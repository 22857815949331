import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
`;

export const Container = styled.div`
  height: 200px;
  overflow-y: auto;
  padding-right: 5px;
`;

export const Product = styled.div`
  text-align: left;
  border-radius: 10px;
  margin-bottom: 6px;
  padding: 10px;
  background-color: ${design.colors.ultralightPrimary};
  > div > div + div {
    text-align: right;
    > div {
      display: block;
    }
  }
`;

export const Title = styled.div`
  padding-top: 25px;
  color: ${design.colors.darkGray};
`;
