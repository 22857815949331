import { design } from '@constants';
import styled, { css } from 'styled-components';

export const State = styled.span`
  font-weight: bold;
  ${(props: any) =>
    props.paid
      ? css`
          color: ${design.colors.green};
        `
      : css`
          color: ${design.colors.orange};
        `}
`;
