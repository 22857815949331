import { Modal, ModalButtons, Fieldset } from '@components';
import TextField from '@mui/material/TextField';
import { INotification, IUseLink } from '@interfaces';
import * as Icons from '@mui/icons-material';
import { icons } from './icons';
import { IconsWrapper, IconWrapper } from './styled';

interface IProps {
  data: IUseLink;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe un enlace con el nombre, URL o icono seleccionado');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const getIcons = () => {
    return icons.map((name, index) => {
      const Icon = Icons[name as keyof typeof Icons];
      return (
        <IconWrapper key={index} active={data.icon === name}>
          <Icon onClick={() => data.setIcon(name)} />
        </IconWrapper>
      );
    });
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} enlace`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Nombre"
        value={data.name}
        onChange={(event) => data.setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <TextField
        label="URL"
        value={data.url}
        onChange={(event) => data.setUrl(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <IconsWrapper>
        <Fieldset title="Icono" margin="0 0 20px" hover={true}>
          {getIcons()}
        </Fieldset>
      </IconsWrapper>
    </Modal>
  );
};
