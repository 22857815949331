import { axiosInstance } from '../axios';
import { api } from '@constants';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.inventoryManagement.indicators, { signal: signal });
};

export const indicators = {
  all,
};
