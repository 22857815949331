import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  height: 100%;
  padding: 0 24px 0 8px;
  @media (max-width: 600px) {
    padding-right: 16px;
  }
  .MuiTextField-root {
    float: right;
    width: 100%;
    max-width: 200px;
  }
  .MuiChip-root {
    margin-bottom: 15px;
    width: 100%;
    background: ${design.colors.lightPrimary};
    color: ${design.colors.primary};
    height: auto;
    span {
      font-size: 12px !important;
      padding: 6px;
    }
  }
`;

export const Container = styled.div`
  background-color: white;
  height: calc(100% - 129px);
  padding: 20px 20px 15px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  overflow: auto;
`;

export const FilterWrapper = styled.div`
  float: right;
  width: 100%;
  max-width: 200px;
  margin-left: 15px;
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px;
  margin-bottom: 19px;
  color: ${design.colors.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.02857em;
`;
