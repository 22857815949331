import Logout from '@mui/icons-material/Logout';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { Wrapper, Dot, StateWrapper, StateDate, StateName, StateDescription } from './styled';
import { useNavigate } from 'react-router-dom';
import { helpers } from '@helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { hooks } from '@hooks';

interface IProps {
  handleClose: () => void;
  onUpdateState: () => void;
}

export const Options = ({ handleClose, onUpdateState }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inSetting = useAppSelector(slices.settings.inSetting);
  const screenWidth = hooks.useScreenWidth();

  const accountSettings = () => {
    if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
    navigate('/settings/personal-information');
    handleClose();
  };

  const logout = () => {
    helpers.deleteJwt();
    navigate('/login');
  };

  return (
    <Wrapper>
      {helpers.getPropertyInCurrentUser('enterprise') && (
        <MenuItem onClick={onUpdateState}>
          <Tooltip
            title={
              <StateWrapper>
                <StateDate>
                  {helpers.getTimeFromNow(helpers.getPropertyInCurrentUser('state_date'))}
                </StateDate>
                <StateName>{helpers.getPropertyInCurrentUser('state')['name']}</StateName>
                {helpers.getPropertyInCurrentUser('state_description') && (
                  <StateDescription>
                    {helpers.getPropertyInCurrentUser('state_description')}
                  </StateDescription>
                )}
              </StateWrapper>
            }
            placement="left"
            arrow
          >
            <Dot color={helpers.getPropertyInCurrentUser('state')['color']} />
          </Tooltip>
          <ListItemIcon>
            <ChangeCircleIcon />
          </ListItemIcon>
          Actualizar mi estado
        </MenuItem>
      )}
      {!inSetting && (
        <MenuItem onClick={accountSettings}>
          <ListItemIcon>
            <ManageAccounts />
          </ListItemIcon>
          Configuración de mi cuenta
        </MenuItem>
      )}
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Cerrar sesión
      </MenuItem>
    </Wrapper>
  );
};
