export const urlBase = process.env.REACT_APP_API_URL;

const inventoryManagement = {
  indicators: 'api/inventory-management/indicators',
};

export const api = {
  auth: 'api/auth',
  roles: 'api/roles',
  users: 'api/users',
  farms: 'api/farms',
  fronts: 'api/fronts',
  states: 'api/states',
  trucks: 'api/trucks',
  links: 'api/links',
  trips: 'api/trips',
  enterprises: 'api/enterprises',
  equipments: 'api/equipments',
  brands: 'api/brands',
  modules: 'api/modules',
  products: 'api/products',
  inventoryManagement,
};
