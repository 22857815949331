import DashboardIcon from '@mui/icons-material/Dashboard';
import GridViewIcon from '@mui/icons-material/GridView';
import BallotIcon from '@mui/icons-material/Ballot';
import { settings } from '@constants';
import { helpers } from '@helpers';

export const inventoryManagement = () => {
  const options = [
    {
      name: 'Indicadores',
      url: '/inventory-management/indicators',
      roles: [settings.roles.administrator],
      icon: <DashboardIcon />,
    },
    {
      name: 'Productos',
      url: '/inventory-management/products',
      roles: [settings.roles.administrator, settings.roles.inventoryClerk],
      icon: <GridViewIcon />,
    },
    {
      name: 'Bitácora',
      url: '/inventory-management/binnacle',
      roles: [settings.roles.administrator],
      icon: <BallotIcon />,
    },
  ];
  return options.filter((option) => helpers.hasRequiredRoles(option.roles));
};
