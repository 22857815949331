import { useEffect, useState } from 'react';
import { Wrapper } from './styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {
  Notification,
  AvatarWithInfo,
  Skeleton,
  WithoutData,
  ModalConfirm,
  TableOptions,
} from '@components';
import { RoleAssignment } from '../../activeUsers/roleAssignment';
import { hooks } from '@hooks';
import { services } from '@services';
import { IUser } from '@interfaces';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import GroupAdd from '@mui/icons-material/GroupAdd';
import { Header } from './header';
import { helpers } from '@helpers';
import moment from 'moment';

export const PendingUsers = () => {
  const [isConfirmingTheAuthorization, setIsConfirmingTheAuthorization] = useState(false);
  const [isConfirmingTheDenial, setIsConfirmingTheDenial] = useState(false);
  const [isAssigningRoles, setIsAssigningRoles] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [assignedRoles, setAssignedRoles] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const pendingUsers = useAppSelector(slices.pendingUsers.items);
  const searchResults = useAppSelector(slices.pendingUsers.searchResults);
  const searchCriteria = useAppSelector(slices.pendingUsers.searchCriteria);
  const pendingUsersLoading = useAppSelector(slices.pendingUsers.loading);
  const pendingUsersLoaded = useAppSelector(slices.pendingUsers.loaded);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!pendingUsersLoaded && !pendingUsersLoading) {
      getPendingUsers();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getPendingUsers = async () => {
    dispatch(slices.pendingUsers.setLoading(true));
    await services.user
      .pending(controller.signal)
      .then((response) => {
        dispatch(slices.pendingUsers.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los usuarios pendientes de autorización');
      });
    dispatch(slices.pendingUsers.setLoading(false));
  };

  const assign = () => {
    setIsConfirmingTheAuthorization(true);
  };

  const authorizeAccess = (user: IUser) => {
    setAssignedRoles([]);
    setSelectedUser(user);
    setIsAssigningRoles(true);
  };

  const denyAccess = (user: IUser) => {
    setSelectedUser(user);
    setIsConfirmingTheDenial(true);
  };

  const confirmAuthorization = async () => {
    if (selectedUser) {
      setIsProcessing(true);
      await services.enterprise
        .approveJoinRequest(controller.signal, selectedUser.id, assignedRoles)
        .then((response) => {
          dispatch(slices.pendingUsers.deleteItem(selectedUser.id));
          dispatch(slices.authorizedUsers.setItem(response.data));
          notification.success('El acceso del usuario seleccionado ha sido autorizado');
        })
        .catch(() => {
          notification.warning('No fue posible autorizar el acceso del usuario seleccionado');
        });
      setIsProcessing(false);
      setIsAssigningRoles(false);
      setIsConfirmingTheAuthorization(false);
    }
  };

  const confirmDenial = async () => {
    if (selectedUser) {
      setIsProcessing(true);
      await services.enterprise
        .declineJoinRequest(controller.signal, selectedUser.id)
        .then(() => {
          dispatch(slices.pendingUsers.deleteItem(selectedUser.id));
          notification.success('El acceso del usuario seleccionado ha sido denegado');
        })
        .catch(() => {
          notification.warning('No fue posible denegar el acceso del usuario seleccionado');
        });
      setIsProcessing(false);
      setIsConfirmingTheDenial(false);
    }
  };

  const cancelAction = () => {
    setIsAssigningRoles(false);
    setIsConfirmingTheAuthorization(false);
    setIsConfirmingTheDenial(false);
  };

  const tableOptions = (item: IUser) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Autorizar acceso',
          onClick: authorizeAccess,
        },
        {
          title: 'Denegar acceso',
          onClick: denyAccess,
        },
      ]}
    />
  );

  const buildTable = (dataList: IUser[]) => (
    <TableContainer>
      <Table size="small" stickyHeader sx={{ minWidth: 800 }}>
        <Header />
        <TableBody>
          {dataList.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell>
                <AvatarWithInfo
                  avatar={helpers.getAvatar(item.avatar)}
                  title={`${item.first_name} ${item.last_name}`}
                  description={item.email}
                />
              </TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{item.birthday && moment(item.birthday).format('DD/MM/YYYY')}</TableCell>
              {tableOptions(item)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Wrapper>
      {pendingUsersLoading ? (
        <Skeleton variant="table" />
      ) : searchCriteria === '' ? (
        pendingUsers.length ? (
          buildTable(pendingUsers)
        ) : (
          <WithoutData
            icon={<GroupAdd />}
            title="No hay usuarios"
            description="Los usuarios que requieran acceder a la información de tu empresa, se mostrarán en esta sección para que puedas autorizar su acceso"
          />
        )
      ) : searchResults.length ? (
        buildTable(searchResults)
      ) : (
        <WithoutData
          icon={<GroupAdd />}
          title="No hay coincidencias"
          description="En esta sección se mostrarán los usuarios que coincidan con los criterios de búsqueda"
        />
      )}
      {isAssigningRoles && (
        <RoleAssignment
          cancelAction={cancelAction}
          assignedRoles={assignedRoles}
          setAssignedRoles={setAssignedRoles}
          assign={assign}
          notification={notification}
        />
      )}
      {isConfirmingTheAuthorization && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, el usuario seleccionado tendrá acceso a la información de la empresa con los roles previamente asignados"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmAuthorization}
          secondaryAction={cancelAction}
          isProcessing={isProcessing}
        />
      )}
      {isConfirmingTheDenial && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se denegará el acceso a la información de la empresa para el usuario seleccionado"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmDenial}
          secondaryAction={cancelAction}
          isProcessing={isProcessing}
        />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
