import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  margin-left: 8px;
  margin-right: 24px;
  text-align: center;
  background-color: white;
  padding: 20px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  height: calc(100% - 40px);
  overflow: auto;
  @media (max-width: 600px) {
    margin-right: 16px;
  }
  > button {
    margin: 50px 0;
  }
`;

export const Container = styled.div`
  padding: 10px 0;
  text-align: left;
  > div > div > div > div > div + div {
    margin-top: 15px;
  }
`;

export const CodeWrapper = styled.div`
  width: max-content;
  button {
    right: -35px;
    top: -22px;
    float: right;
    svg {
      font-size: 15px;
    }
  }
  @media (max-width: 500px) {
    > div > div > div + div {
      width: 190px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
