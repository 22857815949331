import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Notification, Skeleton } from '@components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { hooks } from '@hooks';
import RegisteredProducts from './registeredProducts';
import InvestmentInExistingProducts from './investmentInExistingProducts';
import ProfitsOnExistingProducts from './profitsOnExistingProducts';
import MonthlySales from './monthlySales';
import BestSellingProducts from './bestSellingProducts';
import { Wrapper, Container, Title } from './styled';

export const InventoryManagementIndicators = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const data = useAppSelector(slices.inventoryManagement.indicators.data);
  const loadingIndicators = useAppSelector(slices.inventoryManagement.indicators.loading);
  const loadedIndicators = useAppSelector(slices.inventoryManagement.indicators.loaded);

  useEffect(() => {
    if (!loadedIndicators && !loadingIndicators) getIndicators();
    return () => {
      controller.abort();
    };
  }, []);

  const getIndicators = async () => {
    dispatch(slices.inventoryManagement.indicators.setLoading(true));
    await services.inventoryManagement.indicators
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.inventoryManagement.indicators.setData(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar los indicadores');
      });
    dispatch(slices.inventoryManagement.indicators.setLoading(false));
  };

  return (
    <Wrapper>
      <Title>Indicadores</Title>
      <Container>
        {loadingIndicators && <Skeleton variant="indicators" />}
        {!loadingIndicators && data && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ display: 'grid' }}>
              <InvestmentInExistingProducts data={data} />
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'grid' }}>
              <ProfitsOnExistingProducts data={data} />
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'grid' }}>
              <RegisteredProducts data={data} />
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'grid' }}>
              <BestSellingProducts data={data} />
            </Grid>
            <Grid item xs={12} style={{ display: 'grid' }}>
              <MonthlySales data={data} />
            </Grid>
          </Grid>
        )}
      </Container>
      <Notification data={notification} />
    </Wrapper>
  );
};
