import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IOrder, IModule, IModuleList } from '@interfaces';
import { helpers } from '@helpers';

const initialState: IModuleList = {
  items: [],
  order: 'asc',
  orderBy: '',
  loaded: false,
  loading: false,
};

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IModule[]>) => {
      state.items = action.payload;
      state.loaded = true;
    },
    update: (state, action: PayloadAction<IModule[]>) => {
      const newList = state.items.map((item) => {
        let newModule = item;
        action.payload.forEach((element) => {
          if (element.id === item.id) {
            newModule = element;
          }
        });
        return newModule;
      });
      const field = state.orderBy === '' ? 'name' : state.orderBy;
      const orderedList = helpers.orderList(newList, field, state.order);
      state.items = orderedList;
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    sort: (state, action: PayloadAction<IOrder>) => {
      state.items = helpers.orderList(state.items, action.payload.field, action.payload.order);
    },
  },
});

const { setLoading, setItems, update, setOrder, setOrderBy, sort } = modulesSlice.actions;
const items = (state: RootState) => state.modules.items;
const order = (state: RootState) => state.modules.order;
const orderBy = (state: RootState) => state.modules.orderBy;
const loaded = (state: RootState) => state.modules.loaded;
const loading = (state: RootState) => state.modules.loading;

export const modules = {
  setLoading,
  setItems,
  update,
  setOrder,
  setOrderBy,
  sort,
  items,
  order,
  orderBy,
  loaded,
  loading,
};

export default modulesSlice.reducer;
