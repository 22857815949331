import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ModalConfirm, TableOptions } from '@components';
import { IBrand, INotification, IUseBrand } from '@interfaces';
import { useAppDispatch } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { Form } from '../../form';

interface IProps {
  items: IBrand[];
  data: IUseBrand;
  notification: INotification;
}

export const Body = ({ items, data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onUpdate = (value: IBrand) => {
    data.initialize(value);
    data.setIsUpdating(true);
  };

  const onDelete = (value: IBrand) => {
    data.initialize(value);
    data.setIsDeleting(true);
  };

  const confirmUpdate = async () => {
    data.setIsProcessing(true);
    await services.brand
      .update(controller.signal, data.object())
      .then((response) => {
        dispatch(slices.brands.updateItem(response.data));
        notification.success('Se actualizó la marca seleccionada');
      })
      .catch(() => {
        notification.warning('No fue posible actualizar la marca seleccionada');
      });
    data.setIsUpdating(false);
    data.setIsProcessing(false);
  };

  const confirmDelete = async () => {
    data.setIsProcessing(true);
    await services.brand
      .del(controller.signal, data.id)
      .then(() => {
        dispatch(slices.brands.deleteItem(data.id));
        notification.success('Se eliminó la marca seleccionada');
      })
      .catch(() => {
        notification.warning('No fue posible eliminar la marca seleccionada');
      });
    data.setIsDeleting(false);
    data.setIsProcessing(false);
  };

  const cancel = () => {
    data.setIsUpdating(false);
    data.setIsDeleting(false);
    data.setIsProcessing(false);
  };

  const tableOptions = (item: IBrand) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Actualizar',
          onClick: onUpdate,
        },
        {
          title: 'Eliminar',
          onClick: onDelete,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{item.name}</TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {data.isDeleting && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se eliminará la marca seleccionada"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmDelete}
          secondaryAction={cancel}
          isProcessing={data.isProcessing}
        />
      )}
      {data.isUpdating && (
        <Form data={data} save={confirmUpdate} cancel={cancel} notification={notification} />
      )}
    </TableBody>
  );
};
