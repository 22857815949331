import { useEffect } from 'react';
import { Container } from './styled';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { Skeleton, WithoutData, Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Header } from './header';
import { Body } from './body';

export const Table = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const invoices = useAppSelector(slices.invoices.items);
  const loading = useAppSelector(slices.invoices.loading);
  const loaded = useAppSelector(slices.invoices.loaded);

  useEffect(() => {
    if (!loaded && !loading) getItems();
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.invoices.setLoading(true));
    await services.enterprise
      .invoices(controller.signal)
      .then((response) => {
        dispatch(slices.invoices.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar las facturas generadas');
      });
    dispatch(slices.invoices.setLoading(false));
  };

  return (
    <Container>
      {loading ? (
        <Skeleton variant="table" />
      ) : invoices.length ? (
        <TableContainer>
          <MUITable size="small" stickyHeader sx={{ minWidth: 500 }}>
            <Header />
            <Body items={invoices} />
          </MUITable>
        </TableContainer>
      ) : (
        <WithoutData
          icon={<ReceiptLongIcon />}
          title="No hay facturas"
          description="En esta sección se mostrarán las facturas que han sido generadas"
        />
      )}
      <Notification data={notification} />
    </Container>
  );
};
