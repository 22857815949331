import { BarChart } from '@mui/x-charts/BarChart';
import { IMIndicators } from '@interfaces';
import moment from 'moment';
import { Wrapper, Title } from './styled';
import { design } from '@constants';
import { helpers } from '@helpers';

interface IProps {
  data: IMIndicators;
}

const MonthlySales = ({ data }: IProps) => {
  return (
    <Wrapper>
      <BarChart
        series={[
          {
            data: data?.monthly_sales.map((item) => item.sales),
            valueFormatter: (value) => `${helpers.getFormattedNumber(value || 0)} GTQ`,
          },
        ]}
        xAxis={[
          {
            data: data?.monthly_sales.map((item) => moment(item.month).format('MMM')),
            scaleType: 'band',
          },
        ]}
        colors={design.colors.pallette}
        grid={{ horizontal: true }}
        margin={{ left: 45, right: 0, top: 10, bottom: 25 }}
        borderRadius={10}
        height={200}
      />
      <Title>Ventas del último año</Title>
    </Wrapper>
  );
};

export default MonthlySales;
