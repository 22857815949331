import styled from 'styled-components';
import Menu from '@mui/material/Menu';

export const Wrapper = styled(Menu)`
  .MuiMenuItem-root {
    transition: 0.2s;
  }
  .MuiList-root {
    padding-bottom: 0px;
  }
`;
