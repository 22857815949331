import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ISetting } from '@interfaces';

const initialState: ISetting = {
  inHome: false,
  inAdmin: false,
  inSetting: false,
  openMenu: true,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setInHome: (state) => {
      state.inHome = true;
      state.inAdmin = false;
      state.inSetting = false;
    },
    setInAdmin: (state) => {
      state.inHome = false;
      state.inAdmin = true;
      state.inSetting = false;
    },
    setInSetting: (state) => {
      state.inHome = false;
      state.inAdmin = false;
      state.inSetting = true;
    },
    setOpenMenu: (state, action: PayloadAction<boolean>) => {
      state.openMenu = action.payload;
    },
  },
});

const { setInHome, setInAdmin, setInSetting, setOpenMenu } = settingsSlice.actions;
const inHome = (state: RootState) => state.settings.inHome;
const inAdmin = (state: RootState) => state.settings.inAdmin;
const inSetting = (state: RootState) => state.settings.inSetting;
const openMenu = (state: RootState) => state.settings.openMenu;

export const settings = {
  setInHome,
  setInAdmin,
  setInSetting,
  setOpenMenu,
  inHome,
  inAdmin,
  inSetting,
  openMenu,
};

export default settingsSlice.reducer;
