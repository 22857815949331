import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 108px 20px 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
`;

export const Title = styled.div`
  padding-top: 78px;
  color: ${design.colors.darkGray};
`;

export const Value = styled.div`
  font-size: 50px;
  font-weight: bold;
  color: ${design.colors.primary};
`;
