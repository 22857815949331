import styled from 'styled-components';
import { design } from '@constants';

export const ChipWrapper = styled.div`
  .MuiChip-root {
    margin-bottom: 15px;
    width: 100%;
    background: ${design.colors.lightPrimary};
    color: ${design.colors.primary};
    height: auto;
    span {
      font-size: 12px !important;
      padding: 6px;
    }
  }
`;

export const Container = styled.div`
  background-color: white;
  height: calc(100% - 129px);
  padding: 20px 20px 15px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  overflow: auto;
`;
