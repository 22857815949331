import { useEffect } from 'react';
import { Notification, Skeleton, WithoutData, TableOptions } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Identifier } from './identifier';
import { Route } from './route';
import { Going } from './going';
import { Loading } from './loading';
import { Returning } from './returning';
import { Header } from './header';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Wrapper } from './styled';
import { IFront } from '@interfaces';
import { UpdateRoute } from './options/updateRoute';
import { UpdateState } from './options/updateState';
import { UpdateSettings } from './options/updateSettings';

export const Table = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const fronts = useAppSelector(slices.fronts.items);
  const loadingFronts = useAppSelector(slices.fronts.loading);
  const loadedFronts = useAppSelector(slices.fronts.loaded);
  const loadingFrontCompliance = useAppSelector(slices.frontCompliance.loading);
  const loadedFrontCompliance = useAppSelector(slices.frontCompliance.loaded);
  const trips = useAppSelector(slices.trips.items);
  const searchCriteria = useAppSelector(slices.trips.searchCriteria);
  const searchResults = useAppSelector(slices.trips.searchResults);
  const loadingTrips = useAppSelector(slices.trips.loading);
  const loadedTrips = useAppSelector(slices.trips.loaded);
  const dataRoute = hooks.useFrontRoute();
  const dataState = hooks.useFrontState();
  const dataSettings = hooks.useFrontSettings();

  useEffect(() => {
    if (!loadedFronts && !loadingFronts) getFronts();
    if (!loadedFrontCompliance && !loadingFrontCompliance) getFrontCompliance();
    if (!loadedTrips && !loadingTrips) getTrips();
    return () => {
      controller.abort();
    };
  }, []);

  const getFronts = async () => {
    dispatch(slices.fronts.setLoading(true));
    await services.front
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.fronts.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los frentes');
      });
    dispatch(slices.fronts.setLoading(false));
  };

  const getFrontCompliance = async () => {
    dispatch(slices.frontCompliance.setLoading(true));
    await services.front
      .compliance(controller.signal)
      .then((response) => {
        dispatch(slices.frontCompliance.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar el cumplimiento de frentes');
      });
    dispatch(slices.frontCompliance.setLoading(false));
  };

  const getTrips = async () => {
    dispatch(slices.trips.setLoading(true));
    await services.trip
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trips.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los viajes');
      });
    dispatch(slices.trips.setLoading(false));
  };

  const onUpdateRoute = (item: IFront) => {
    dataRoute.initialize(item);
    dataRoute.setIsUpdating(true);
  };

  const onUpdateState = (item: IFront) => {
    dataState.initialize(item);
    dataState.setIsUpdating(true);
  };

  const onUpdateSettings = (item: IFront) => {
    dataSettings.initialize(item);
    dataSettings.setIsUpdating(true);
  };

  const tableOptions = (item: IFront) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Actualizar ruta',
          onClick: onUpdateRoute,
        },
        {
          title: 'Actualizar estado',
          onClick: onUpdateState,
        },
        {
          title: 'Actualizar configuración',
          onClick: onUpdateSettings,
        },
      ]}
    />
  );

  return (
    <Wrapper>
      {loadingFronts ? (
        <Skeleton variant="table" />
      ) : fronts.length === 0 ? (
        <WithoutData
          icon={<AgricultureIcon />}
          title="No hay frentes"
          description="En esta sección se mostrará la distribución por frente"
        />
      ) : (
        <TableContainer>
          <MUITable size="small" stickyHeader sx={{ minWidth: 700 }}>
            <Header />
            <TableBody>
              {fronts.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Identifier item={item} />
                  </TableCell>
                  <TableCell>
                    <Route item={item} />
                  </TableCell>
                  <TableCell>
                    <Going
                      initialData={trips.filter(
                        (trip) =>
                          trip.front?.id === item.id && trip.settings.front_arrival_datetime === '',
                      )}
                      trips={
                        searchCriteria === ''
                          ? trips.filter(
                              (trip) =>
                                trip.front?.id === item.id &&
                                trip.settings.front_arrival_datetime === '',
                            )
                          : searchResults.filter(
                              (trip) =>
                                trip.front?.id === item.id &&
                                trip.settings.front_arrival_datetime === '',
                            )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Loading
                      initialData={trips.filter(
                        (trip) =>
                          trip.front?.id === item.id &&
                          trip.settings.front_arrival_datetime !== '' &&
                          trip.settings.front_departure_datetime === '',
                      )}
                      trips={
                        searchCriteria === ''
                          ? trips.filter(
                              (trip) =>
                                trip.front?.id === item.id &&
                                trip.settings.front_arrival_datetime !== '' &&
                                trip.settings.front_departure_datetime === '',
                            )
                          : searchResults.filter(
                              (trip) =>
                                trip.front?.id === item.id &&
                                trip.settings.front_arrival_datetime !== '' &&
                                trip.settings.front_departure_datetime === '',
                            )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Returning
                      initialData={trips.filter(
                        (trip) =>
                          trip.front?.id === item.id &&
                          trip.settings.front_departure_datetime !== '' &&
                          trip.settings.mill_arrival_datetime === '',
                      )}
                      trips={
                        searchCriteria === ''
                          ? trips.filter(
                              (trip) =>
                                trip.front?.id === item.id &&
                                trip.settings.front_departure_datetime !== '' &&
                                trip.settings.mill_arrival_datetime === '',
                            )
                          : searchResults.filter(
                              (trip) =>
                                trip.front?.id === item.id &&
                                trip.settings.front_departure_datetime !== '' &&
                                trip.settings.mill_arrival_datetime === '',
                            )
                      }
                    />
                  </TableCell>
                  {tableOptions(item)}
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      )}
      {dataRoute.isUpdating && <UpdateRoute data={dataRoute} notification={notification} />}
      {dataState.isUpdating && <UpdateState data={dataState} notification={notification} />}
      {dataSettings.isUpdating && (
        <UpdateSettings data={dataSettings} notification={notification} />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
