import { useEffect, useState } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IEquipmentType, INotification, IUseFrontSettings } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { helpers } from '@helpers';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { Information } from './styled';

interface IProps {
  data: IUseFrontSettings;
  notification: INotification;
}

export const UpdateSettings = ({ data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const enterpriseSettings = useAppSelector(slices.enterpriseSettings.settings);
  const loadingEnterpriseSettings = useAppSelector(slices.enterpriseSettings.loading);
  const loadedEnterpriseSettings = useAppSelector(slices.enterpriseSettings.loaded);
  const equipmentTypes = useAppSelector(slices.equipmentTypes.items);
  const loadingEquipmentTypes = useAppSelector(slices.equipmentTypes.loading);
  const loadedEquipmentTypes = useAppSelector(slices.equipmentTypes.loaded);
  const [timeToGoInMinutes, setTimeToGoInMinutes] = useState(0);
  const [timeToLoadInMinutes, setTimeToLoadInMinutes] = useState(0);
  const [timeToReturnInMinutes, setTimeToReturnInMinutes] = useState(0);

  useEffect(() => {
    setTimeToGoInMinutes(Math.ceil(Number(data.timeToGo) * 60));
    setTimeToLoadInMinutes(Math.ceil(Number(data.timeToLoad) * 60));
    setTimeToReturnInMinutes(Math.ceil(Number(data.timeToReturn) * 60));
    if (!loadedEnterpriseSettings && !loadingEnterpriseSettings) {
      getSettings();
    }
    if (!loadedEquipmentTypes && !loadingEquipmentTypes) {
      getEquipmentTypes();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getSettings = async () => {
    dispatch(slices.enterpriseSettings.setLoading(true));
    await services.enterprise
      .enterpriseSettings(controller.signal)
      .then((response) => {
        dispatch(slices.enterpriseSettings.setSettings(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible obtener la configuración de la empresa');
      });
    dispatch(slices.enterpriseSettings.setLoading(false));
  };

  const getEquipmentTypes = async () => {
    dispatch(slices.equipmentTypes.setLoading(true));
    await services.equipmentType
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.equipmentTypes.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los tipos');
      });
    dispatch(slices.equipmentTypes.setLoading(false));
  };

  const next = async () => {
    if (data.isValid()) {
      if (Number(data.minimumCages) > Number(data.maximumCages)) {
        notification.warning('Corrige la cantidad mínima y máxima de jaulas');
      } else {
        data.setIsProcessing(true);
        await services.front
          .updateSettings(controller.signal, data.object())
          .then((response) => {
            dispatch(slices.fronts.updateItem(response.data));
            notification.success('Se actualizó la configuración del frente');
          })
          .catch(() => {
            notification.warning('No fue posible actualizar la configuración del frente');
          });
        data.setIsUpdating(false);
        data.setIsProcessing(false);
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const calculateTimes = () => {
    if (data.front) {
      const hours = data.front.farm.distance / enterpriseSettings.average_speed;
      const newTimeToGo = helpers.validateNumber(data.timeToGo, hours.toFixed(2), true);
      const newTimeToReturn = helpers.validateNumber(
        data.timeToReturn,
        (hours * 1.25).toFixed(2),
        true,
      );
      data.setTimeToGo(newTimeToGo);
      data.setTimeToReturn(newTimeToReturn);
      setTimeToGoInMinutes(Math.ceil(Number(newTimeToGo) * 60));
      setTimeToReturnInMinutes(Math.ceil(Number(newTimeToReturn) * 60));
    }
  };

  const cancel = () => {
    data.setIsUpdating(false);
  };

  const setTimeToGo = (value: any) => {
    const time = helpers.validateNumber(data.timeToGo, value, true);
    data.setTimeToGo(time);
    setTimeToGoInMinutes(Math.ceil(Number(time) * 60));
  };

  const setTimeToLoad = (value: any) => {
    const time = helpers.validateNumber(data.timeToLoad, value, true);
    data.setTimeToLoad(time);
    setTimeToLoadInMinutes(Math.ceil(Number(time) * 60));
  };

  const setTimeToReturn = (value: any) => {
    const time = helpers.validateNumber(data.timeToReturn, value, true);
    data.setTimeToReturn(time);
    setTimeToReturnInMinutes(Math.ceil(Number(time) * 60));
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`Configuración del Frente ${data.front?.identifier}`}
      close={cancel}
      actions={buttons}
      maxWidth="sm"
    >
      {loadedEnterpriseSettings && (
        <Information>
          <Button onClick={calculateTimes} variant="outlined" size="small">
            Calcular tiempos
          </Button>
          <div>Distancia de la finca {data.front?.farm.distance} km</div>
          <div>Velocidad promedio de los camiones {enterpriseSettings.average_speed} km/h</div>
        </Information>
      )}
      <Grid container spacing={2} style={{ marginTop: 'unset', marginBottom: 15 }}>
        <Grid item xs={6}>
          <TextField
            label="Tiempo de viaje vacío"
            value={data.timeToGo}
            onChange={(event) => setTimeToGo(event.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            InputProps={{
              endAdornment: <InputAdornment position="end">h</InputAdornment>,
            }}
            sx={{ marginBottom: '5px' }}
            helperText={
              timeToGoInMinutes === 0
                ? ''
                : `${timeToGoInMinutes} minuto${timeToGoInMinutes > 1 ? 's' : ''}`
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tiempo de viaje cargado"
            value={data.timeToReturn}
            onChange={(event) => setTimeToReturn(event.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">h</InputAdornment>,
            }}
            sx={{ marginBottom: '5px' }}
            helperText={
              timeToReturnInMinutes === 0
                ? ''
                : `${timeToReturnInMinutes} minuto${timeToReturnInMinutes > 1 ? 's' : ''}`
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={data.equipmentType}
            onChange={(_event: any, newValue: IEquipmentType | null) =>
              data.setEquipmentType(newValue)
            }
            options={equipmentTypes}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de equipo"
                size="small"
                sx={{ marginBottom: '5px' }}
              />
            )}
            noOptionsText="No hay opciones"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Peso por jaula"
            value={data.loadWeight}
            onChange={(event) =>
              data.setLoadWeight(helpers.validateNumber(data.loadWeight, event.target.value, true))
            }
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">t</InputAdornment>,
            }}
            sx={{ marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tiempo de carga por jaula"
            value={data.timeToLoad}
            onChange={(event) => setTimeToLoad(event.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">h</InputAdornment>,
            }}
            sx={{ marginBottom: '5px' }}
            helperText={
              timeToLoadInMinutes === 0
                ? ''
                : `${timeToLoadInMinutes} minuto${timeToLoadInMinutes > 1 ? 's' : ''}`
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Carga simultánea de jaulas"
            value={data.simultaneousLoads}
            onChange={(event) =>
              data.setSimultaneousLoads(
                helpers.validateNumber(data.simultaneousLoads, event.target.value, false),
              )
            }
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Mínimo de jaulas"
            value={data.minimumCages}
            onChange={(event) =>
              data.setMinimumCages(
                helpers.validateNumber(data.minimumCages, event.target.value, false),
              )
            }
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Máximo de jaulas"
            value={data.maximumCages}
            onChange={(event) =>
              data.setMaximumCages(
                helpers.validateNumber(data.maximumCages, event.target.value, false),
              )
            }
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
