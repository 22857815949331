import styled from 'styled-components';
import { design } from '@constants';

export const Information = styled.div`
  background-color: ${design.colors.lightPrimary};
  color: ${design.colors.primary};
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 12px;
`;

export const Content = styled.div`
  margin-bottom: 20px;
  height: 300px;
`;
