import styled from 'styled-components';
import Box from '@mui/material/Box';
import { design } from '@constants';

export const Wrapper = styled(Box)`
  margin-top: 5px !important;
  > span {
    width: ${(props: any) => props.indicatorsize}px !important;
    height: ${(props: any) => props.indicatorsize}px !important;
    border: solid ${design.colors.mediumGray} 3px;
    border-radius: 50%;
  }
`;
