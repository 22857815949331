import { useState } from 'react';
import { ITrip } from '@interfaces';
import { Data } from './../styled';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { Trips } from '../../header/searcher/trips';

interface IProps {
  initialData: ITrip[];
  trips: ITrip[];
}

export const Going = ({ initialData, trips }: IProps) => {
  const dispatch = useAppDispatch();
  const notification = hooks.useNotification();
  const loadingTrips = useAppSelector(slices.trips.loading);
  const [showDetail, setShowDetail] = useState(false);

  const openModal = () => {
    dispatch(slices.trips.search(''));
    setShowDetail(true);
  };

  return (
    <>
      <Data onClick={openModal} align="center" hasDetail={true} forceHeight={true}>
        {initialData.length}
      </Data>
      {showDetail && (
        <Trips
          initialData={initialData}
          data={trips}
          loading={loadingTrips}
          close={() => setShowDetail(false)}
          notification={notification}
        />
      )}
      <Notification data={notification} />
    </>
  );
};
