import { Wrapper, Title } from './styled';
import { Requests } from './requests';
import { Searcher } from './searcher';

export const Header = () => {
  return (
    <Wrapper>
      <Requests />
      <Searcher />
      <Title>Distribución</Title>
    </Wrapper>
  );
};
