import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  width: 280px;
  text-align: center;
  padding: 20px;
  .MuiAvatar-root {
    display: inline-block;
  }
  .MuiBadge-badge {
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: ${design.colors.darkGray};
    cursor: pointer;
    transition: 200ms;
    :hover {
      color: ${design.colors.primary};
    }
  }
`;

export const Name = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: ${design.colors.primary};
`;

export const Email = styled.div`
  margin-top: 5px;
  color: ${design.colors.darkGray};
`;
