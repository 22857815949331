import { BarChart } from '@mui/x-charts/BarChart';
import { Indicators } from '@interfaces';
import moment from 'moment';
import { Wrapper, Title } from './styled';
import { design } from '@constants';

interface IProps {
  data: Indicators;
}

export const CaneEnteredPerHour = ({ data }: IProps) => {
  return (
    <Wrapper>
      <BarChart
        series={[
          {
            data: data?.cane_entered_per_hour.map((item) => item.cane_entered),
            valueFormatter: (value) => `${value?.toLocaleString()} toneladas`,
          },
        ]}
        xAxis={[
          {
            data: data?.cane_entered_per_hour.map((item) => moment(item.hour).format('HH:mm')),
            scaleType: 'band',
          },
        ]}
        colors={design.colors.pallette}
        grid={{ horizontal: true }}
        margin={{ left: 45, right: 0, top: 10, bottom: 20 }}
        borderRadius={10}
        height={200}
      />
      <Title>Caña ingresada por hora</Title>
    </Wrapper>
  );
};
