import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableOptions, AvatarWithInfo } from '@components';
import { INotification, IUser } from '@interfaces';
import { helpers } from '@helpers';
import moment from 'moment';
import { hooks } from '@hooks';
import { UpdateState } from './updateState';

interface IProps {
  items: IUser[];
  notification: INotification;
}

export const Body = ({ items, notification }: IProps) => {
  const dataState = hooks.useUserState();

  const onUpdateState = (item: IUser) => {
    dataState.initialize(item);
    dataState.setIsUpdating(true);
  };

  const tableOptions = (item: IUser) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Cambiar estado',
          onClick: onUpdateState,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>
            <AvatarWithInfo
              avatar={helpers.getAvatar(item.avatar)}
              title={`${item.first_name} ${item.last_name}`}
              description={item.email}
            />
          </TableCell>
          <TableCell>{item.phone}</TableCell>
          <TableCell>{item.birthday && moment(item.birthday).format('DD/MM/YYYY')}</TableCell>
          <TableCell>
            <AvatarWithInfo
              title={item.state.name}
              description={helpers.getTimeFromNow(item.state_date)}
              titleColor={item.state.color}
              tooltipDescription={item.state_description}
            />
          </TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {dataState.isUpdating && <UpdateState data={dataState} notification={notification} />}
    </TableBody>
  );
};
