import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import {
  Welcome,
  Distribution,
  Indicators,
  Scenarios,
  ActiveUsers,
  Login,
  Signup,
  PasswordRecovery,
  Private,
  Farms,
  Fronts,
  States,
  Trucks,
  PersonalInformation,
  CurrentEnterprise,
  Enterprise,
  Links,
  CurrentTrip,
  Equipments,
  EquipmentsDownload,
  EquipmentsType,
  Brands,
  CurrentState,
  Modules,
  Invoices,
  Drivers,
  NotFound,
  Forbidden,
  NewUsers,
  Products,
  Binnacle,
  InventoryManagementIndicators,
} from '@components';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/transport-management/indicators"
          element={
            <Private>
              <Indicators />
            </Private>
          }
        />
        <Route
          path="/transport-management/distribution"
          element={
            <Private>
              <Distribution />
            </Private>
          }
        />
        <Route
          path="/transport-management/equipments/download"
          element={
            <Private>
              <EquipmentsDownload />
            </Private>
          }
        />
        <Route
          path="/transport-management/scenarios"
          element={
            <Private>
              <Scenarios />
            </Private>
          }
        />
        <Route
          path="/transport-management/drivers"
          element={
            <Private>
              <Drivers />
            </Private>
          }
        />
        <Route
          path="/transport-management/trucks"
          element={
            <Private>
              <Trucks />
            </Private>
          }
        />
        <Route
          path="/transport-management/equipments"
          element={
            <Private>
              <Equipments />
            </Private>
          }
        />
        <Route
          path="/transport-management/equipments/types"
          element={
            <Private>
              <EquipmentsType />
            </Private>
          }
        />
        <Route
          path="/transport-management/brands"
          element={
            <Private>
              <Brands />
            </Private>
          }
        />
        <Route
          path="/transport-management/fronts"
          element={
            <Private>
              <Fronts />
            </Private>
          }
        />
        <Route
          path="/transport-management/farms"
          element={
            <Private>
              <Farms />
            </Private>
          }
        />
        <Route
          path="/transport-management/states"
          element={
            <Private>
              <States />
            </Private>
          }
        />
        <Route
          path="/transport-management/drivers/current-trip"
          element={
            <Private>
              <CurrentTrip />
            </Private>
          }
        />
        <Route
          path="/transport-management/fronts/current-state"
          element={
            <Private>
              <CurrentState />
            </Private>
          }
        />
        <Route
          path="/inventory-management/indicators"
          element={
            <Private>
              <InventoryManagementIndicators />
            </Private>
          }
        />
        <Route
          path="/inventory-management/products"
          element={
            <Private>
              <Products />
            </Private>
          }
        />
        <Route
          path="/inventory-management/binnacle"
          element={
            <Private>
              <Binnacle />
            </Private>
          }
        />
        <Route
          path="/settings/personal-information"
          element={
            <Private inSettings={true}>
              <PersonalInformation />
            </Private>
          }
        />
        <Route
          path="/settings/current-enterprise"
          element={
            <Private inSettings={true}>
              <CurrentEnterprise />
            </Private>
          }
        />
        <Route
          path="/admin/enterprise"
          element={
            <Private inAdmin={true}>
              <Enterprise />
            </Private>
          }
        />
        <Route
          path="/admin/links"
          element={
            <Private inAdmin={true}>
              <Links />
            </Private>
          }
        />
        <Route
          path="/admin/active-users"
          element={
            <Private inAdmin={true}>
              <ActiveUsers />
            </Private>
          }
        />
        <Route
          path="/admin/new-users"
          element={
            <Private inAdmin={true}>
              <NewUsers />
            </Private>
          }
        />
        <Route
          path="/admin/modules"
          element={
            <Private inAdmin={true}>
              <Modules />
            </Private>
          }
        />
        <Route
          path="/admin/invoices"
          element={
            <Private inAdmin={true}>
              <Invoices />
            </Private>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route
          path="/forbidden"
          element={
            <Private>
              <Forbidden />
            </Private>
          }
        />
        <Route
          path="/not-found"
          element={
            <Private>
              <NotFound />
            </Private>
          }
        />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Router>
  );
};
