import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  span {
    float: right;
    margin-left: 10px;
  }
`;

export const Item = styled.div`
  margin-top: 10px;
  margin-bottom: 2px;
  background-color: ${design.colors.ultralightGray};
  border-radius: 10px;
  padding: 10px;
  transition: 200ms;
  button {
    float: right;
  }
  :hover {
    background-color: ${design.colors.lightGray};
  }
`;
