import { Indicators } from '@interfaces';
import Grid from '@mui/material/Grid';
import { ProgressIndicator, AvatarWithInfo } from '@components';
import { Wrapper, Container, Front, Title, IndicatorTitle } from './styled';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { design } from '@constants';

interface IProps {
  data: Indicators;
}

export const QuotaCompliance = ({ data }: IProps) => {
  const fronts = useAppSelector(slices.fronts.items);

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <ProgressIndicator
            value={data.general_quota_compliance}
            indicatorSize={150}
            titleSize={40}
            indicatorColor="primary"
          />
          <IndicatorTitle>General</IndicatorTitle>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Container>
            {data.front_quota_compliance.map((item, index) => (
              <Front key={index}>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <AvatarWithInfo
                      title={`Frente ${item.identifier}`}
                      description={`Cuota ${fronts
                        .filter((front) => front.identifier === item.identifier)[0]
                        .settings.quota.toLocaleString()} toneladas`}
                      titleColor={design.colors.primary}
                    />
                  </Grid>
                  <Grid item xs={3} textAlign="right">
                    <ProgressIndicator value={item.quota_compliance} indicatorColor="primary" />
                  </Grid>
                </Grid>
              </Front>
            ))}
          </Container>
        </Grid>
      </Grid>
      <Title>Cumplimiento de cuotas</Title>
    </Wrapper>
  );
};
