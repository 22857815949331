import { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AvatarWithInfo, ModalConfirm, TableOptions } from '@components';
import { IProduct, INotification, INewProduct } from '@interfaces';
import { useAppDispatch } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { Form } from '../../form';
import { Cost, Price, Stock, MinimumExistence } from './styled';
import { helpers } from '@helpers';
import { settings } from '@constants';

interface IProps {
  items: IProduct[];
  notification: INotification;
}

export const Body = ({ items, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [selectedItem, setSelectedItem] = useState<IProduct | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onUpdate = (value: IProduct) => {
    setSelectedItem(value);
    setIsUpdating(true);
  };

  const onRemove = (value: IProduct) => {
    setSelectedItem(value);
    setIsRemoving(true);
  };

  const update = async (data: INewProduct) => {
    setIsProcessing(true);
    await services.product
      .update(controller.signal, data)
      .then((response) => {
        dispatch(slices.products.updateItem(response.data));
        notification.success('Se actualizó el producto seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible actualizar el producto seleccionado');
      });
    setIsUpdating(false);
    setIsProcessing(false);
  };

  const remove = async () => {
    setIsProcessing(true);
    await services.product
      .remove(controller.signal, selectedItem?.id || 0)
      .then(() => {
        dispatch(slices.products.deleteItem(selectedItem?.id || 0));
        notification.success('Se eliminó el producto seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible eliminar el producto seleccionado');
      });
    setIsRemoving(false);
    setIsProcessing(false);
  };

  const cancel = () => {
    setIsUpdating(false);
    setIsRemoving(false);
    setIsProcessing(false);
  };

  const tableOptions = (item: IProduct) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Actualizar',
          onClick: onUpdate,
        },
        {
          title: 'Eliminar',
          onClick: onRemove,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.description}</TableCell>
          {helpers.hasRequiredRoles([settings.roles.administrator]) && (
            <TableCell align="right">
              <Cost>
                <AvatarWithInfo title={item.cost.toFixed(2).toLocaleString()} description="GTQ" />
              </Cost>
            </TableCell>
          )}
          <TableCell align="right">
            <Price>
              <AvatarWithInfo title={item.price.toFixed(2).toLocaleString()} description="GTQ" />
            </Price>
          </TableCell>
          <TableCell align="right">
            <Stock low={String(item.existence < item.minimum_existence)}>
              {item.existence.toLocaleString()}
            </Stock>
          </TableCell>
          {helpers.hasRequiredRoles([settings.roles.administrator]) && (
            <TableCell align="right">
              <MinimumExistence>{item.minimum_existence}</MinimumExistence>
            </TableCell>
          )}
          {helpers.hasRequiredRoles([settings.roles.administrator]) && tableOptions(item)}
        </TableRow>
      ))}
      {isRemoving && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se eliminará el producto seleccionado"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={remove}
          secondaryAction={cancel}
          isProcessing={isProcessing}
        />
      )}
      {isUpdating && selectedItem && (
        <Form
          data={selectedItem}
          save={update}
          cancel={cancel}
          isProcessing={isProcessing}
          notification={notification}
        />
      )}
    </TableBody>
  );
};
