import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';

export const options = [
  { name: 'Regresar al inicio', url: '/', icon: <HomeIcon /> },
  {
    name: 'Información personal',
    url: '/settings/personal-information',
    icon: <AccountCircleIcon />,
  },
  { name: 'Empresa', url: '/settings/current-enterprise', icon: <BusinessIcon /> },
];
