import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Notification } from '@components';
import { useAppDispatch } from 'src/store/hooks';
import { hooks } from '@hooks';
import { services } from '@services';
import { helpers } from '@helpers';
import { slices } from '@slices';
import {
  Wrapper,
  Container,
  LogoWrapper,
  Logo,
  Title,
  Subtitle,
  PasswordRecovery,
  SignUpContainer,
  SignUpQuestion,
  SignUpLink,
} from './styled';
import logo from 'src/assets/logo.jpg';

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const screenWidth = hooks.useScreenWidth();
  const screenHeight = hooks.useScreenHeight();
  const [authenticated, setAuthenticated] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (helpers.isAuthenticated()) {
      if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
      navigate('/transport-management/indicators');
    } else {
      if (helpers.getUrlParam('event') === 'userCreated') {
        notification.success('Tu cuenta ha sido creada');
      }
      if (helpers.getUrlParam('event') === 'passwordChanged') {
        notification.success('Tu nueva contraseña ha sido registrada');
      }
      if (helpers.getUrlParam('event') === 'enterpriseCreated') {
        notification.success('Vuelve a iniciar sesión para cargar tu nueva empresa');
      }
      if (helpers.getUrlParam('event') === 'joinRequestCreated') {
        notification.success('Vuelve a iniciar sesión cuando aprueben tu solicitud');
      }
      if (helpers.getUrlParam('event') === 'sessionExpired') {
        notification.warning('Tu sesión ha expirado');
      }
      if (helpers.getUrlParam('event') === 'leaveEnterprise') {
        notification.warning('Has perdido el acceso a la información de la empresa');
      }
      setAuthenticated(false);
    }
    return () => {
      controller.abort();
    };
  }, []);

  const login = () => {
    if (email.trim() === '' || password.trim() === '') {
      notification.warning('Ingresa tu correo electrónico y contraseña');
    } else {
      services.security
        .login(controller.signal, email.trim(), password.trim())
        .then((response) => {
          helpers.setJwt(response.data.access_token);
          if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
          navigate('/transport-management/indicators');
        })
        .catch(() => {
          notification.warning('Tu correo electrónico o contraseña no son correctos');
        });
    }
  };

  return !authenticated ? (
    <Wrapper height={screenHeight}>
      <Container>
        <LogoWrapper>
          <Logo src={logo} />
        </LogoWrapper>
        <Title>Inicio de sesión</Title>
        <Subtitle>Ingresa tu correo electrónico y contraseña</Subtitle>
        <TextField
          label="Correo electrónico"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
          size="small"
          autoFocus
          fullWidth
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          label="Contraseña"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          variant="outlined"
          size="small"
          type="password"
          fullWidth
          sx={{ marginBottom: '10px' }}
        />
        <PasswordRecovery onClick={() => navigate('/password-recovery')}>
          Olvidé mi contraseña
        </PasswordRecovery>
        <Button onClick={login} variant="contained" size="large" fullWidth>
          Iniciar Sesión
        </Button>
        <SignUpContainer>
          <SignUpQuestion>¿Aún no has creado tu cuenta?</SignUpQuestion>
          <SignUpLink onClick={() => navigate('/signup')}>Crear cuenta</SignUpLink>
        </SignUpContainer>
      </Container>
      <Notification data={notification} />
    </Wrapper>
  ) : null;
};
