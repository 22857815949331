import { Searcher } from '@components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { Wrapper, Title } from './styled';

export const Header = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(slices.authorizedUsers.items);
  const searchCriteria = useAppSelector(slices.authorizedUsers.searchCriteria);

  const onSearch = (value: string) => {
    dispatch(slices.authorizedUsers.search(value));
  };

  return (
    <Wrapper>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={items.length === 0} />
      <Title>Conductores</Title>
    </Wrapper>
  );
};
