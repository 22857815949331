import { useNotification } from './useNotification';
import { useState } from './useState';
import { useFarm } from './useFarm';
import { useFront } from './useFront';
import { useTruck } from './useTruck';
import { useLink } from './useLink';
import { useFrontRoute } from './useFrontRoute';
import { useFrontState } from './useFrontState';
import { useFrontSettings } from './useFrontSettings';
import { useUserState } from './useUserState';
import { useTruckState } from './useTruckState';
import { useTrip } from './useTrip';
import { useAssignTrip } from './useAssignTrip';
import { useEquipment } from './useEquipment';
import { useEquipmentType } from './useEquipmentType';
import { useBrand } from './useBrand';
import { useScreenWidth } from './useScreenWidth';
import { useScreenHeight } from './useScreenHeight';

export const hooks = {
  useNotification,
  useState,
  useFarm,
  useFront,
  useTruck,
  useLink,
  useFrontRoute,
  useFrontState,
  useFrontSettings,
  useUserState,
  useTruckState,
  useTrip,
  useAssignTrip,
  useEquipment,
  useEquipmentType,
  useBrand,
  useScreenWidth,
  useScreenHeight,
};
