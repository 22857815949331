export const icons = [
  'Alarm',
  'AccessTime',
  'Accessibility',
  'AccountCircle',
  'AccountTree',
  'AdfScanner',
  'Adjust',
  'AdminPanelSettings',
  'Agriculture',
  'Air',
  'AirlineSeatIndividualSuite',
  'AirlineSeatLegroomExtra',
  'AirlineSeatReclineExtra',
  'AirlineStops',
  'AirportShuttle',
  'AlignHorizontalCenter',
  'AllInbox',
  'AllOut',
  'AltRoute',
  'Analytics',
  'Anchor',
  'Apartment',
  'AppSettingsAlt',
  'Approval',
  'Architecture',
  'AspectRatio',
  'AssistantDirection',
  'AssistantPhoto',
  'AttachFile',
  'AttachMoney',
  'Audiotrack',
  'AutoAwesome',
  'AutoAwesomeMosaic',
  'AutoFixHigh',
  'AutoGraph',
  'AutoStories',
  'Autorenew',
  'Balance',
  'BarChart',
  'BatteryChargingFull',
  'BatteryFull',
  'BeachAccess',
  'Bed',
  'Bedtime',
  'BlurOn',
  'Bolt',
  'Bookmark',
  'Build',
  'BusAlert',
  'BusinessCenter',
  'Cable',
  'CalendarViewDay',
  'Call',
  'CallMerge',
  'Camera',
  'CameraAlt',
  'Campaign',
  'CarRental',
  'CarRepair',
  'Category',
  'Celebration',
  'CellTower',
  'Chair',
  'Check',
  'Coffee',
  'ColorLens',
  'Colorize',
  'Construction',
  'ContentCut',
  'Cottage',
  'Delete',
  'DisplaySettings',
  'DonutSmall',
  'DoubleArrow',
  'EmojiEmotions',
  'EmojiEvents',
  'Explore',
  'Extension',
  'FilterHdr',
  'Folder',
  'Fort',
  'Gamepad',
  'Grass',
  'Groups',
  'Image',
  'Inbox',
  'Key',
  'Laptop',
  'ListAlt',
  'LocalDining',
  'LocalGasStation',
  'LocalPrintshop',
  'Lock',
  'Map',
  'Memory',
  'MilitaryTech',
  'Notifications',
  'OilBarrel',
  'OpenWith',
  'PanTool',
  'Place',
  'PrecisionManufacturing',
  'PushPin',
  'QuestionMark',
  'RecordVoiceOver',
  'Restaurant',
  'Security',
  'SentimentSatisfiedAlt',
  'SettingsVoice',
  'Stars',
  'ThumbDown',
  'ThumbUp',
  'Thunderstorm',
  'Visibility',
  'VolumeUp',
  'YouTube',
  'Workspaces',
];
