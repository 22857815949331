import { useEffect, useState } from 'react';
import {
  Modal,
  ModalButtons,
  ModalConfirm,
  WithoutData,
  Skeleton,
  SortableColumn,
} from '@components';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { slices } from '@slices';
import { services } from '@services';
import { INotification, IModule } from '@interfaces';
import { Information, Content } from './styled';
import Checkbox from '@mui/material/Checkbox';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { design } from '@constants';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const Activate = ({ close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedModules, setSelectedModules] = useState<number[]>([]);
  const [inactiveModules, setInactiveModules] = useState<IModule[]>([]);
  const modules = useAppSelector(slices.modules.items);
  const loading = useAppSelector(slices.modules.loading);
  const order = useAppSelector(slices.modules.order);
  const orderBy = useAppSelector(slices.modules.orderBy);

  const handleSort = (field: string) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc';
    dispatch(slices.modules.setOrder(newOrder));
    dispatch(slices.modules.setOrderBy(field));
    dispatch(slices.modules.sort({ field: field, order: newOrder }));
  };

  useEffect(() => {
    setInactiveModules(modules.filter((item) => !item.is_active));
  }, [modules]);

  const activate = async () => {
    setIsProcessing(true);
    await services.module
      .activate(controller.signal, selectedModules)
      .then((response) => {
        dispatch(slices.modules.update(response.data));
        notification.success('Los módulos seleccionados han sido activados');
        close();
      })
      .catch(() => {
        notification.warning('No fue posible activar los módulos seleccionados');
      });
    setIsProcessing(false);
  };

  const next = async () => {
    if (selectedModules.length) {
      setIsConfirming(true);
    } else {
      notification.warning('Debes seleccionar al menos un módulo');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedModules([...selectedModules, Number(event.target.name)]);
    } else {
      setSelectedModules(selectedModules.filter((item) => item !== Number(event.target.name)));
    }
  };

  return (
    <Modal
      title="Activación de módulos"
      close={close}
      actions={
        <ModalButtons
          isProcessing={isProcessing}
          primaryAction={next}
          secondaryAction={close}
          primaryText={inactiveModules.length ? 'Continuar' : undefined}
          secondaryText="Cancelar"
        />
      }
      maxWidth="md"
    >
      {loading ? (
        <Skeleton variant="table" />
      ) : inactiveModules.length ? (
        <>
          <Information>Precio por usuario al mes</Information>
          <Content>
            <TableContainer>
              <MUITable size="small" stickyHeader sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <SortableColumn
                      name="name"
                      value="Nombre"
                      order={order}
                      orderBy={orderBy}
                      handleSort={handleSort}
                    />
                    <SortableColumn
                      name="description"
                      value="Descripción"
                      order={order}
                      orderBy={orderBy}
                      handleSort={handleSort}
                    />
                    <SortableColumn
                      name="price"
                      value="Precio"
                      order={order}
                      orderBy={orderBy}
                      handleSort={handleSort}
                      align="right"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inactiveModules.map((item) => (
                    <TableRow key={item.id} hover>
                      <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Checkbox
                          name={`${item.id}`}
                          checked={selectedModules.includes(item.id)}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="right">
                        {item.price} USD
                        <Tooltip title="Precio por usuario al mes" placement="right" arrow>
                          <InfoIcon
                            sx={{ fontSize: 14 }}
                            htmlColor={design.colors.darkGray}
                            style={{ marginLeft: 5, marginBottom: -2 }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MUITable>
            </TableContainer>
          </Content>
        </>
      ) : (
        <WithoutData
          icon={<ViewModuleIcon />}
          title="No hay módulos sin activar"
          description="En esta sección se mostrarán los nuevos módulos pendientes de activación"
        />
      )}
      {isConfirming && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, los módulos seleccionados serán activados y se iniciará con el cobro mensual"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={activate}
          secondaryAction={() => setIsConfirming(false)}
          isProcessing={isProcessing}
        />
      )}
    </Modal>
  );
};
