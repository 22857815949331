import { useEffect, useState } from 'react';
import { Modal, ModalButtons, AvatarWithInfo } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IUser, INotification, ITrip } from '@interfaces';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { OptionContainer } from './styled';
import { settings } from '@constants';

interface IProps {
  data: ITrip;
  close: () => void;
  notification: INotification;
}

export const Transfer = ({ data, close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [user, setUser] = useState<IUser | null>(data.driver);
  const [isProcessing, setIsProcessing] = useState(false);
  const users = useAppSelector(slices.authorizedUsers.items);
  const loading = useAppSelector(slices.authorizedUsers.loading);
  const loaded = useAppSelector(slices.authorizedUsers.loaded);

  useEffect(() => {
    if (!loaded && !loading) getUsers();
    return () => {
      controller.abort();
    };
  }, []);

  const getUsers = async () => {
    dispatch(slices.authorizedUsers.setLoading(true));
    await services.user
      .authorized(controller.signal)
      .then((response) => {
        dispatch(slices.authorizedUsers.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los conductores');
      });
    dispatch(slices.authorizedUsers.setLoading(false));
  };

  const next = async () => {
    if (user) {
      if (user.id === data.driver.id) {
        notification.warning('Debes seleccionar un conductor diferente');
      } else {
        setIsProcessing(true);
        await services.trip
          .transfer(controller.signal, data.id, user.id)
          .then((response) => {
            dispatch(slices.trips.updateItem(response.data));
            notification.success('Hemos transferido el viaje seleccionado');
            close();
          })
          .catch((error) => {
            switch (error.response.data.detail) {
              case 'The new driver already has a trip assigned':
                notification.warning('El nuevo conductor ya tiene un viaje asignado');
                break;
              default:
                notification.warning('No fue posible transferir el viaje seleccionado');
                break;
            }
          });
        setIsProcessing(false);
      }
    } else {
      notification.warning('Debes seleccionar un conductor');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Transferir" close={close} actions={buttons} maxWidth="xs">
      <Autocomplete
        value={user}
        onChange={(_event: any, newValue: IUser | null) => setUser(newValue)}
        options={users.filter((item) => item.roles.includes(settings.roles.driver))}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Conductor"
            size="small"
            autoFocus
            sx={{ marginTop: '10px', marginBottom: '20px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              avatar={option.avatar}
              title={`${option.first_name} ${option.last_name}`}
              description={option.phone}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};
