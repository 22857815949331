import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { INotification, IUseFarm } from '@interfaces';
import { helpers } from '@helpers';

interface IProps {
  data: IUseFarm;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe una finca con este nombre');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} finca`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Nombre"
        value={data.name}
        onChange={(event) => data.setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <TextField
        label="Ubicación"
        value={data.location}
        onChange={(event) => data.setLocation(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <TextField
        label="Distancia"
        value={data.distance}
        onChange={(event) =>
          data.setDistance(helpers.validateNumber(data.distance, event.target.value, true))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
        InputProps={{
          endAdornment: <InputAdornment position="end">km</InputAdornment>,
        }}
      />
    </Modal>
  );
};
