import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { SortableColumn } from '@components';
import { helpers } from '@helpers';
import { settings } from '@constants';

export const Header = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(slices.products.order);
  const orderBy = useAppSelector(slices.products.orderBy);

  const handleSort = (field: string) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc';
    dispatch(slices.products.setOrder(newOrder));
    dispatch(slices.products.setOrderBy(field));
    dispatch(slices.products.sort({ field: field, order: newOrder }));
  };

  return (
    <TableHead>
      <TableRow>
        <SortableColumn
          name="name"
          value="Producto"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="description"
          value="Descripción"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        {helpers.hasRequiredRoles([settings.roles.administrator]) && (
          <SortableColumn
            name="cost"
            value="Costo"
            order={order}
            orderBy={orderBy}
            handleSort={handleSort}
            align="right"
            width={60}
          />
        )}
        <SortableColumn
          name="price"
          value="Precio"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
          align="right"
          width={60}
        />
        <SortableColumn
          name="existence"
          value="Existencia"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
          align="right"
          width={80}
        />
        {helpers.hasRequiredRoles([settings.roles.administrator]) && (
          <SortableColumn
            name="minimum_existence"
            value="Existencia mínima"
            order={order}
            orderBy={orderBy}
            handleSort={handleSort}
            align="right"
            width={130}
          />
        )}
        {helpers.hasRequiredRoles([settings.roles.administrator]) && (
          <TableCell width={30}></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
