import ViewListIcon from '@mui/icons-material/ViewList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GrassIcon from '@mui/icons-material/Grass';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import DownloadingIcon from '@mui/icons-material/Downloading';
import CategoryIcon from '@mui/icons-material/Category';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import PeopleIcon from '@mui/icons-material/People';
import { settings } from '@constants';
import { helpers } from '@helpers';

export const transportManagement = () => {
  const options = [
    {
      name: 'Indicadores',
      url: '/transport-management/indicators',
      roles: [settings.roles.administrator, settings.roles.controller],
      icon: <DashboardIcon />,
    },
    {
      name: 'Distribución',
      url: '/transport-management/distribution',
      roles: [settings.roles.administrator, settings.roles.controller],
      icon: <ViewListIcon />,
    },
    {
      name: 'Descarga',
      url: '/transport-management/equipments/download',
      roles: [
        settings.roles.administrator,
        settings.roles.controller,
        settings.roles.downloadCoordinator,
      ],
      icon: <DownloadingIcon />,
    },
    {
      name: 'Escenarios',
      url: '/transport-management/scenarios',
      roles: [],
      icon: <CalendarMonthIcon />,
    },
    {
      name: 'Frentes',
      url: '/transport-management/fronts',
      roles: [settings.roles.administrator, settings.roles.controller],
      icon: <AgricultureIcon />,
    },
    {
      name: 'Fincas',
      url: '/transport-management/farms',
      roles: [settings.roles.administrator, settings.roles.controller],
      icon: <GrassIcon />,
    },
    {
      name: 'Conductores',
      url: '/transport-management/drivers',
      roles: [
        settings.roles.administrator,
        settings.roles.controller,
        settings.roles.resourceManager,
      ],
      icon: <PeopleIcon />,
    },
    {
      name: 'Camiones',
      url: '/transport-management/trucks',
      roles: [
        settings.roles.administrator,
        settings.roles.controller,
        settings.roles.resourceManager,
      ],
      icon: <LocalShippingIcon />,
    },
    {
      name: 'Equipos',
      url: '/transport-management/equipments',
      roles: [settings.roles.administrator, settings.roles.resourceManager],
      icon: <LinearScaleIcon />,
    },
    {
      name: 'Tipos de equipo',
      url: '/transport-management/equipments/types',
      roles: [settings.roles.administrator, settings.roles.resourceManager],
      icon: <CategoryIcon />,
    },
    {
      name: 'Marcas',
      url: '/transport-management/brands',
      roles: [settings.roles.administrator, settings.roles.resourceManager],
      icon: <ViewColumnIcon />,
    },
    {
      name: 'Estados',
      url: '/transport-management/states',
      roles: [
        settings.roles.administrator,
        settings.roles.controller,
        settings.roles.resourceManager,
      ],
      icon: <ChangeCircleIcon />,
    },
    {
      name: 'Asignación actual',
      url: '/transport-management/drivers/current-trip',
      roles: [settings.roles.driver],
      icon: <FactCheckIcon />,
    },
    {
      name: 'Estado actual',
      url: '/transport-management/fronts/current-state',
      roles: [settings.roles.frontBoss],
      icon: <DisplaySettingsIcon />,
    },
  ];
  return options.filter((option) => helpers.hasRequiredRoles(option.roles));
};
