import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IFrontDetail, IFrontState } from '@interfaces';

const initialState: IFrontState = {
  item: null,
  loaded: false,
  loading: false,
};

const frontSlice = createSlice({
  name: 'front',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItem: (state, action: PayloadAction<IFrontDetail | null>) => {
      state.item = action.payload;
      state.loaded = true;
    },
  },
});

const { setLoading, setItem } = frontSlice.actions;
const item = (state: RootState) => state.front.item;
const loaded = (state: RootState) => state.front.loaded;
const loading = (state: RootState) => state.front.loading;

export const front = {
  setLoading,
  setItem,
  item,
  loaded,
  loading,
};

export default frontSlice.reducer;
