import { AvatarWithInfo } from '@components';
import { IFront } from '@interfaces';
import { helpers } from '@helpers';
import { Data } from './../styled';

interface IProps {
  item: IFront;
}

export const Route = ({ item }: IProps) => {
  const getDescription = () => {
    if (item.state.name === 'Activo') {
      return item.settings.route;
    }
    const description = `${item.state.name} ${helpers
      .getTimeFromNow(item.state_date)
      .toLowerCase()}`;
    return item.state_description ? `${description}. ${item.state_description}` : description;
  };

  return (
    <Data>
      <AvatarWithInfo
        title={item.farm.name}
        description={getDescription()}
        descriptionColor={item.state.name === 'Activo' ? undefined : item.state.color}
        tooltipTitle={`${item.farm.distance} km`}
      />
    </Data>
  );
};
