import {
  setJwt,
  getJwt,
  isAuthenticated,
  deleteJwt,
  getPropertyInCurrentUser,
  updateCurrentUser,
  updateCurrentUserState,
  updateCurrentUserAvatar,
  updateCurrentUserEnterpriseLogo,
  hasRequiredRoles,
  getHomePage,
  moduleIsActive,
  getActiveRoles,
} from './security';
import {
  sleep,
  copyToClipboard,
  getUrlParam,
  getLogo,
  getAvatar,
  getLowerCase,
  normalize,
  orderList,
  search,
  getScope,
  validateNumber,
  getTimeFromNow,
  getMonthAndYear,
  getFormattedNumber,
} from './usability';

export const helpers = {
  setJwt,
  getJwt,
  isAuthenticated,
  deleteJwt,
  getPropertyInCurrentUser,
  updateCurrentUser,
  updateCurrentUserState,
  updateCurrentUserAvatar,
  updateCurrentUserEnterpriseLogo,
  hasRequiredRoles,
  getHomePage,
  sleep,
  copyToClipboard,
  getUrlParam,
  getLogo,
  getAvatar,
  getLowerCase,
  normalize,
  orderList,
  search,
  getScope,
  validateNumber,
  getTimeFromNow,
  getMonthAndYear,
  moduleIsActive,
  getActiveRoles,
  getFormattedNumber,
};
