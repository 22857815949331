import { Wrapper, Option, Title } from './styled';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';

interface IProps {
  rotation: number;
  zoom: number;
  setRotation: (value: number) => void;
  setZoom: (value: number) => void;
}

export const Options = ({ rotation, zoom, setRotation, setZoom }: IProps) => {
  const handleChangeRotation = (_event: Event, newValue: number | number[]) => {
    setRotation(newValue as number);
  };

  const handleChangeZoom = (_event: Event, newValue: number | number[]) => {
    setZoom(newValue as number);
  };

  return (
    <Wrapper container>
      <Grid item xs={6}>
        <Option>
          <Title>Rotación</Title>
          <Slider value={rotation} onChange={handleChangeRotation} min={0} max={360} step={1} />
        </Option>
      </Grid>
      <Grid item xs={6}>
        <Option>
          <Title>Acercamiento</Title>
          <Slider value={zoom} onChange={handleChangeZoom} min={1} max={3} step={0.1} />
        </Option>
      </Grid>
    </Wrapper>
  );
};
