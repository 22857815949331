import { useEffect } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import { IUseEquipmentType, INotification } from '@interfaces';

interface IProps {
  data: IUseEquipmentType;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe un tipo con este nombre');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} tipo`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Nombre"
        value={data.name}
        onChange={(event) => data.setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
    </Modal>
  );
};
