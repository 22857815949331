import { useEffect, useState } from 'react';
import { AvatarWithInfo, Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { INotification, IProduct, IUpdateExistence } from '@interfaces';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { helpers } from '@helpers';
import { services } from '@services';
import { OptionContainer } from './styled';

interface IProps {
  isInput: boolean;
  close: () => void;
  notification: INotification;
}

export const UpdateExistence = ({ isInput, close, notification }: IProps) => {
  const controller = new AbortController();
  const dispatch = useAppDispatch();
  const products = useAppSelector(slices.products.items);
  const [product, setProduct] = useState<IProduct | null>(null);
  const [amount, setAmount] = useState('');
  const [observations, setObservations] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = () => {
    if (isValid()) {
      save({
        product: product?.id || 0,
        is_input: isInput,
        amount: Number(amount.trim()),
        observations: observations.trim(),
      });
    }
  };

  const save = async (data: IUpdateExistence) => {
    setIsProcessing(true);
    await services.product
      .updateExistence(controller.signal, data)
      .then((response) => {
        dispatch(slices.products.updateItem(response.data));
        if (isInput) {
          notification.success('Se registró la entrada de producto');
        } else {
          notification.success('Se registró la salida de producto');
        }
        close();
      })
      .catch(() => {
        if (isInput) {
          notification.warning('No fue posible registrar la entrada de producto');
        } else {
          notification.warning('No fue posible registrar la salida de producto');
        }
      });
    setIsProcessing(false);
  };

  const isValid = () => {
    if (product && amount.trim().length && observations.trim().length) {
      if (!isInput && product.existence < Number(amount.trim())) {
        notification.warning('No hay suficiente existencia del producto seleccionado');
        return false;
      }
      return true;
    }
    notification.warning('Debes completar todos los campos');
    return false;
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={isInput ? 'Entrada de producto' : 'Salida de producto'}
      close={close}
      actions={buttons}
      maxWidth="xs"
    >
      <Autocomplete
        value={product}
        onChange={(_event: any, newValue: IProduct | null) => setProduct(newValue)}
        options={products}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Producto"
            size="small"
            autoFocus
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              title={`${option.name} (${option.existence})`}
              description={option.description}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
      <TextField
        label="Cantidad"
        value={amount}
        onChange={(event) => setAmount(helpers.validateNumber(amount, event.target.value, false))}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
      <TextField
        label="Observaciones"
        value={observations}
        onChange={(event) => setObservations(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
    </Modal>
  );
};
