import { useEffect } from 'react';
import { ChipWrapper, Container } from './styled';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Chip from '@mui/material/Chip';
import { Skeleton, WithoutData, Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import PeopleIcon from '@mui/icons-material/People';
import { Header } from './header';
import { Body } from './body';
import { IUser } from '@interfaces';
import { settings } from '@constants';

export const Table = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.authorizedUsers.items);
  const searchResults = useAppSelector(slices.authorizedUsers.searchResults);
  const searchCriteria = useAppSelector(slices.authorizedUsers.searchCriteria);
  const loading = useAppSelector(slices.authorizedUsers.loading);
  const loaded = useAppSelector(slices.authorizedUsers.loaded);

  useEffect(() => {
    if (!loaded && !loading) getItems();
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.authorizedUsers.setLoading(true));
    await services.user
      .authorized(controller.signal)
      .then((response) => {
        dispatch(slices.authorizedUsers.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los conductores');
      });
    dispatch(slices.authorizedUsers.setLoading(false));
  };

  const getTotalItems = () => {
    let totalItems = 0;
    if (searchCriteria === '') {
      totalItems = items.filter((item) => item.roles.includes(settings.roles.driver)).length;
    } else {
      totalItems = searchResults.filter((item) =>
        item.roles.includes(settings.roles.driver),
      ).length;
    }
    return (
      <Chip
        label={`${totalItems} ${
          totalItems === 1 ? 'conductor encontrado' : 'conductores encontrados'
        }`}
        size="small"
      />
    );
  };

  const buildTable = (dataList: IUser[]) => (
    <TableContainer>
      <MUITable size="small" stickyHeader sx={{ minWidth: 800 }}>
        <Header />
        <Body items={dataList} notification={notification} />
      </MUITable>
    </TableContainer>
  );

  return (
    <>
      <ChipWrapper>{getTotalItems()}</ChipWrapper>
      <Container>
        {loading ? (
          <Skeleton variant="table" />
        ) : searchCriteria === '' ? (
          items.filter((item) => item.roles.includes(settings.roles.driver)).length ? (
            buildTable(items.filter((item) => item.roles.includes(settings.roles.driver)))
          ) : (
            <WithoutData
              icon={<PeopleIcon />}
              title="No hay conductores"
              description="En esta sección se mostrarán los conductores que han sido agregados"
            />
          )
        ) : searchResults.filter((item) => item.roles.includes(settings.roles.driver)).length ? (
          buildTable(searchResults.filter((item) => item.roles.includes(settings.roles.driver)))
        ) : (
          <WithoutData
            icon={<PeopleIcon />}
            title="No hay coincidencias"
            description="En esta sección se mostrarán los conductores que coincidan con los criterios de búsqueda"
          />
        )}
        <Notification data={notification} />
      </Container>
    </>
  );
};
