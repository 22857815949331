import { Wrapper, EnterpriseName, CopyEnterpriseToken } from './styled';
import { helpers } from '@helpers';
import { INotification } from '@interfaces';

interface IProps {
  handleClose: () => void;
  notification: INotification;
}

export const Footer = ({ handleClose, notification }: IProps) => {
  const copyEnterpriseToken = () => {
    helpers.copyToClipboard(helpers.getPropertyInCurrentUser('enterprise')['token']);
    notification.success('El código de empresa ha sido copiado');
    handleClose();
  };

  return (
    <Wrapper>
      <EnterpriseName>{helpers.getPropertyInCurrentUser('enterprise')['name']}</EnterpriseName>
      <CopyEnterpriseToken onClick={copyEnterpriseToken}>
        Copiar código de empresa
      </CopyEnterpriseToken>
    </Wrapper>
  );
};
