import { useState } from 'react';
import { Modal, ModalButtons } from '@components';
import { ChromePicker } from 'react-color';
import { ColorIndicator, Container } from './styled';
import TextField from '@mui/material/TextField';

interface IProps {
  color: string;
  setColor: (value: string) => void;
}

export const ColorPicker = ({ color, setColor }: IProps) => {
  const [open, setOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState(color);

  const next = () => {
    setColor(currentColor);
    setOpen(false);
  };

  const buttons = (
    <ModalButtons
      isProcessing={false}
      primaryAction={next}
      secondaryAction={() => setOpen(false)}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <div>
      <ColorIndicator color={color}>
        <div></div>
      </ColorIndicator>
      <TextField
        label="Color"
        value={color}
        onClick={() => setOpen(true)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      {open && (
        <Modal
          title="Selección de color"
          close={() => setOpen(false)}
          actions={buttons}
          maxWidth="xs"
        >
          <Container>
            <ChromePicker
              color={currentColor}
              onChangeComplete={(color: any) => setCurrentColor(color.hex)}
              disableAlpha={true}
            />
          </Container>
        </Modal>
      )}
    </div>
  );
};
