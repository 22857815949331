import { design } from '@constants';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 68px);
  overflow: auto;
  ${(props: any) =>
    props.hasNotData
      ? css`
          border-radius: 10px;
          padding: 5px 20px;
          margin-left: 5px;
          margin-right: 24px;
          background-color: white;
          box-shadow: ${design.shadows.default};
          @media (max-width: 600px) {
            margin-left: 3px;
            margin-right: 17px;
          }
        `
      : css`
          padding: 5px 19px 5px 5px;
          @media (max-width: 600px) {
            padding-left: 3px;
            padding-right: 11px;
          }
        `}
`;

export const Container = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
  > svg {
    color: ${design.colors.darkGray};
    position: absolute;
    right: 20px;
    margin-top: -10px;
    margin-right: -10px;
    font-size: 16px;
    transition: 0.2s;
    :hover {
      color: ${design.colors.primary};
    }
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  ${(props: any) =>
    props.color
      ? css`
          color: ${props.color};
        `
      : css`
          color: ${design.colors.primary};
        `}
`;

export const Subtitle = styled.div`
  padding-top: 10px;
  color: ${design.colors.darkGray};
`;
