import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { hooks } from '@hooks';

interface IProp {
  value: string;
  onSearch: (value: string) => void;
  autoFocus?: boolean;
  disabled?: boolean;
}

export const Searcher = ({ value, onSearch, autoFocus = false, disabled = false }: IProp) => {
  const screenWidth = hooks.useScreenWidth();

  return (
    <TextField
      placeholder="Buscar"
      value={value}
      onChange={(event: any) => onSearch(event.target.value)}
      variant="outlined"
      size="small"
      autoFocus={autoFocus}
      InputProps={
        screenWidth > 900
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }
          : {}
      }
      style={screenWidth < 900 ? { width: 80 } : {}}
      disabled={disabled}
    />
  );
};
