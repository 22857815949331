import { useState } from 'react';
import { IUseEquipmentType, IEquipmentType } from '@interfaces';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

export const useEquipmentType = () => {
  const items = useAppSelector(slices.equipmentTypes.items);
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: IEquipmentType) => {
    setId(item ? item.id : 0);
    setName(item ? item.name : '');
  };

  const isValid = () => {
    if (name.trim() === '') {
      setName(name.trim());
      return false;
    }
    return true;
  };

  const isDuplicated = () =>
    items.filter((item) => item.name.toLowerCase() === name.trim().toLowerCase() && item.id !== id)
      .length > 0;

  const object = () => {
    return {
      id: id,
      name: name.trim(),
    };
  };

  const data: IUseEquipmentType = {
    id,
    name,
    isCreating,
    isUpdating,
    isDeleting,
    isProcessing,
    setName,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsProcessing,
    initialize,
    isValid,
    isDuplicated,
    object,
  };

  return data;
};
