import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Wrapper } from './styled';
import { useEffect, useState } from 'react';

interface IProps {
  value: number;
  title?: string;
  suffix?: string;
  indicatorSize?: number;
  titleSize?: number;
  countdown?: boolean;
  indicatorColor?: 'error' | 'warning' | 'success' | 'primary' | 'secondary' | 'info';
}

export const ProgressIndicator = ({
  value,
  title,
  suffix = '%',
  indicatorSize = 40,
  titleSize = 12,
  countdown = false,
  indicatorColor,
}: IProps) => {
  const [color, setColor] = useState<any>(undefined);

  useEffect(() => {
    if (indicatorColor) {
      setColor(indicatorColor);
    } else {
      if (countdown) {
        if (value < 6) {
          setColor('error');
        } else {
          if (value < 11) {
            setColor('warning');
          } else {
            setColor('success');
          }
        }
      } else {
        if (value < 91) {
          setColor('success');
        } else {
          if (value < 96) {
            setColor('warning');
          } else {
            setColor('error');
          }
        }
      }
    }
  }, [value]);

  return (
    <Wrapper sx={{ position: 'relative', display: 'inline-flex' }} indicatorsize={indicatorSize}>
      <CircularProgress variant="determinate" value={value > 100 ? 100 : value} color={color} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={titleSize}
        >{`${title || value}${suffix}`}</Typography>
      </Box>
    </Wrapper>
  );
};
