import { IFrontDetail } from '@interfaces';
import { Wrapper, Container, Title, Subtitle } from './styled';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { ProgressIndicator, WithoutData, Skeleton } from '@components';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { helpers } from '@helpers';

interface IProps {
  data: IFrontDetail | null;
  loading: boolean;
}

export const Detail = ({ data, loading }: IProps) => {
  return (
    <Wrapper hasNotData={!loading && !data}>
      {loading && <Skeleton variant="frontDetail" />}
      {!loading && !data && (
        <WithoutData
          icon={<AgricultureIcon />}
          title="No hay frente asignado"
          description="En esta sección se mostrarán los detalles del frente asignado"
        />
      )}
      {!loading && data && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Container>
              <ProgressIndicator
                value={Math.round(data.time_compliance)}
                indicatorSize={100}
                titleSize={25}
                indicatorColor="success"
              />
              <Subtitle>Cumplimiento del tiempo de carga</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <ProgressIndicator
                value={Math.round((data.cane_entered * 100) / data.settings.quota)}
                indicatorSize={100}
                titleSize={25}
              />
              {data.cane_entered > 0 && (
                <Tooltip title={`${data.cane_entered.toLocaleString()} toneladas`} arrow>
                  <InfoIcon />
                </Tooltip>
              )}
              <Subtitle>Cumplimiento de cuota</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Title color={data.state.color}>
                {data.state.name} {helpers.getTimeFromNow(data.state_date).toLowerCase()}
              </Title>
              <Subtitle>{data.state_description || data.state.description}</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Title>{data.empty_trucks}</Title>
              <Subtitle>Camiones en ruta vacíos</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Title>{data.loading_trucks}</Title>
              <Subtitle>Camiones cargando</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Title>{data.loaded_trucks}</Title>
              <Subtitle>Camiones en ruta cargados</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Title>{data.farm.distance} km</Title>
              <Subtitle>Distancia</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Title>{data.equipment_type.name}</Title>
              <Subtitle>Tipo de equipo</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Title>{data.settings.load_weight} t</Title>
              <Subtitle>Peso por jaula</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Title>{data.settings.quota.toLocaleString()} t</Title>
              <Subtitle>Cuota asignada</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Title>Finca {data.farm.name}</Title>
              <Subtitle>{data.settings.route}</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Title>{data.settings.minimum_cages}</Title>
              <Subtitle>Mínimo de jaulas por viaje</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Title>{data.settings.maximum_cages}</Title>
              <Subtitle>Máximo de jaulas por viaje</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Title>{data.settings.simultaneous_loads}</Title>
              <Subtitle>Carga simultánea de jaulas</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Tooltip title={`${Math.ceil(data.settings.time_to_go * 60)} minutos`} arrow>
                <InfoIcon />
              </Tooltip>
              <Title>{data.settings.time_to_go} h</Title>
              <Subtitle>Tiempo de viaje vacío</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Tooltip title={`${Math.ceil(data.settings.time_to_load * 60)} minutos`} arrow>
                <InfoIcon />
              </Tooltip>
              <Title>{data.settings.time_to_load} h</Title>
              <Subtitle>Tiempo de carga por jaula</Subtitle>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container>
              <Tooltip title={`${Math.ceil(data.settings.time_to_return * 60)} minutos`} arrow>
                <InfoIcon />
              </Tooltip>
              <Title>{data.settings.time_to_return} h</Title>
              <Subtitle>Tiempo de viaje cargado</Subtitle>
            </Container>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};
