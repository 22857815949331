import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Wrapper, Title, Subtitle } from './styled';
import { ModalButtons } from '@components';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useNavigate } from 'react-router-dom';
import { helpers } from '@helpers';

interface IProps {
  setJoiningEnterprise: (value: boolean) => void;
}

export const JoinEnterprise = ({ setJoiningEnterprise }: IProps) => {
  const navigate = useNavigate();
  const notification = hooks.useNotification();
  const controller = new AbortController();
  const [token, setToken] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    if (token.trim() === '') {
      notification.warning('Ingresa el código de tu empresa');
    } else {
      setIsProcessing(true);
      await services.enterprise
        .joinRequest(controller.signal, token.trim())
        .then(() => {
          helpers.deleteJwt();
          navigate('/login?event=joinRequestCreated');
        })
        .catch(() => {
          notification.warning('El código de empresa no existe');
        });
      setIsProcessing(false);
    }
  };

  const cancel = () => {
    setJoiningEnterprise(false);
  };

  return (
    <Wrapper>
      <Title>Unirme a una empresa</Title>
      <Subtitle>Ingresa el código de la empresa para continuar</Subtitle>
      <TextField
        label="Código de empresa"
        value={token}
        onChange={(event) => setToken(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '30px' }}
      />
      <ModalButtons
        isProcessing={isProcessing}
        secondaryAction={cancel}
        primaryAction={next}
        secondaryText="Cancelar"
        primaryText="Continuar"
      />
      <Notification data={notification} />
    </Wrapper>
  );
};
