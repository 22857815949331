import { useState } from 'react';
import { Wrapper, Title } from './styled';
import { Notification } from '@components';
import { hooks } from '@hooks';
import Button from '@mui/material/Button';
import { Activate } from './activate';

export const Header = () => {
  const notification = hooks.useNotification();
  const [isAdding, setIsAdding] = useState(false);

  return (
    <Wrapper>
      <Button onClick={() => setIsAdding(true)} variant="outlined" size="large">
        Agregar
      </Button>
      <Title>Módulos activos</Title>
      {isAdding && <Activate close={() => setIsAdding(false)} notification={notification} />}
      <Notification data={notification} />
    </Wrapper>
  );
};
