import { useEffect, useState } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import { INotification, IProduct, INewProduct } from '@interfaces';
import { slices } from '@slices';
import { useAppSelector } from 'src/store/hooks';
import { helpers } from '@helpers';

interface IProps {
  data?: IProduct;
  save: (data: INewProduct) => void;
  cancel: () => void;
  isProcessing: boolean;
  notification: INotification;
}

export const Form = ({ data, save, cancel, isProcessing, notification }: IProps) => {
  const controller = new AbortController();
  const items = useAppSelector(slices.products.items);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState('');
  const [price, setPrice] = useState('');
  const [minimumExistence, setMinimumExistence] = useState('');

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setCost(data.cost.toString());
      setPrice(data.price.toString());
      setMinimumExistence(data.minimum_existence.toString());
    }
    return () => {
      controller.abort();
    };
  }, []);

  const next = () => {
    if (isValid() && !isDuplicated()) {
      save({
        id: data?.id || 0,
        name: name.trim(),
        description: description.trim(),
        cost: Number(cost.trim()),
        price: Number(price.trim()),
        minimum_existence: Number(minimumExistence.trim()),
      });
    }
  };

  const isValid = () => {
    if (
      name.trim().length &&
      description.trim().length &&
      cost.trim().length &&
      price.trim().length &&
      minimumExistence.trim().length
    ) {
      return true;
    }
    notification.warning('Debes completar todos los campos');
    return false;
  };

  const isDuplicated = () => {
    const dataList = items.filter(
      (item) =>
        item.name.trim().toLowerCase() === name.trim().toLowerCase() && item.id !== data?.id,
    );
    if (dataList.length) {
      notification.warning('Ya existe un producto con este nombre');
      return true;
    }
    return false;
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data ? 'Actualizar' : 'Agregar nuevo'} producto`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Nombre"
        value={name}
        onChange={(event) => setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
      <TextField
        label="Descripción"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
      <TextField
        label="Costo"
        value={cost}
        onChange={(event) => setCost(helpers.validateNumber(cost, event.target.value, true))}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
      <TextField
        label="Precio"
        value={price}
        onChange={(event) => setPrice(helpers.validateNumber(price, event.target.value, true))}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
      <TextField
        label="Existencia mínima"
        value={minimumExistence}
        onChange={(event) =>
          setMinimumExistence(helpers.validateNumber(minimumExistence, event.target.value, false))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
    </Modal>
  );
};
