import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  padding: 9px 10px 10px 6px;
  color: ${design.colors.primary};
  letter-spacing: 0.02857em;
`;

export const Container = styled.div`
  padding: 10px 19px 5px 8px;
  height: calc(100% - 63px);
  overflow: auto;
  @media (max-width: 600px) {
    padding-right: 11px;
  }
`;
