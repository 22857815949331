import { axiosInstance } from './axios';
import { api } from '@constants';
import { ILink } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.links, { signal: signal });
};

const create = (signal: AbortSignal, link: ILink) => {
  return axiosInstance.post(api.links, link, { signal: signal });
};

const update = (signal: AbortSignal, link: ILink) => {
  return axiosInstance.put(api.links, link, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.links}/${id}`, { signal: signal });
};

export const link = {
  all,
  create,
  update,
  del,
};
