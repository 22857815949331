import { urlBase, api, settings } from '@constants';
import moment from 'moment';
import 'moment/locale/es';

export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const copyToClipboard = (value: string) => {
  if (navigator.clipboard) navigator.clipboard.writeText(value);
};

export const getUrlParam = (name: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const getLogo = (filename: string) => {
  return `${urlBase}${api.enterprises}/logo/${filename}`;
};

export const getAvatar = (filename: string) => {
  return filename ? `${urlBase}${api.users}/avatar/${filename}` : filename;
};

export const getLowerCase = (value: any) => (value ? value.trim().toLowerCase() : '');

export const normalize = (value: string) => {
  return getLowerCase(value)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const orderList = (dataList: any[], field: string, order: string) => {
  return dataList.length === 0
    ? []
    : dataList.sort((a, b) => {
        const dataType = typeof dataList[0][field.split('.')[0]];
        if (order === 'asc') {
          if (dataType === 'number') {
            return a[field] - b[field];
          } else {
            if (dataType === 'string') {
              return a[field].localeCompare(b[field], 'en', { sensitivity: 'base' });
            } else {
              const subdataType = typeof dataList[0][field.split('.')[0]][field.split('.')[1]];
              if (subdataType === 'number') {
                return (
                  a[field.split('.')[0]][field.split('.')[1]] -
                  b[field.split('.')[0]][field.split('.')[1]]
                );
              } else {
                return a[field.split('.')[0]][field.split('.')[1]].localeCompare(
                  b[field.split('.')[0]][field.split('.')[1]],
                  'en',
                  { sensitivity: 'base' },
                );
              }
            }
          }
        } else {
          if (dataType === 'number') {
            return b[field] - a[field];
          } else {
            if (dataType === 'string') {
              return b[field].localeCompare(a[field], 'en', { sensitivity: 'base' });
            } else {
              const subdataType = typeof dataList[0][field.split('.')[0]][field.split('.')[1]];
              if (subdataType === 'number') {
                return (
                  b[field.split('.')[0]][field.split('.')[1]] -
                  a[field.split('.')[0]][field.split('.')[1]]
                );
              } else {
                return b[field.split('.')[0]][field.split('.')[1]].localeCompare(
                  a[field.split('.')[0]][field.split('.')[1]],
                  'en',
                  { sensitivity: 'base' },
                );
              }
            }
          }
        }
      });
};

export const search = (criteria: string, dataList: any[], searchFields: string[]) => {
  return dataList.filter((item: any) => {
    let result = false;
    for (const [key, value] of Object.entries(item)) {
      if (value) {
        if (
          searchFields.length &&
          !searchFields.map((field) => field.split('.')[0]).includes(key)
        ) {
          continue;
        }
        if (typeof value === 'object') {
          for (const [subkey, subvalue] of Object.entries(value)) {
            if (searchFields.length && !searchFields.includes(`${key}.${subkey}`)) {
              continue;
            } else {
              if (equalValues(criteria, subkey, value, `${subvalue}`)) {
                result = true;
                break;
              }
            }
          }
          if (result) {
            break;
          }
        } else {
          if (equalValues(criteria, key, item, `${value}`)) {
            result = true;
            break;
          }
        }
      }
    }
    return result;
  });
};

const equalValues = (criteria: string, key: string, value: object, subvalue: string) => {
  let finalValue = '';
  if (key === 'first_name') {
    finalValue = `${Object(value).first_name} ${Object(value).last_name}`;
  } else {
    if (key === 'birthday') {
      finalValue = moment(subvalue).format('DD/MM/YYYY');
    } else {
      finalValue = `${subvalue}`;
    }
  }
  return normalize(finalValue).includes(normalize(criteria));
};

export const getScope = (id: any) => {
  return settings.scopes.filter((item) => item.id === id)[0];
};

export const validateNumber = (previousValue: string, newValue: string, hasDecimals: boolean) => {
  const numberPattern = hasDecimals ? /^([0-9]+(\.[0-9]{0,2})?)?$/ : /^([0-9]+)?$/;
  if (numberPattern.test(newValue)) {
    return newValue;
  }
  return previousValue;
};

export const getTimeFromNow = (value: string) => {
  const result = moment(value).locale('es').fromNow();
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};

export const getMonthAndYear = (value: string) => {
  const result = moment(value).locale('es').format('MMMM YYYY');
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};

export const getFormattedNumber = (value: number) => {
  const newValue = Number(value.toFixed(2)).toLocaleString();
  if (newValue.includes('.')) {
    return newValue;
  }
  return `${newValue}.00`;
};
