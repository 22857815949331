import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const Dot = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${(props: any) => props.color};
  border-radius: 50%;
  position: absolute;
  right: 16px;
`;

export const StateWrapper = styled.div`
  padding: 5px;
`;

export const StateDate = styled.div`
  font-size: 12px;
`;

export const StateName = styled.div`
  padding-top: 10px;
  font-weight: bold;
`;

export const StateDescription = styled.div`
  padding-top: 5px;
`;
