import { useEffect } from 'react';
import { Modal, ModalButtons, WithoutData, Skeleton, SortableColumn } from '@components';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { slices } from '@slices';
import { services } from '@services';
import { INotification } from '@interfaces';
import { Information, Content } from './styled';
import Checkbox from '@mui/material/Checkbox';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

interface IProps {
  selectedModules: number[];
  setSelectedModules: (value: number[]) => void;
  next: () => void;
  close: () => void;
  notification: INotification;
}

export const Activate = ({
  selectedModules,
  setSelectedModules,
  next,
  close,
  notification,
}: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const modules = useAppSelector(slices.modules.items);
  const loading = useAppSelector(slices.modules.loading);
  const loaded = useAppSelector(slices.modules.loaded);
  const order = useAppSelector(slices.modules.order);
  const orderBy = useAppSelector(slices.modules.orderBy);

  useEffect(() => {
    if (!loaded && !loading) getModules();
    return () => {
      controller.abort();
    };
  }, []);

  const getModules = async () => {
    dispatch(slices.modules.setLoading(true));
    await services.module
      .available(controller.signal)
      .then((response) => {
        dispatch(slices.modules.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los modulos disponibles');
      });
    dispatch(slices.modules.setLoading(false));
  };

  const handleSort = (field: string) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc';
    dispatch(slices.modules.setOrder(newOrder));
    dispatch(slices.modules.setOrderBy(field));
    dispatch(slices.modules.sort({ field: field, order: newOrder }));
  };

  const validate = () => {
    if (selectedModules.length) {
      next();
    } else {
      notification.warning('Debes seleccionar al menos un módulo');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedModules([...selectedModules, Number(event.target.name)]);
    } else {
      setSelectedModules(selectedModules.filter((item) => item !== Number(event.target.name)));
    }
  };

  return (
    <Modal
      title="Activación de módulos"
      close={close}
      actions={
        <ModalButtons
          isProcessing={false}
          primaryAction={validate}
          secondaryAction={close}
          primaryText={modules.length ? 'Continuar' : undefined}
          secondaryText="Cancelar"
        />
      }
      maxWidth="md"
    >
      {loading ? (
        <Skeleton variant="table" />
      ) : modules.length ? (
        <>
          <Information>Precio por usuario al mes</Information>
          <Content>
            <TableContainer>
              <MUITable size="small" stickyHeader sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <SortableColumn
                      name="name"
                      value="Nombre"
                      order={order}
                      orderBy={orderBy}
                      handleSort={handleSort}
                    />
                    <SortableColumn
                      name="description"
                      value="Descripción"
                      order={order}
                      orderBy={orderBy}
                      handleSort={handleSort}
                    />
                    <SortableColumn
                      name="price"
                      value="Precio"
                      order={order}
                      orderBy={orderBy}
                      handleSort={handleSort}
                      align="right"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modules.map((item) => (
                    <TableRow key={item.id} hover>
                      <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Checkbox
                          name={`${item.id}`}
                          checked={selectedModules.includes(item.id)}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="right">{item.price} USD</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MUITable>
            </TableContainer>
          </Content>
        </>
      ) : (
        <WithoutData
          icon={<ViewModuleIcon />}
          title="No hay módulos"
          description="En esta sección se mostrarán los módulos disponibles"
        />
      )}
    </Modal>
  );
};
