import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Indicators, IndicatorsState } from '@interfaces';

const initialState: IndicatorsState = {
  data: null,
  loading: false,
  loaded: false,
};

const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setData: (state, action: PayloadAction<Indicators | null>) => {
      state.data = action.payload;
      state.loaded = true;
    },
  },
});

const { setLoading, setData } = indicatorsSlice.actions;
const data = (state: RootState) => state.indicators.data;
const loading = (state: RootState) => state.indicators.loading;
const loaded = (state: RootState) => state.indicators.loaded;

export const indicators = {
  setLoading,
  setData,
  data,
  loading,
  loaded,
};

export default indicatorsSlice.reducer;
