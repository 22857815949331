import { WithoutData } from '@components';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Wrapper, Container } from './styled';

export const NotFound = () => {
  return (
    <Wrapper>
      <Container>
        <WithoutData
          icon={<LinkOffIcon />}
          title="Página no disponible"
          description="Estás intentando ingresar a una página que no existe"
        />
      </Container>
    </Wrapper>
  );
};
