import { useState } from 'react';
import { IUseFrontSettings, IFront, IEquipmentType } from '@interfaces';

export const useFrontSettings = () => {
  const [front, setFront] = useState<IFront | null>(null);
  const [timeToGo, setTimeToGo] = useState('');
  const [timeToLoad, setTimeToLoad] = useState('');
  const [timeToReturn, setTimeToReturn] = useState('');
  const [loadWeight, setLoadWeight] = useState('');
  const [equipmentType, setEquipmentType] = useState<IEquipmentType | null>(null);
  const [minimumCages, setMinimumCages] = useState('');
  const [maximumCages, setMaximumCages] = useState('');
  const [simultaneousLoads, setSimultaneousLoads] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: IFront) => {
    setFront(item ? item : null);
    setTimeToGo(item && item.settings.time_to_go ? item.settings.time_to_go.toString() : '');
    setTimeToLoad(item && item.settings.time_to_load ? item.settings.time_to_load.toString() : '');
    setTimeToReturn(
      item && item.settings.time_to_return ? item.settings.time_to_return.toString() : '',
    );
    setLoadWeight(item && item.settings.load_weight ? item.settings.load_weight.toString() : '');
    setEquipmentType(item ? item.equipment_type : null);
    setMinimumCages(
      item && item.settings.minimum_cages ? item.settings.minimum_cages.toString() : '',
    );
    setMaximumCages(
      item && item.settings.maximum_cages ? item.settings.maximum_cages.toString() : '',
    );
    setSimultaneousLoads(
      item && item.settings.simultaneous_loads ? item.settings.simultaneous_loads.toString() : '',
    );
  };

  const isValid = () => {
    if (
      timeToGo.trim() === '' ||
      timeToLoad.trim() === '' ||
      timeToReturn.trim() === '' ||
      loadWeight.trim() === '' ||
      equipmentType === null ||
      minimumCages.trim() === '' ||
      maximumCages.trim() === '' ||
      simultaneousLoads.trim() === ''
    ) {
      setTimeToGo(timeToGo.trim());
      setTimeToLoad(timeToLoad.trim());
      setTimeToReturn(timeToReturn.trim());
      setLoadWeight(loadWeight.trim());
      setMinimumCages(minimumCages.trim());
      setMaximumCages(maximumCages.trim());
      setSimultaneousLoads(simultaneousLoads.trim());
      return false;
    }
    return true;
  };

  const object = () => {
    return {
      front: front?.id,
      time_to_go: Number(timeToGo),
      time_to_load: Number(timeToLoad),
      time_to_return: Number(timeToReturn),
      load_weight: Number(loadWeight),
      equipment_type: equipmentType?.id,
      minimum_cages: Number(minimumCages),
      maximum_cages: Number(maximumCages),
      simultaneous_loads: Number(simultaneousLoads),
    };
  };

  const data: IUseFrontSettings = {
    front,
    timeToGo,
    timeToLoad,
    timeToReturn,
    loadWeight,
    equipmentType,
    minimumCages,
    maximumCages,
    simultaneousLoads,
    isUpdating,
    isProcessing,
    setTimeToGo,
    setTimeToLoad,
    setTimeToReturn,
    setLoadWeight,
    setEquipmentType,
    setMinimumCages,
    setMaximumCages,
    setSimultaneousLoads,
    setIsUpdating,
    setIsProcessing,
    initialize,
    isValid,
    object,
  };

  return data;
};
