import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ModalConfirm, TableOptions, AvatarWithInfo } from '@components';
import { ITruck, INotification, IUseTruck } from '@interfaces';
import { useAppDispatch } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { Form } from '../../form';
import { UpdateState } from './updateState';
import { helpers } from '@helpers';
import { hooks } from '@hooks';

interface IProps {
  items: ITruck[];
  data: IUseTruck;
  notification: INotification;
}

export const Body = ({ items, data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const dataState = hooks.useTruckState();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onUpdate = (value: ITruck) => {
    data.initialize(value);
    data.setIsUpdating(true);
  };

  const onUpdateState = (value: ITruck) => {
    dataState.initialize(value);
    dataState.setIsUpdating(true);
  };

  const onDelete = (value: ITruck) => {
    data.initialize(value);
    data.setIsDeleting(true);
  };

  const confirmUpdate = async () => {
    data.setIsProcessing(true);
    await services.truck
      .update(controller.signal, data.object())
      .then((response) => {
        dispatch(slices.trucks.updateItem(response.data));
        notification.success('Se actualizó el camión seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible actualizar el camión seleccionado');
      });
    data.setIsUpdating(false);
    data.setIsProcessing(false);
  };

  const confirmDelete = async () => {
    data.setIsProcessing(true);
    await services.truck
      .del(controller.signal, data.id)
      .then(() => {
        dispatch(slices.trucks.deleteItem(data.id));
        notification.success('Se eliminó el camión seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible eliminar el camión seleccionado');
      });
    data.setIsDeleting(false);
    data.setIsProcessing(false);
  };

  const cancel = () => {
    data.setIsUpdating(false);
    data.setIsDeleting(false);
    data.setIsProcessing(false);
  };

  const tableOptions = (item: ITruck) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Cambiar estado',
          onClick: onUpdateState,
        },
        {
          title: 'Actualizar',
          onClick: onUpdate,
        },
        {
          title: 'Eliminar',
          onClick: onDelete,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{item.code}</TableCell>
          <TableCell>{item.brand.name}</TableCell>
          <TableCell align="right">{item.model}</TableCell>
          <TableCell>
            <AvatarWithInfo
              title={item.state.name}
              description={helpers.getTimeFromNow(item.state_date)}
              titleColor={item.state.color}
              tooltipDescription={item.state_description}
            />
          </TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {data.isDeleting && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se eliminará el camión seleccionado"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmDelete}
          secondaryAction={cancel}
          isProcessing={data.isProcessing}
        />
      )}
      {data.isUpdating && (
        <Form data={data} save={confirmUpdate} cancel={cancel} notification={notification} />
      )}
      {dataState.isUpdating && <UpdateState data={dataState} notification={notification} />}
    </TableBody>
  );
};
