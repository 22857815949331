import { Indicators } from '@interfaces';
import { Wrapper, Title } from './styled';
import { ProgressIndicator } from '@components';

interface IProps {
  data: Indicators;
}

export const TimeToReturn = ({ data }: IProps) => {
  return (
    <Wrapper>
      <ProgressIndicator
        value={data.time_compliance.time_to_return}
        indicatorSize={80}
        titleSize={20}
        indicatorColor="primary"
      />
      <Title>Cumplimiento del tiempo de viaje cargado</Title>
    </Wrapper>
  );
};
