import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { BarChart } from '@mui/x-charts/BarChart';
import moment from 'moment';
import { Wrapper, Title } from './styled';
import { design } from '@constants';

interface ICanePerHour {
  label: string;
  value: number;
}

export const CaneEntryInTheNextFewHours = () => {
  const [canePerHour, setCanePerHour] = useState<ICanePerHour[]>([]);
  const trips = useAppSelector(slices.trips.items);

  useEffect(() => {
    const count: { [key: string]: number } = {};
    for (let i = 0; i < 5; i++) {
      count[moment().add(i, 'hours').format('HH:00')] = 0;
    }
    const tripsPendingArrival = trips.filter(
      (trip) =>
        trip.settings.front_departure_datetime !== '' && trip.settings.mill_arrival_datetime === '',
    );
    tripsPendingArrival.forEach((trip) => {
      const millArrivalDatetime = moment(trip.settings.front_departure_datetime);
      millArrivalDatetime.add(trip.settings.time_to_return, 'hours');
      millArrivalDatetime.add(trip.settings.mill_arrival_downtime, 'milliseconds');
      if (millArrivalDatetime.isBefore(moment())) {
        count[moment().format('HH:00')] += trip.settings.load_weight * trip.settings.cage_number;
      } else {
        if (millArrivalDatetime.format('HH:00') in count) {
          count[millArrivalDatetime.format('HH:00')] +=
            trip.settings.load_weight * trip.settings.cage_number;
        }
      }
    });
    const countingList = Object.keys(count).map((item) => ({ label: item, value: count[item] }));
    setCanePerHour(countingList);
  }, [trips]);

  return (
    <Wrapper>
      <BarChart
        series={[
          {
            data: canePerHour.map((item) => item.value),
            valueFormatter: (value) => `${value?.toLocaleString()} toneladas`,
          },
        ]}
        xAxis={[
          {
            data: canePerHour.map((item) => item.label),
            scaleType: 'band',
          },
        ]}
        colors={design.colors.pallette}
        grid={{ horizontal: true }}
        margin={{ left: 45, right: 0, top: 10, bottom: 20 }}
        borderRadius={10}
        height={200}
      />
      <Title>Ingreso de caña en las próximas horas</Title>
    </Wrapper>
  );
};
