import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IOrder, IUser, IUserList, IRole } from '@interfaces';
import { helpers } from '@helpers';

const searchFields = [
  'first_name',
  'email',
  'phone',
  'birthday',
  'state.name',
  'state_description',
];

const initialState: IUserList = {
  items: [],
  searchResults: [],
  searchCriteria: '',
  filteredRole: null,
  order: 'asc',
  orderBy: '',
  loaded: false,
  loading: false,
};

const authorizedUsersSlice = createSlice({
  name: 'authorizedUsers',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItem: (state, action: PayloadAction<IUser>) => {
      const newList = [...state.items, action.payload];
      const field = state.orderBy === '' ? 'first_name' : state.orderBy;
      const orderedList = helpers.orderList(newList, field, state.order);
      state.items = orderedList;
      let filteredResults = orderedList;
      if (state.filteredRole !== null) {
        filteredResults = filteredResults.filter((item) =>
          item.roles.includes(state.filteredRole?.internal_name),
        );
      }
      if (state.searchCriteria !== '') {
        filteredResults = helpers.search(state.searchCriteria, filteredResults, searchFields);
      }
      state.searchResults = filteredResults;
    },
    setItems: (state, action: PayloadAction<IUser[]>) => {
      state.items = action.payload;
      state.loaded = true;
    },
    updateItem: (state, action: PayloadAction<IUser>) => {
      const newList = state.items.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );
      const field = state.orderBy === '' ? 'first_name' : state.orderBy;
      const orderedList = helpers.orderList(newList, field, state.order);
      state.items = orderedList;
      let filteredResults = orderedList;
      if (state.filteredRole !== null) {
        filteredResults = filteredResults.filter((item) =>
          item.roles.includes(state.filteredRole?.internal_name),
        );
      }
      if (state.searchCriteria !== '') {
        filteredResults = helpers.search(state.searchCriteria, filteredResults, searchFields);
      }
      state.searchResults = filteredResults;
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((user: IUser) => user.id !== action.payload);
      if (state.searchCriteria !== '' || state.filteredRole !== null) {
        state.searchResults = state.searchResults.filter(
          (user: IUser) => user.id !== action.payload,
        );
      }
    },
    search: (state, action: PayloadAction<string>) => {
      state.searchCriteria = action.payload;
      const field = state.orderBy === '' ? 'first_name' : state.orderBy;
      let newList = helpers.orderList(state.items, field, state.order);
      if (state.filteredRole !== null) {
        newList = newList.filter((item) => item.roles.includes(state.filteredRole?.internal_name));
      }
      if (action.payload !== '') {
        newList = helpers.search(action.payload, newList, searchFields);
      }
      if (action.payload === '' && state.filteredRole === null) {
        state.items = newList;
      } else {
        state.searchResults = newList;
      }
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    sort: (state, action: PayloadAction<IOrder>) => {
      if (state.searchCriteria === '' && state.filteredRole === null) {
        state.items = helpers.orderList(state.items, action.payload.field, action.payload.order);
      } else {
        state.searchResults = helpers.orderList(
          state.searchResults,
          action.payload.field,
          action.payload.order,
        );
      }
    },
    filterByRole: (state, action: PayloadAction<IRole | null>) => {
      state.filteredRole = action.payload;
      const field = state.orderBy === '' ? 'first_name' : state.orderBy;
      let newList = helpers.orderList(state.items, field, state.order);
      if (action.payload !== null) {
        newList = newList.filter((item) => item.roles.includes(action.payload?.internal_name));
      }
      if (state.searchCriteria !== '') {
        newList = helpers.search(state.searchCriteria, newList, searchFields);
      }
      if (action.payload === null && state.searchCriteria === '') {
        state.items = newList;
      } else {
        state.searchResults = newList;
      }
    },
  },
});

const {
  setLoading,
  setItem,
  setItems,
  updateItem,
  deleteItem,
  search,
  setOrder,
  setOrderBy,
  sort,
  filterByRole,
} = authorizedUsersSlice.actions;
const items = (state: RootState) => state.authorizedUsers.items;
const searchResults = (state: RootState) => state.authorizedUsers.searchResults;
const searchCriteria = (state: RootState) => state.authorizedUsers.searchCriteria;
const filteredRole = (state: RootState) => state.authorizedUsers.filteredRole;
const order = (state: RootState) => state.authorizedUsers.order;
const orderBy = (state: RootState) => state.authorizedUsers.orderBy;
const loaded = (state: RootState) => state.authorizedUsers.loaded;
const loading = (state: RootState) => state.authorizedUsers.loading;

export const authorizedUsers = {
  setLoading,
  setItem,
  setItems,
  updateItem,
  deleteItem,
  search,
  setOrder,
  setOrderBy,
  sort,
  filterByRole,
  items,
  searchResults,
  searchCriteria,
  filteredRole,
  order,
  orderBy,
  loaded,
  loading,
};

export default authorizedUsersSlice.reducer;
