import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from '@mui/material/Tooltip';
import { AvatarWithInfo, Fieldset, Notification, ChangeAvatar } from '@components';
import { helpers } from '@helpers';
import { Wrapper, Banner, AvatarWrapper, Container } from './styled';
import moment from 'moment';
import { design } from '@constants';
import { hooks } from '@hooks';
import { Update } from './update';
import { Button } from '@mui/material';

export const PersonalInformation = () => {
  const notification = hooks.useNotification();
  const [isChangingAvatar, setIsChangingAvatar] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAvatar = () => {
    if (helpers.getPropertyInCurrentUser('avatar')) {
      return helpers.getAvatar(helpers.getPropertyInCurrentUser('avatar'));
    } else {
      return undefined;
    }
  };

  const cancelAction = () => {
    setIsChangingAvatar(false);
    setIsUpdating(false);
  };

  return (
    <Wrapper>
      <AvatarWrapper>
        <Banner />
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Tooltip title="Cambiar foto de perfil" arrow>
              <PhotoCameraIcon onClick={() => setIsChangingAvatar(true)} />
            </Tooltip>
          }
        >
          <Avatar sx={{ width: 100, height: 100 }} alt="Foto de perfil" src={getAvatar()}>
            <PersonIcon sx={{ fontSize: 90 }} />
          </Avatar>
        </Badge>
      </AvatarWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Container>
            <Fieldset title="General" active={true} size="medium">
              <AvatarWithInfo
                avatar={<PersonIcon />}
                title="Nombre"
                description={`${helpers.getPropertyInCurrentUser(
                  'first_name',
                )} ${helpers.getPropertyInCurrentUser('last_name')}`}
                titleColor={design.colors.darkGray}
                descriptionColor={design.colors.content}
                highlightDescription={true}
              />
              {helpers.getPropertyInCurrentUser('birthday') && (
                <AvatarWithInfo
                  avatar={<CakeIcon />}
                  title="Fecha de nacimiento"
                  description={moment(helpers.getPropertyInCurrentUser('birthday')).format(
                    'DD/MM/YYYY',
                  )}
                  titleColor={design.colors.darkGray}
                  descriptionColor={design.colors.content}
                  highlightDescription={true}
                />
              )}
            </Fieldset>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container>
            <Fieldset title="Contacto" active={true} size="medium">
              <AvatarWithInfo
                avatar={<EmailIcon />}
                title="Correo electrónico"
                description={helpers.getPropertyInCurrentUser('email')}
                highlightDescription={true}
              />
              {helpers.getPropertyInCurrentUser('phone') && (
                <AvatarWithInfo
                  avatar={<PhoneIcon />}
                  title="Teléfono"
                  description={helpers.getPropertyInCurrentUser('phone')}
                  highlightDescription={true}
                />
              )}
            </Fieldset>
          </Container>
        </Grid>
      </Grid>
      <Button onClick={() => setIsUpdating(true)} variant="outlined" size="large">
        Actualizar información
      </Button>
      {isChangingAvatar && <ChangeAvatar close={cancelAction} notification={notification} />}
      {isUpdating && <Update close={cancelAction} notification={notification} />}
      <Notification data={notification} />
    </Wrapper>
  );
};
