import { useEffect, useState } from 'react';
import { Modal, ModalButtons, AvatarWithInfo } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IEquipment, INotification, ITrip } from '@interfaces';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { OptionContainer } from './styled';
import { helpers } from '@helpers';

interface IProps {
  data: ITrip;
  close: () => void;
  notification: INotification;
}

export const NotifyDepartureFront = ({ data, close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [equipment, setEquipment] = useState<IEquipment | null>(null);
  const [cageNumber, setCageNumber] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const equipments = useAppSelector(slices.equipments.items);
  const loading = useAppSelector(slices.equipments.loading);
  const loaded = useAppSelector(slices.equipments.loaded);

  useEffect(() => {
    if (!loaded && !loading) getItems();
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.equipments.setLoading(true));
    await services.equipment
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.equipments.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los equipos');
      });
    dispatch(slices.equipments.setLoading(false));
  };

  const next = async () => {
    if (equipment && cageNumber.trim() !== '') {
      setIsProcessing(true);
      await services.trip
        .notifyDepartureFront(
          controller.signal,
          data.id,
          equipment.id,
          equipment.type.id,
          Number(cageNumber),
        )
        .then((response) => {
          dispatch(slices.trip.setItem(response.data));
          dispatch(slices.trips.updateItem(response.data));
          notification.success('Hemos notificado la salida del frente');
          close();
        })
        .catch((error) => {
          switch (error.response.data.detail) {
            case 'This equipment is already assigned':
              notification.warning('Este equipo ya está asignado');
              break;
            default:
              notification.warning('No fue posible notificar la salida del frente');
              break;
          }
        });
      setIsProcessing(false);
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Notificar la salida del frente" close={close} actions={buttons} maxWidth="xs">
      <Autocomplete
        value={equipment}
        onChange={(_event: any, newValue: IEquipment | null) => setEquipment(newValue)}
        options={equipments}
        getOptionLabel={(option) => `${option.code} ${option.type.name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Equipo"
            size="small"
            autoFocus
            sx={{ marginTop: '10px', marginBottom: '20px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo title={option.code} description={option.type.name} />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
      <TextField
        label="Cantidad de jaulas"
        value={cageNumber}
        onChange={(event) =>
          setCageNumber(helpers.validateNumber(cageNumber, event.target.value, false))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
    </Modal>
  );
};
