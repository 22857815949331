import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import {
  Title,
  Description,
  TooltipWrapper,
  TooltipDatetime,
  TooltipTitle,
  TooltipDescription,
} from './styled';
import { design } from '@constants';
import { ReactNode } from 'react';

interface IProps {
  avatar?: string | ReactNode;
  title: string;
  description?: string;
  tooltipDatetime?: string;
  tooltipTitle?: string;
  tooltipDescription?: string;
  titleColor?: string;
  descriptionColor?: string;
  highlightDescription?: boolean;
  tooltipIconColor?: string;
}

export const AvatarWithInfo = ({
  avatar,
  title,
  description = '',
  tooltipDatetime = '',
  tooltipTitle = '',
  tooltipDescription = '',
  titleColor = 'initial',
  descriptionColor = design.colors.darkGray,
  highlightDescription = false,
  tooltipIconColor = design.colors.darkGray,
}: IProps) => {
  return (
    <Grid container spacing={1} alignItems="center">
      {avatar !== undefined && (
        <Grid item xs="auto">
          <Avatar
            alt="Imagen"
            src={typeof avatar === 'string' ? avatar : undefined}
            sx={{ width: 32, height: 32 }}
          >
            {typeof avatar === 'string' ? <PersonIcon /> : avatar}
          </Avatar>
        </Grid>
      )}
      <Grid item>
        <Title
          color={titleColor}
          highlightDescription={highlightDescription}
          tooltipColor={tooltipIconColor}
        >
          {title}
          {(tooltipDatetime || tooltipTitle || tooltipDescription) && (
            <Tooltip
              title={
                <TooltipWrapper>
                  {tooltipDatetime && (
                    <TooltipDatetime addPadding={tooltipTitle || tooltipDescription}>
                      {tooltipDatetime}
                    </TooltipDatetime>
                  )}
                  {tooltipTitle && <TooltipTitle>{tooltipTitle}</TooltipTitle>}
                  {tooltipDescription && (
                    <TooltipDescription addPadding={tooltipDatetime || tooltipTitle}>
                      {tooltipDescription}
                    </TooltipDescription>
                  )}
                </TooltipWrapper>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          )}
        </Title>
        <Description color={descriptionColor} highlightDescription={highlightDescription}>
          {description}
        </Description>
      </Grid>
    </Grid>
  );
};
