import { useEffect, useState } from 'react';
import { Modal, ModalButtons, AvatarWithInfo } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IFront, INotification, ITrip } from '@interfaces';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { OptionContainer } from './styled';

interface IProps {
  data: ITrip;
  close: () => void;
  notification: INotification;
}

export const ChangeFront = ({ data, close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [front, setFront] = useState<IFront | null>(data.front);
  const [isProcessing, setIsProcessing] = useState(false);
  const fronts = useAppSelector(slices.fronts.items);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    if (front) {
      setIsProcessing(true);
      await services.trip
        .changeFront(controller.signal, data.id, front.id)
        .then((response) => {
          dispatch(slices.trips.updateItem(response.data));
          notification.success('Hemos cambiado el frente del viaje seleccionado');
          close();
        })
        .catch(() => {
          notification.warning('No fue posible cambiar el frente del viaje seleccionado');
        });
      setIsProcessing(false);
    } else {
      notification.warning('Debes seleccionar un frente');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Cambiar frente" close={close} actions={buttons} maxWidth="xs">
      <Autocomplete
        value={front}
        onChange={(_event: any, newValue: IFront | null) => setFront(newValue)}
        options={fronts.filter((front) => {
          if (front.equipment_type.id !== data.equipment_type.id) return false;
          if (front.settings.minimum_cages > data.settings.cage_number) return false;
          if (front.settings.maximum_cages < data.settings.cage_number) return false;
          return true;
        })}
        getOptionLabel={(option) =>
          `Frente ${option.identifier} ubicado en finca ${option.farm.name}`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Frente"
            size="small"
            autoFocus
            sx={{ marginTop: '10px', marginBottom: '20px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              title={`Frente ${option.identifier}`}
              description={`Ubicado en finca ${option.farm.name}`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};
