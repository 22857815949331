import { useEffect } from 'react';
import { Header, Option, Title } from '../styled';
import { Notification } from '@components';
import Tooltip from '@mui/material/Tooltip';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { hooks } from '@hooks';
import { services } from '@services';
import { slices } from '@slices';
import * as Icons from '@mui/icons-material';

export const Links = () => {
  const dispatch = useAppDispatch();
  const screenWidth = hooks.useScreenWidth();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const openMenu = useAppSelector(slices.settings.openMenu);
  const items = useAppSelector(slices.links.items);
  const loading = useAppSelector(slices.links.loading);
  const loaded = useAppSelector(slices.links.loaded);

  useEffect(() => {
    if (!loaded && !loading) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.links.setLoading(true));
    await services.link
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.links.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los enlaces');
      });
    dispatch(slices.links.setLoading(false));
  };

  const getIcon = (name: string) => {
    const Icon = Icons[name as keyof typeof Icons];
    return <Icon />;
  };

  const openLink = (url: string) => {
    if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
    window.open(url, '_blank');
  };

  return (
    <>
      {!loading && items.length > 0 && (
        <>
          {openMenu && <Header paddingtop={20}>Enlaces</Header>}
          {items.map((item, index) => (
            <Tooltip key={index} title={openMenu ? '' : item.name} placement="right" arrow>
              <Option onClick={() => openLink(item.url)}>
                {getIcon(item.icon)}
                {openMenu && <Title>{item.name}</Title>}
              </Option>
            </Tooltip>
          ))}
          <Notification data={notification} />
        </>
      )}
    </>
  );
};
