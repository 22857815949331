import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { Wrapper, Container, FilterWrapper, Title } from './styled';
import { AuthorizedUsers } from './authorizedUsers';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Notification, Searcher } from '@components';
import { services } from '@services';
import { hooks } from '@hooks';
import { IRole } from '@interfaces';
import { helpers } from '@helpers';

export const ActiveUsers = () => {
  const dispatch = useAppDispatch();
  const notification = hooks.useNotification();
  const controller = new AbortController();
  const screenWidth = hooks.useScreenWidth();
  const [activeRoles, setActiveRoles] = useState<IRole[]>([]);
  const modules = useAppSelector(slices.modules.items);
  const roles = useAppSelector(slices.roles.items);
  const rolesLoading = useAppSelector(slices.roles.loading);
  const rolesLoaded = useAppSelector(slices.roles.loaded);
  const users = useAppSelector(slices.authorizedUsers.items);
  const searchResults = useAppSelector(slices.authorizedUsers.searchResults);
  const searchCriteria = useAppSelector(slices.authorizedUsers.searchCriteria);
  const filteredRole = useAppSelector(slices.authorizedUsers.filteredRole);

  useEffect(() => {
    if (!rolesLoaded && !rolesLoading) {
      getRoles();
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setActiveRoles(helpers.getActiveRoles(roles, modules));
  }, [roles, modules]);

  const getRoles = async () => {
    dispatch(slices.roles.setLoading(true));
    await services.role
      .getAllRoles(controller.signal)
      .then((response) => {
        dispatch(slices.roles.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los roles existentes');
      });
    dispatch(slices.roles.setLoading(false));
  };

  const onSearch = (value: string) => {
    dispatch(slices.authorizedUsers.search(value));
  };

  const getTotalItems = () => {
    let totalItems = 0;
    if (searchCriteria === '' && filteredRole === null) {
      totalItems = users.length;
    } else {
      totalItems = searchResults.length;
    }
    return (
      <Chip
        label={`${totalItems} ${totalItems === 1 ? 'usuario encontrado' : 'usuarios encontrados'}`}
        size="small"
      />
    );
  };

  return (
    <Wrapper>
      <FilterWrapper style={screenWidth < 900 ? { width: 130 } : {}}>
        <Autocomplete
          value={filteredRole}
          onChange={(_event: any, newValue: IRole | null) =>
            dispatch(slices.authorizedUsers.filterByRole(newValue))
          }
          options={activeRoles}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} size="small" placeholder="Filtrar por rol" />
          )}
          noOptionsText="No hay opciones"
          disabled={rolesLoading}
        />
      </FilterWrapper>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={users.length === 0} />
      <Title>Usuarios activos</Title>
      {getTotalItems()}
      <Container>
        <AuthorizedUsers />
      </Container>
      <Notification data={notification} />
    </Wrapper>
  );
};
