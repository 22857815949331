import { Snackbar, Alert, AlertColor } from '@mui/material';
import { INotification } from '@interfaces';

interface IProps {
  data: INotification;
}

export const Notification = ({ data }: IProps) => {
  return (
    <Snackbar
      open={data.show}
      autoHideDuration={6000}
      onClose={data.hide}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={data.hide} variant="filled" severity={data.severity as AlertColor}>
        {data.message}
      </Alert>
    </Snackbar>
  );
};
