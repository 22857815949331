import styled, { css } from 'styled-components';
import Grid from '@mui/material/Grid';

export const Wrapper = styled.div`
  padding: 20px 0 20px 24px;
  .MuiGrid-item {
    position: relative;
  }
`;

export const MenuWrapper = styled(Grid)`
  width: 100%;
  ${(props: any) =>
    props.openmenu === 'true'
      ? css`
          @media (max-width: 600px) {
            padding-left: 8px !important;
            padding-right: 16px !important;
          }
          @media (max-width: 900px) {
            padding-right: 24px;
          }
          @media (min-width: 900px) {
            max-width: 250px;
          }
        `
      : css`
          @media (max-width: 900px) {
            display: none;
          }
          @media (min-width: 900px) {
            max-width: 92px;
          }
        `}
`;

export const Page = styled(Grid)`
  width: 100%;
  ${(props: any) =>
    props.openmenu === 'true'
      ? css`
          @media (max-width: 900px) {
            display: none;
          }
          @media (min-width: 900px) {
            max-width: calc(100% - 250px);
          }
        `
      : css`
          @media (max-width: 600px) {
            padding-left: 0px !important;
          }
          @media (min-width: 600px) {
            padding-left: 8px !important;
            padding-right: 0px;
          }
          @media (min-width: 900px) {
            padding-left: 16px !important;
            max-width: calc(100% - 92px);
          }
        `}
`;

export const Container = styled.div`
  ${(props: any) =>
    css`
      height: calc(${props.height}px - 104px);
    `}
  overflow: visible;
  @media (max-width: 600px) {
    ${(props: any) =>
      css`
        height: calc(${props.height}px - 98px);
      `}
  }
`;
