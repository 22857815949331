import { IMIndicators } from '@interfaces';
import { Wrapper, Title, Value } from './styled';
import { helpers } from '@helpers';

interface IProps {
  data: IMIndicators;
}

const InvestmentInExistingProducts = ({ data }: IProps) => {
  return (
    <Wrapper>
      <Value>
        {helpers.getFormattedNumber(data.investment_in_existing_products)} <span>GTQ</span>
      </Value>
      <Title>Inversión en productos existentes</Title>
    </Wrapper>
  );
};

export default InvestmentInExistingProducts;
