import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ModalConfirm, TableOptions } from '@components';
import { IState, INotification, IUseState } from '@interfaces';
import { useAppDispatch } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { Form } from '../../form';
import { ColorIndicator } from './styled';

interface IProps {
  items: IState[];
  data: IUseState;
  notification: INotification;
}

export const Body = ({ items, data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onUpdate = (value: IState) => {
    data.initialize(value);
    data.setIsUpdating(true);
  };

  const onDelete = (value: IState) => {
    data.initialize(value);
    data.setIsDeleting(true);
  };

  const confirmUpdate = async () => {
    data.setIsProcessing(true);
    await services.state
      .update(controller.signal, data.object())
      .then((response) => {
        dispatch(slices.states.updateItem(response.data));
        notification.success('Se actualizó el estado seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible actualizar el estado seleccionado');
      });
    data.setIsUpdating(false);
    data.setIsProcessing(false);
  };

  const confirmDelete = async () => {
    data.setIsProcessing(true);
    await services.state
      .del(controller.signal, data.id)
      .then(() => {
        dispatch(slices.states.deleteItem(data.id));
        notification.success('Se eliminó el estado seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible eliminar el estado seleccionado');
      });
    data.setIsDeleting(false);
    data.setIsProcessing(false);
  };

  const cancel = () => {
    data.setIsUpdating(false);
    data.setIsDeleting(false);
    data.setIsProcessing(false);
  };

  const tableOptions = (item: IState) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Actualizar',
          onClick: item.is_default ? undefined : onUpdate,
        },
        {
          title: 'Eliminar',
          onClick: item.is_default ? undefined : onDelete,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.description}</TableCell>
          <TableCell>
            <ColorIndicator color={item.color} />
          </TableCell>
          <TableCell>{item.scope.name}</TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {data.isDeleting && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se eliminará el estado seleccionado"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmDelete}
          secondaryAction={cancel}
          isProcessing={data.isProcessing}
        />
      )}
      {data.isUpdating && (
        <Form data={data} save={confirmUpdate} cancel={cancel} notification={notification} />
      )}
    </TableBody>
  );
};
