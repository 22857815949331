import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  background-color: ${design.colors.ultralightGray};
  padding: 15px 10px;
  text-align: center;
`;

export const EnterpriseName = styled.div`
  opacity: 0.7;
  margin-bottom: 10px;
`;

export const CopyEnterpriseToken = styled.span`
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  color: ${design.colors.primary};
  background-color: ${design.colors.lightPrimary};
  transition: 0.2s;
  :hover {
    color: white;
    background-color: ${design.colors.primary};
  }
`;
