import styled, { css } from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  display: flex;
  ${(props: any) =>
    css`
      height: ${props.height}px;
    `}
  @media (max-width: 550px) {
    background-color: white;
  }
`;

export const Container = styled.div`
  box-shadow: ${design.shadows.default};
  background-color: white;
  width: 100%;
  max-width: 400px;
  padding: 40px;
  border-radius: 10px;
  margin: auto;
  @media (max-width: 550px) {
    max-width: unset;
    padding: 20px;
    box-shadow: unset;
  }
`;

export const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

export const Logo = styled.img`
  width: 150px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
  opacity: 0.7;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  opacity: 0.5;
  margin-bottom: 25px;
`;

export const LoginContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const LoginQuestion = styled.span`
  opacity: 0.5;
  font-size: 12px;
`;

export const LoginLink = styled.span`
  margin-left: 5px;
  color: ${design.colors.primary};
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.8;
  }
`;
