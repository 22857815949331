import styled, { css } from 'styled-components';
import { design } from '@constants';

interface IActionProps {
  isinput: string;
}

export const Action = styled.div`
  border-radius: 50px;
  padding: 5px;
  text-align: center;
  ${(props: IActionProps) =>
    props.isinput === 'true'
      ? css`
          color: ${design.colors.green};
          background-color: ${design.colors.lightGreen};
        `
      : css`
          color: ${design.colors.red};
          background-color: ${design.colors.lightRed};
        `}
`;

export const SeeMore = styled.div`
  text-align: center;
  button {
    width: 200px;
    margin-top: 20px;
    border-radius: 50px;
    color: ${design.colors.darkGray};
    background-color: ${design.colors.lightGray};
    :hover {
      color: ${design.colors.primary};
      background-color: ${design.colors.lightPrimary};
    }
  }
`;

export const Price = styled.div`
  > div {
    display: block;
  }
`;
