import { Wrapper, Title } from './styled';
import { Searcher } from '@components';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

export const Header = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(slices.equipmentsDownload.items);
  const searchCriteria = useAppSelector(slices.equipmentsDownload.searchCriteria);

  const onSearch = (value: string) => {
    dispatch(slices.equipmentsDownload.search(value));
  };

  return (
    <Wrapper>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={items.length === 0} />
      <Title>Descarga</Title>
    </Wrapper>
  );
};
