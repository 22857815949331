import { Modal, ModalButtons } from '@components';
import { Content } from './styled';

interface IProps {
  title: string;
  content: string;
  primaryAction: () => void;
  secondaryAction: () => void;
  primaryText: string;
  secondaryText: string;
  isProcessing: boolean;
}

export const ModalConfirm = ({
  title,
  content,
  primaryAction,
  secondaryAction,
  primaryText,
  secondaryText,
  isProcessing,
}: IProps) => {
  return (
    <Modal
      title={title}
      close={secondaryAction}
      actions={
        <ModalButtons
          isProcessing={isProcessing}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      }
      maxWidth="xs"
    >
      <Content>{content}</Content>
    </Modal>
  );
};
