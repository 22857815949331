import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  > div > div + div > div {
    display: block;
    text-align: right;
  }
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px;
  margin-bottom: 19px;
  color: ${design.colors.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.02857em;
`;
