import styled from 'styled-components';
import { design } from '@constants';

export const Information = styled.div`
  background-color: ${design.colors.lightPrimary};
  color: ${design.colors.primary};
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0 10px;
  font-size: 12px;
  text-align: left;
  > div {
    font-size: 12px;
  }
  button {
    float: right;
  }
`;
