import { axiosInstance } from './axios';
import { api } from '@constants';

const available = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.modules}/available`, { signal: signal });
};

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.modules, { signal: signal });
};

const activate = (signal: AbortSignal, modules: number[]) => {
  return axiosInstance.post(`${api.modules}/activate`, { modules }, { signal: signal });
};

const inactivate = (signal: AbortSignal, id: number) => {
  return axiosInstance.put(`${api.modules}/inactivate/${id}`, { signal: signal });
};

export const module = {
  available,
  all,
  activate,
  inactivate,
};
