import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { design } from '@constants';

export const Wrapper = styled(Grid)`
  border: solid 1px ${design.colors.mediumGray};
  padding: 10px 15px;
  padding-bottom: 9px;
  border-radius: 0px 0px 10px 10px;
`;

export const Option = styled.div`
  padding: 0 10px;
`;

export const Title = styled.div`
  color: ${design.colors.darkGray};
`;
