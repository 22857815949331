import { useEffect } from 'react';
import { Modal, ModalButtons, Skeleton, AvatarWithInfo } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { INotification, IUseFront, IFarm, IUser } from '@interfaces';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { settings } from '@constants';
import { helpers } from '@helpers';
import { OptionContainer } from './styled';

interface IProps {
  data: IUseFront;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const users = useAppSelector(slices.authorizedUsers.items);
  const loadingUsers = useAppSelector(slices.authorizedUsers.loading);
  const loadedUsers = useAppSelector(slices.authorizedUsers.loaded);
  const farms = useAppSelector(slices.farms.items);
  const loadingFarms = useAppSelector(slices.farms.loading);
  const loadedFarms = useAppSelector(slices.farms.loaded);

  useEffect(() => {
    if (!loadedUsers && !loadingUsers) {
      getUsers();
    }
    if (!loadedFarms && !loadingFarms) {
      getFarms();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getUsers = async () => {
    dispatch(slices.authorizedUsers.setLoading(true));
    await services.user
      .authorized(controller.signal)
      .then((response) => {
        dispatch(slices.authorizedUsers.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar la lista de jefes de frente');
      });
    dispatch(slices.authorizedUsers.setLoading(false));
  };

  const getFarms = async () => {
    dispatch(slices.farms.setLoading(true));
    await services.farm
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.farms.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar las fincas');
      });
    dispatch(slices.farms.setLoading(false));
  };

  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe un frente con este identificador');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} frente`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Identificador"
        value={data.identifier}
        onChange={(event) => data.setIdentifier(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      {loadingUsers ? (
        <Skeleton variant="input" />
      ) : (
        <Autocomplete
          value={data.boss}
          onChange={(_event: any, newValue: IUser | null) => data.setBoss(newValue)}
          options={users.filter((item) => item.roles.includes(settings.roles.frontBoss))}
          getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Encargado" size="small" sx={{ marginBottom: '20px' }} />
          )}
          renderOption={(props, option) => (
            <OptionContainer {...props}>
              <AvatarWithInfo
                avatar={helpers.getAvatar(option.avatar)}
                title={`${option.first_name} ${option.last_name}`}
                description={option.phone}
              />
            </OptionContainer>
          )}
          noOptionsText="No hay opciones"
        />
      )}
      {loadingFarms ? (
        <Skeleton variant="input" />
      ) : (
        <Autocomplete
          value={data.farm}
          onChange={(_event: any, newValue: IFarm | null) => data.setFarm(newValue)}
          options={farms}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Finca" size="small" sx={{ marginBottom: '20px' }} />
          )}
          renderOption={(props, option) => (
            <OptionContainer {...props}>
              <AvatarWithInfo title={option.name} description={`${option.distance} km`} />
            </OptionContainer>
          )}
          noOptionsText="No hay opciones"
        />
      )}
      <TextField
        label="Cuota"
        value={data.quota}
        onChange={(event) =>
          data.setQuota(helpers.validateNumber(data.quota, event.target.value, false))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
        InputProps={{
          endAdornment: <InputAdornment position="end">t</InputAdornment>,
        }}
      />
    </Modal>
  );
};
