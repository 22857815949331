import { Wrapper } from './styled';
import { Header } from './header';
import { Table } from './table';

export const EquipmentsDownload = () => {
  return (
    <Wrapper>
      <Header />
      <Table />
    </Wrapper>
  );
};
