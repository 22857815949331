import { useState } from 'react';
import { IUseLink, ILink } from '@interfaces';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

export const useLink = () => {
  const items = useAppSelector(slices.links.items);
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [icon, setIcon] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: ILink) => {
    setId(item ? item.id : 0);
    setName(item ? item.name : '');
    setUrl(item ? item.url : '');
    setIcon(item ? item.icon : '');
  };

  const isValid = () => {
    if (name.trim() === '' || url.trim() === '' || icon === '') {
      setName(name.trim());
      setUrl(url.trim());
      return false;
    }
    return true;
  };

  const isDuplicated = () =>
    items.filter(
      (item) =>
        item.id !== id &&
        (item.name.toLowerCase() === name.trim().toLowerCase() ||
          item.url.toLowerCase() === url.trim().toLowerCase() ||
          item.icon === icon),
    ).length > 0;

  const object = () => {
    return {
      id: id,
      name: name.trim(),
      url: url.trim(),
      icon: icon,
    };
  };

  const data: IUseLink = {
    id,
    name,
    url,
    icon,
    isCreating,
    isUpdating,
    isDeleting,
    isProcessing,
    setName,
    setUrl,
    setIcon,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsProcessing,
    initialize,
    isValid,
    isDuplicated,
    object,
  };

  return data;
};
