import { design } from '@constants';
import styled, { css } from 'styled-components';

export const IconsWrapper = styled.div`
  > div > div + div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    padding: 20px 15px 15px;
  }
`;

export const IconWrapper = styled.span`
  svg {
    padding: 6px;
    cursor: pointer;
    border-radius: 50%;
    transition: 200ms;
    :hover {
      background-color: ${design.colors.lightGray};
    }
    ${(props: any) =>
      props.active &&
      css`
        color: ${design.colors.primary};
        background-color: ${design.colors.lightPrimary} !important;
      `}
  }
`;
