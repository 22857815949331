import { ReactNode } from 'react';
import { Wrapper, Title, Content } from './styled';

interface IProps {
  title: string;
  children: ReactNode;
  active?: boolean;
  hover?: boolean;
  size?: 'small' | 'medium';
  margin?: string;
  padding?: string;
}

export const Fieldset = ({
  title,
  children,
  active = false,
  hover = false,
  size = 'small',
  margin = '0',
  padding = `${size === 'small' ? '25px 15px 20px' : '25px 20px 20px'}`,
}: IProps) => {
  return (
    <Wrapper margin={margin}>
      <Title active={active} size={size}>
        {title}
      </Title>
      <Content padding={padding} hover={hover}>
        {children}
      </Content>
    </Wrapper>
  );
};
