import styled, { css } from 'styled-components';

export const ColorIndicator = styled.div`
  position: relative;
  padding: 0 14px;
  > div {
    position: absolute;
    height: 18px;
    width: calc(100% - 28px);
    border-radius: 4px;
    margin-top: 10px;
    pointer-events: none;
    ${(props: any) => css`
      background-color: ${props.color};
    `}
  }
  + div {
    input {
      cursor: pointer;
      ${(props: any) => css`
        color: ${props.color};
      `}
    }
  }
`;

export const Container = styled.div`
  padding: 0 0 10px;
  .chrome-picker {
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    border: solid 1px rgba(32, 33, 36, 0.2);
    > div {
      border-radius: 4px 4px 0px 0px;
    }
  }
`;
