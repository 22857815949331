import { useEffect, useState } from 'react';
import { helpers } from '@helpers';
import { Wrapper, Container, CodeWrapper } from './styled';
import { Fieldset, AvatarWithInfo, Notification } from '@components';
import BusinessIcon from '@mui/icons-material/Business';
import TokenIcon from '@mui/icons-material/Token';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Leave } from './leave';
import { hooks } from '@hooks';

export const CurrentEnterprise = () => {
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const [isLeaving, setIsLeaving] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const copyEnterpriseToken = () => {
    helpers.copyToClipboard(helpers.getPropertyInCurrentUser('enterprise')['token']);
    notification.success('El código de empresa ha sido copiado');
  };

  const cancelAction = () => {
    setIsLeaving(false);
  };

  return (
    <Wrapper>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Fieldset title="General" active={true} size="medium">
              <AvatarWithInfo
                title="Nombre"
                description={helpers.getPropertyInCurrentUser('enterprise')['name']}
                highlightDescription={true}
                avatar={<BusinessIcon />}
              />
            </Fieldset>
          </Grid>
          <Grid item xs={12} md={6}>
            <Fieldset title="Seguridad" active={true} size="medium">
              <CodeWrapper>
                <AvatarWithInfo
                  title="Código"
                  description={helpers.getPropertyInCurrentUser('enterprise')['token']}
                  highlightDescription={true}
                  avatar={<TokenIcon />}
                />
                <Tooltip title="Copiar código de empresa" arrow>
                  <IconButton onClick={copyEnterpriseToken} size="small">
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </CodeWrapper>
            </Fieldset>
          </Grid>
        </Grid>
      </Container>
      <Button onClick={() => setIsLeaving(true)} variant="outlined" size="large" color="error">
        Abandonar empresa
      </Button>
      {isLeaving && <Leave close={cancelAction} notification={notification} />}
      <Notification data={notification} />
    </Wrapper>
  );
};
