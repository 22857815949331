import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  padding-left: 5px;
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  padding-top: 14px;
  padding-left: 5px;
  color: ${design.colors.primary};
  margin-bottom: 15px;
  letter-spacing: 0.02857em;
`;
