import { useEffect } from 'react';
import { Wrapper, Title } from './styled';
import { slices } from '@slices';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { Detail } from './detail';

export const CurrentState = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const currentFront = useAppSelector(slices.front.item);
  const loaded = useAppSelector(slices.front.loaded);
  const loading = useAppSelector(slices.front.loading);

  useEffect(() => {
    if (!loaded && !loading) {
      getCurrentFront();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getCurrentFront = async () => {
    dispatch(slices.front.setLoading(true));
    await services.front
      .detail(controller.signal)
      .then((response) => {
        dispatch(slices.front.setItem(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar el detalle del frente');
      });
    dispatch(slices.front.setLoading(false));
  };

  return (
    <Wrapper>
      <Title>Frente {currentFront?.identifier}</Title>
      <Detail data={currentFront} loading={loading} />
      <Notification data={notification} />
    </Wrapper>
  );
};
