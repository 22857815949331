import { Wrapper } from './styled';
import { Header } from './header';
import { Table } from './table';
import { hooks } from '@hooks';

export const Equipments = () => {
  const data = hooks.useEquipment();

  return (
    <Wrapper>
      <Header data={data} />
      <Table data={data} />
    </Wrapper>
  );
};
