import { Modal, ModalButtons, ColorPicker } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { INotification, IUseState, IScope } from '@interfaces';
import { settings } from '@constants';

interface IProps {
  data: IUseState;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe un estado con este nombre');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} estado`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Nombre"
        value={data.name}
        onChange={(event) => data.setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <TextField
        label="Descripción"
        value={data.description}
        onChange={(event) => data.setDescription(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <ColorPicker color={data.color} setColor={data.setColor} />
      <Autocomplete
        value={data.scope}
        onChange={(_event: any, newValue: IScope | null) => data.setScope(newValue)}
        options={settings.scopes}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="Alcance" size="small" sx={{ marginBottom: '10px' }} />
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};
