import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 80px;
  @media (max-width: 600px) {
    button {
      width: 100%;
    }
  }
`;
