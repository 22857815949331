import { useState } from 'react';
import { Wrapper } from './styled';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContactsIcon from '@mui/icons-material/Contacts';
import { Menu, Notification } from '@components';
import { ContactList } from './contactList';
import { hooks } from '@hooks';

export const Contacts = () => {
  const notification = hooks.useNotification();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Tooltip title="Contactos" arrow>
        <IconButton onClick={handleClick} size="large">
          <ContactsIcon sx={{ fontSize: 27 }} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} handleClose={handleClose}>
        <ContactList />
      </Menu>
      <Notification data={notification} />
    </Wrapper>
  );
};
