import MUISkeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { Wrapper, TableSkeleton, ListSkeleton, InputSkeleton, IndicatorWrapper } from './styled';

interface IProps {
  variant: 'table' | 'list' | 'input' | 'currentTrip' | 'frontDetail' | 'indicators';
}

export const Skeleton = ({ variant }: IProps) => {
  return (
    <Wrapper>
      {variant === 'table' && (
        <TableSkeleton>
          <MUISkeleton animation="wave" height={70} />
          <MUISkeleton animation="wave" height={70} />
          <MUISkeleton animation="wave" height={70} />
        </TableSkeleton>
      )}
      {variant === 'list' && (
        <ListSkeleton>
          <MUISkeleton animation="wave" height={60} />
          <MUISkeleton animation="wave" height={60} />
          <MUISkeleton animation="wave" height={60} />
        </ListSkeleton>
      )}
      {variant === 'input' && (
        <InputSkeleton>
          <MUISkeleton animation="wave" height={65} />
        </InputSkeleton>
      )}
      {variant === 'currentTrip' && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={250}
                  width={250}
                  style={{ display: 'inline-block', marginTop: -55, marginBottom: -35 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width={150}
                  style={{ display: 'inline-block', marginBottom: -6 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
        </Grid>
      )}
      {variant === 'frontDetail' && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={250}
                  width="60%"
                  style={{ display: 'inline-block', marginTop: -50, marginBottom: -35 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="45%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={250}
                  width="60%"
                  style={{ display: 'inline-block', marginTop: -50, marginBottom: -35 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="45%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <IndicatorWrapper>
              <div>
                <MUISkeleton
                  animation="wave"
                  height={50}
                  width="50%"
                  style={{ display: 'inline-block', marginTop: -11 }}
                />
              </div>
              <div>
                <MUISkeleton
                  animation="wave"
                  width="90%"
                  style={{ display: 'inline-block', marginBottom: -5 }}
                />
              </div>
            </IndicatorWrapper>
          </Grid>
        </Grid>
      )}
      {variant === 'indicators' && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={250}
                style={{ marginTop: -54, marginBottom: -45 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={250}
                style={{ marginTop: -54, marginBottom: -45 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={250}
                style={{ marginTop: -54, marginBottom: -45 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={250}
                style={{ marginTop: -54, marginBottom: -45 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <IndicatorWrapper>
              <MUISkeleton
                animation="wave"
                height={450}
                style={{ marginTop: -99, marginBottom: -81 }}
              />
            </IndicatorWrapper>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};
