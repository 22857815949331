import { useState } from 'react';
import { IUseAssignTrip, IFront, ITrip } from '@interfaces';

export const useAssignTrip = () => {
  const [trip, setTrip] = useState<ITrip | null>(null);
  const [front, setFront] = useState<IFront | null>(null);
  const [automaticAssignment, setAutomaticAssignment] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const isValid = () => {
    if (front === null) return false;
    return true;
  };

  const object = () => {
    return {
      trip_id: trip?.id,
      front_id: front?.id,
      automatic_assignment: automaticAssignment,
    };
  };

  const data: IUseAssignTrip = {
    trip,
    front,
    automaticAssignment,
    showDetail,
    isAssigning,
    isDeleting,
    isProcessing,
    setTrip,
    setFront,
    setAutomaticAssignment,
    setShowDetail,
    setIsAssigning,
    setIsDeleting,
    setIsProcessing,
    isValid,
    object,
  };

  return data;
};
