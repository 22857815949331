import { useState } from 'react';
import { INotification } from '@interfaces';
import { Wrapper } from './styled';
import { WithoutData } from '@components';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { hooks } from '@hooks';
import { RequestNewTrip } from './requestNewTrip';
import { AssignEquipment } from './assignEquipment';

interface IProps {
  notification: INotification;
}

export const RequestTrip = ({ notification }: IProps) => {
  const data = hooks.useTrip();
  const [isAssigning, setIsAssigning] = useState(false);

  return (
    <Wrapper>
      <WithoutData
        icon={<LocalShippingIcon />}
        title="Aún no tienes asignación"
        description="Puedes asignarte un equipo para descarga o solicitar un nuevo viaje"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} textAlign="right">
          <Button onClick={() => setIsAssigning(true)} variant="outlined" size="large">
            Asignar equipo
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="left">
          <Button onClick={() => data.setIsRequesting(true)} variant="contained" size="large">
            Solicitar viaje
          </Button>
        </Grid>
      </Grid>
      {isAssigning && (
        <AssignEquipment close={() => setIsAssigning(false)} notification={notification} />
      )}
      {data.isRequesting && <RequestNewTrip data={data} notification={notification} />}
    </Wrapper>
  );
};
