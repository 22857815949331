import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Option, Title, Header } from './../styled';
import Tooltip from '@mui/material/Tooltip';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { transportManagement } from './transportManagement';
import { Links } from './links';
import { inventoryManagement } from './inventoryManagement';
import { helpers } from '@helpers';
import { settings } from '@constants';
import { hooks } from '@hooks';

export const Home = () => {
  const dispatch = useAppDispatch();
  const screenWidth = hooks.useScreenWidth();
  const [optionSelected, setOptionSelected] = useState('');
  const openMenu = useAppSelector(slices.settings.openMenu);
  const modules = useAppSelector(slices.modules.items);

  useEffect(() => {
    setOptionSelected(window.location.pathname);
  }, [window.location.pathname]);

  const openLink = () => {
    if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
  };

  return (
    <>
      {helpers.moduleIsActive(settings.modules.transportManagement, modules) && (
        <>
          {openMenu && transportManagement().length > 0 && (
            <Header
              paddingtop={modules.filter((module) => module.is_active).length === 1 ? 10 : 20}
            >
              Gestión de transportes
            </Header>
          )}
          {transportManagement().map((item, index) => (
            <Link key={index} to={item.url} onClick={openLink}>
              <Tooltip title={openMenu ? '' : item.name} placement="right" arrow>
                <Option status={optionSelected === item.url ? 'selected' : ''}>
                  {item.icon}
                  {openMenu && <Title>{item.name}</Title>}
                </Option>
              </Tooltip>
            </Link>
          ))}
        </>
      )}
      {helpers.moduleIsActive(settings.modules.inventoryManagement, modules) && (
        <>
          {openMenu && inventoryManagement().length > 0 && (
            <Header
              paddingtop={modules.filter((module) => module.is_active).length === 1 ? 10 : 20}
            >
              Gestión de inventario
            </Header>
          )}
          {inventoryManagement().map((item, index) => (
            <Link key={index} to={item.url} onClick={openLink}>
              <Tooltip title={openMenu ? '' : item.name} placement="right" arrow>
                <Option status={optionSelected === item.url ? 'selected' : ''}>
                  {item.icon}
                  {openMenu && <Title>{item.name}</Title>}
                </Option>
              </Tooltip>
            </Link>
          ))}
        </>
      )}
      <Links />
    </>
  );
};
