import { useEffect, useState } from 'react';
import { CropImage } from '@components';
import { helpers } from '@helpers';
import { services } from '@services';
import { INotification } from '@interfaces';
import user from 'src/assets/user.png';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const ChangeAvatar = ({ close, notification }: IProps) => {
  const controller = new AbortController();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const getAvatar = () => {
    if (helpers.getPropertyInCurrentUser('avatar')) {
      return helpers.getAvatar(helpers.getPropertyInCurrentUser('avatar'));
    } else {
      return user;
    }
  };

  const save = (image: any) => {
    setIsProcessing(true);
    services.user
      .changeAvatar(controller.signal, image)
      .then((response) => {
        helpers.updateCurrentUserAvatar(response.data);
        notification.success('Foto de perfil actualizada');
        close();
      })
      .catch(() => {
        setIsProcessing(false);
        notification.warning('No fue posible actualizar tu foto de perfil');
      });
  };

  return (
    <CropImage
      title="Foto de perfil"
      variant="avatar"
      initialImage={getAvatar()}
      close={close}
      save={save}
      isProcessing={isProcessing}
    />
  );
};
