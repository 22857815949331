import { useEffect } from 'react';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Notification } from '@components';
import { Wrapper } from './styled';
import { Header } from './header';
import { Table } from './table';
import { design } from '@constants';
import moment from 'moment';
import { hooks } from '@hooks';
import { services } from '@services';
import { IFront, ITrip, ITruckDemand } from '@interfaces';

export const Distribution = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const fronts = useAppSelector(slices.fronts.items);
  const loadedFronts = useAppSelector(slices.fronts.loaded);
  const frontCompliance = useAppSelector(slices.frontCompliance.items);
  const loadedFrontCompliance = useAppSelector(slices.frontCompliance.loaded);
  const trips = useAppSelector(slices.trips.items);
  const loadedTrips = useAppSelector(slices.trips.loaded);
  const settings = useAppSelector(slices.enterpriseSettings.settings);
  const loadedSettings = useAppSelector(slices.enterpriseSettings.loaded);
  const loadingSettings = useAppSelector(slices.enterpriseSettings.loading);

  type demandLevels = 'LOW' | 'HALF' | 'HIGH';

  useEffect(() => {
    if (!loadedSettings && !loadingSettings) getSettings();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    updateTruckDemand();
    const intervalId = setInterval(updateTruckDemand, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [fronts, frontCompliance, trips, settings]);

  const getSettings = async () => {
    dispatch(slices.enterpriseSettings.setLoading(true));
    await services.enterprise
      .enterpriseSettings(controller.signal)
      .then((response) => {
        dispatch(slices.enterpriseSettings.setSettings(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible obtener la configuración de la empresa');
      });
    dispatch(slices.enterpriseSettings.setLoading(false));
  };

  const updateTruckDemand = () => {
    if (loadedFronts && loadedFrontCompliance && loadedTrips && loadedSettings) {
      const truckDemand = fronts.map((front) => {
        let response = getDefaultTruckDemand(front.identifier);
        if (front.state.name === 'Activo') {
          response = setTruckDemand(response, 'HIGH');
          const mostRecentTrip = getRecentTrip(front.id);
          if (mostRecentTrip) {
            response = determineFrontCapacity(response, front, mostRecentTrip);
          }
          if (response['color'] === design.colors.green) {
            response = determineCompliance(response, front);
          }
        }
        return response;
      });
      dispatch(slices.truckDemand.setItems(orderByDemand(truckDemand)));
    }
  };

  const orderByDemand = (truckDemand: ITruckDemand[]) => {
    const inactiveFronts = truckDemand.filter((item) => item.color === design.colors.darkGray);
    const frontsWithLowDemand = truckDemand
      .filter((item) => item.color === design.colors.red)
      .sort((a, b) => b.percentage - a.percentage);
    return [
      ...getFrontsWithHighDemand(truckDemand),
      ...getFrontsWithHalfDemand(truckDemand),
      ...frontsWithLowDemand,
      ...inactiveFronts,
    ];
  };

  const getFrontsWithHighDemand = (truckDemand: ITruckDemand[]) => {
    const frontsWithHighDemand = truckDemand.filter((item) => item.color === design.colors.green);
    const frontsWithoutRecentTrips = frontsWithHighDemand.filter((item) => item.percentage === 0);
    const frontsWithRecentTrips = frontsWithHighDemand
      .filter((item) => item.percentage > 0)
      .sort((a, b) => b.percentage - a.percentage);
    return [...frontsWithoutRecentTrips, ...frontsWithRecentTrips];
  };

  const getFrontsWithHalfDemand = (truckDemand: ITruckDemand[]) => {
    const frontsWithHalfDemand = truckDemand.filter((item) => item.color === design.colors.orange);
    const frontsWithoutRecentTrips = frontsWithHalfDemand.filter((item) => item.percentage === 0);
    const frontsWithRecentTrips = frontsWithHalfDemand
      .filter((item) => item.percentage > 0)
      .sort((a, b) => b.percentage - a.percentage);
    return [...frontsWithoutRecentTrips, ...frontsWithRecentTrips];
  };

  const determineCompliance = (truckDemand: ITruckDemand, front: IFront) => {
    let estimatedCane = 0;
    let caneEntered = 0;
    frontCompliance.forEach((data) => {
      caneEntered += data.cane_entered;
    });
    fronts.forEach((data) => {
      estimatedCane += data.settings.quota;
    });
    const currentTime = moment();
    currentTime.hour(Number(settings.harvest_day_start_time?.split('T')[1].slice(0, 2)));
    currentTime.minute(Number(settings.harvest_day_start_time?.split('T')[1].slice(3, 5)));
    if (currentTime > moment()) {
      currentTime.subtract(1, 'days');
    }
    const elapsedTimeOfHarvestDay = moment().diff(currentTime, 'hours');
    const compliance = frontCompliance.filter((data) => data.id === front.id);
    if (compliance.length) {
      if (
        caneEntered > (elapsedTimeOfHarvestDay * estimatedCane) / 24 &&
        compliance[0].cane_entered > (elapsedTimeOfHarvestDay * front.settings.quota) / 24
      ) {
        truckDemand = setTruckDemand(truckDemand, 'HALF');
      }
    }
    return truckDemand;
  };

  const determineFrontCapacity = (
    truckDemand: ITruckDemand,
    front: IFront,
    mostRecentTrip: ITrip,
  ) => {
    const lastTripTime = moment().diff(moment(mostRecentTrip.settings.mill_departure_datetime));
    const lastTripLoadingTime = getMilliseconds(
      Math.ceil(mostRecentTrip.settings.cage_number / front.settings.simultaneous_loads) *
        front.settings.time_to_load,
    );
    const percentage = (lastTripTime * 100) / lastTripLoadingTime;
    truckDemand['percentage'] = percentage;
    if (percentage < 100) {
      truckDemand = setTruckDemand(truckDemand, 'LOW');
    }
    return truckDemand;
  };

  const setTruckDemand = (truckDemand: ITruckDemand, level: demandLevels) => {
    switch (level) {
      case 'LOW':
        truckDemand['background'] = design.colors.lightRed;
        truckDemand['color'] = design.colors.red;
        break;
      case 'HALF':
        truckDemand['background'] = design.colors.lightOrange;
        truckDemand['color'] = design.colors.orange;
        break;
      case 'HIGH':
        truckDemand['background'] = design.colors.lightGreen;
        truckDemand['color'] = design.colors.green;
        break;
    }
    return truckDemand;
  };

  const getDefaultTruckDemand = (identifier: string) => {
    return {
      identifier: identifier,
      background: design.colors.lightGray,
      color: design.colors.darkGray,
      percentage: 0,
    };
  };

  const getRecentTrip = (frontId: number) => {
    const dataList = trips
      .filter((trip) => trip.front?.id === frontId)
      .sort((a, b) => {
        const dateA = moment(a.settings.mill_departure_datetime).valueOf();
        const dateB = moment(b.settings.mill_departure_datetime).valueOf();
        return dateB - dateA;
      });
    return dataList.length ? dataList[0] : null;
  };

  const getMilliseconds = (decimalTime: number) => decimalTime * 60 * 60 * 1000;

  return (
    <Wrapper>
      <Header />
      <Table />
      <Notification data={notification} />
    </Wrapper>
  );
};
