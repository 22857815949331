import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
`;

export const Title = styled.div`
  padding-top: 30px;
  color: ${design.colors.darkGray};
`;
