import { settings } from '@constants';
import LinkIcon from '@mui/icons-material/Link';

export const links = [
  {
    name: 'Enlaces',
    url: '/admin/links',
    roles: [settings.roles.administrator],
    icon: <LinkIcon />,
  },
];
