import { useEffect, useState } from 'react';
import { Modal, ModalButtons, Skeleton, AvatarWithInfo } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { INotification, IState } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { OptionContainer } from './styled';
import { helpers } from '@helpers';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const UpdateState = ({ close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const states = useAppSelector(slices.states.items);
  const loading = useAppSelector(slices.states.loading);
  const loaded = useAppSelector(slices.states.loaded);
  const [state, setState] = useState<IState | null>(null);
  const [stateDescription, setStateDescription] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setStateDescription(helpers.getPropertyInCurrentUser('state_description'));
    if (!loaded && !loading) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const filteredStates = states.filter(
      (item) => item.id === helpers.getPropertyInCurrentUser('state')['id'],
    );
    if (filteredStates.length) setState(filteredStates[0]);
  }, [states]);

  const getItems = async () => {
    dispatch(slices.states.setLoading(true));
    await services.state
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.states.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los estados');
      });
    dispatch(slices.states.setLoading(false));
  };

  const next = async () => {
    if (state !== null) {
      setIsProcessing(true);
      await services.user
        .updateState(controller.signal, {
          user: helpers.getPropertyInCurrentUser('id'),
          state: state.id,
          state_description: stateDescription.trim(),
        })
        .then((response) => {
          helpers.updateCurrentUserState(
            response.data.state.id,
            response.data.state.name,
            response.data.state.color,
            response.data.state_description,
            response.data.state_date,
          );
          notification.success('Se actualizó tu estado');
        })
        .catch(() => {
          notification.warning('No fue posible actualizar tu estado');
        });
      setIsProcessing(false);
      close();
    } else {
      notification.warning('Selecciona el nuevo estado');
    }
  };

  const changeState = (newValue: IState | null) => {
    setState(newValue);
    setStateDescription('');
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Actualizar mi estado" close={close} actions={buttons} maxWidth="xs">
      {loading ? (
        <Skeleton variant="input" />
      ) : (
        <Autocomplete
          value={state}
          onChange={(_event: any, newValue: IState | null) => changeState(newValue)}
          options={states.filter((item) => item.scope.name === 'Usuarios')}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Estado"
              size="small"
              autoFocus
              sx={{ marginTop: '10px', marginBottom: '20px' }}
            />
          )}
          renderOption={(props, option) => (
            <OptionContainer {...props}>
              <AvatarWithInfo title={option.name} description={option.description} />
            </OptionContainer>
          )}
          noOptionsText="No hay opciones"
        />
      )}
      <TextField
        label="Descripción"
        value={stateDescription}
        onChange={(event) => setStateDescription(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        multiline
        minRows={4}
        maxRows={4}
        sx={{ marginBottom: '10px' }}
      />
    </Modal>
  );
};
