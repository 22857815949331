import styled from 'styled-components';
import { design } from '@constants';

export const UrlWrapper = styled.span`
  cursor: pointer;
  transition: 200ms;
  :hover {
    color: ${design.colors.primary};
  }
`;

export const IconWrapper = styled.span`
  color: ${design.colors.darkGray};
`;
