import { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Content, CropperWrapper } from './styled';
import { Options } from './options';
import { Buttons } from './buttons';
import { Modal } from '@components';
import getCroppedImg from './cropImage';

interface IProps {
  title: string;
  variant: string;
  initialImage: string;
  close: () => void;
  save: (image: any) => void;
  isProcessing: boolean;
}

export const CropImage = ({ title, variant, initialImage, close, save, isProcessing }: IProps) => {
  const [image, setImage] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const saveImage = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
    fetch(croppedImage)
      .then((res) => res.blob())
      .then((newImage) => {
        save(newImage);
      });
  };

  const cropSize = () => {
    switch (variant) {
      case 'avatar':
        return { width: 150, height: 150 };
      case 'logo':
        return { width: 400, height: 125 };
    }
  };

  const buttons = (
    <Buttons close={close} setImage={setImage} saveImage={saveImage} isProcessing={isProcessing} />
  );

  return (
    <Modal title={title} close={close} actions={buttons} maxWidth="sm">
      <Content>
        <CropperWrapper>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            showGrid={false}
            cropSize={cropSize()}
          />
        </CropperWrapper>
        <Options rotation={rotation} zoom={zoom} setRotation={setRotation} setZoom={setZoom} />
      </Content>
    </Modal>
  );
};
