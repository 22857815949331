import { IMIndicators } from '@interfaces';
import Grid from '@mui/material/Grid';
import { AvatarWithInfo } from '@components';
import { Wrapper, Container, Product, Title } from './styled';
import { design } from '@constants';
import { helpers } from '@helpers';

interface IProps {
  data: IMIndicators;
}

const BestSellingProducts = ({ data }: IProps) => {
  return (
    <Wrapper>
      <Container>
        {data.best_selling_products.map((item, index) => (
          <Product key={index}>
            <Grid container>
              <Grid item xs={9}>
                <AvatarWithInfo
                  title={item.product_name}
                  description={item.product_description}
                  titleColor={design.colors.primary}
                />
              </Grid>
              <Grid item xs={3}>
                <AvatarWithInfo title={helpers.getFormattedNumber(item.sales)} description="GTQ" />
              </Grid>
            </Grid>
          </Product>
        ))}
      </Container>
      <Title>Productos más vendidos del último año</Title>
    </Wrapper>
  );
};

export default BestSellingProducts;
