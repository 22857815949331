import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Menu } from '@components';
import { ITableOption } from '@interfaces';
import { OptionsWrapper } from './styled';

interface IProps {
  row: any;
  options: ITableOption[];
  showOption?: (option: any, index: number) => boolean;
}

export const TableOptions = ({ row, options, showOption }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (action: ((value: any) => void) | undefined) => {
    if (action) {
      action(row);
      handleClose();
    }
  };

  return (
    <TableCell style={{ width: 30 }} align="right">
      <Tooltip title="Opciones" placement="left" arrow>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} handleClose={handleClose}>
        <OptionsWrapper>
          {options
            .filter((_option, index) => (showOption ? showOption(row, index) : true))
            .map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => onClick(option.onClick)}
                disabled={option.onClick === undefined}
              >
                {option.title}
              </MenuItem>
            ))}
        </OptionsWrapper>
      </Menu>
    </TableCell>
  );
};
