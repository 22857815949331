import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

interface IProp {
  name: string;
  value: string;
  order: 'asc' | 'desc';
  orderBy: string;
  handleSort: (field: string) => void;
  align?: 'left' | 'center' | 'right' | 'justify';
  width?: number;
}

export const SortableColumn = ({
  name,
  value,
  order,
  orderBy,
  handleSort,
  align = 'left',
  width,
}: IProp) => {
  return (
    <TableCell sortDirection={orderBy === name ? order : false} align={align} width={width}>
      <TableSortLabel
        active={orderBy === name}
        direction={orderBy === name ? order : 'asc'}
        onClick={() => handleSort(name)}
      >
        {value}
      </TableSortLabel>
    </TableCell>
  );
};
