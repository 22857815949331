import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ITrip, ITripState } from '@interfaces';

const initialState: ITripState = {
  item: null,
  loaded: false,
  loading: false,
};

const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItem: (state, action: PayloadAction<ITrip | null>) => {
      state.item = action.payload;
      state.loaded = true;
    },
  },
});

const { setLoading, setItem } = tripSlice.actions;
const item = (state: RootState) => state.trip.item;
const loaded = (state: RootState) => state.trip.loaded;
const loading = (state: RootState) => state.trip.loading;

export const trip = {
  setLoading,
  setItem,
  item,
  loaded,
  loading,
};

export default tripSlice.reducer;
