import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Notification, Skeleton } from '@components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { hooks } from '@hooks';
import { CaneEnteredByTypeOfEquipment } from './caneEnteredByTypeOfEquipment';
import { CaneEnteredPerHour } from './caneEnteredPerHour';
import { GroundCanePerHour } from './groundCanePerHour';
import { SelfAssigned } from './selfAssigned';
import { TimeToGo } from './timeToGo';
import { TimeToLoad } from './timeToLoad';
import { TimeToReturn } from './timeToReturn';
import { QuotaCompliance } from './quotaCompliance';
import { FrontsByState } from './frontsByState';
import { TrucksByState } from './trucksByState';
import { EquipmentPendingDownload } from './equipmentPendingDownload';
import { CaneEntryInTheNextFewHours } from './caneEntryInTheNextFewHours';
import { Wrapper, Container, Title } from './styled';

export const Indicators = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const data = useAppSelector(slices.indicators.data);
  const loadingIndicators = useAppSelector(slices.indicators.loading);
  const loadedIndicators = useAppSelector(slices.indicators.loaded);
  const loadingFronts = useAppSelector(slices.fronts.loading);
  const loadedFronts = useAppSelector(slices.fronts.loaded);
  const loadingEquipments = useAppSelector(slices.equipmentsDownload.loading);
  const loadedEquipments = useAppSelector(slices.equipmentsDownload.loaded);
  const loadingTrips = useAppSelector(slices.trips.loading);
  const loadedTrips = useAppSelector(slices.trips.loaded);
  const loadingTrucks = useAppSelector(slices.trucks.loading);
  const loadedTrucks = useAppSelector(slices.trucks.loaded);

  useEffect(() => {
    if (!loadedIndicators && !loadingIndicators) getIndicators();
    if (!loadedFronts && !loadingFronts) getFronts();
    if (!loadedEquipments && !loadingEquipments) getEquipmentPendingDownload();
    if (!loadedTrips && !loadingTrips) getTrips();
    if (!loadedTrucks && !loadingTrucks) getTrucks();
    return () => {
      controller.abort();
    };
  }, []);

  const getIndicators = async () => {
    dispatch(slices.indicators.setLoading(true));
    await services.enterprise
      .indicators(controller.signal)
      .then((response) => {
        dispatch(slices.indicators.setData(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar los indicadores');
      });
    dispatch(slices.indicators.setLoading(false));
  };

  const getFronts = async () => {
    dispatch(slices.fronts.setLoading(true));
    await services.front
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.fronts.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar los frentes');
      });
    dispatch(slices.fronts.setLoading(false));
  };

  const getEquipmentPendingDownload = async () => {
    dispatch(slices.equipmentsDownload.setLoading(true));
    await services.trip
      .allForDownload(controller.signal)
      .then((response) => {
        dispatch(slices.equipmentsDownload.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar los equipos pendientes de descarga');
      });
    dispatch(slices.equipmentsDownload.setLoading(false));
  };

  const getTrips = async () => {
    dispatch(slices.trips.setLoading(true));
    await services.trip
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trips.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar los viajes');
      });
    dispatch(slices.trips.setLoading(false));
  };

  const getTrucks = async () => {
    dispatch(slices.trucks.setLoading(true));
    await services.truck
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trucks.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar los camiones');
      });
    dispatch(slices.trucks.setLoading(false));
  };

  return (
    <Wrapper>
      <Title>Indicadores</Title>
      <Container>
        {(loadingIndicators ||
          loadingFronts ||
          loadingEquipments ||
          loadingTrips ||
          loadingTrucks) && <Skeleton variant="indicators" />}
        {!loadingIndicators &&
          !loadingFronts &&
          !loadingEquipments &&
          !loadingTrips &&
          !loadingTrucks &&
          data && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3} style={{ display: 'grid' }}>
                <SelfAssigned data={data} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} style={{ display: 'grid' }}>
                <TimeToGo data={data} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} style={{ display: 'grid' }}>
                <TimeToLoad data={data} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} style={{ display: 'grid' }}>
                <TimeToReturn data={data} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CaneEnteredByTypeOfEquipment data={data} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <QuotaCompliance data={data} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FrontsByState />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TrucksByState />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CaneEnteredPerHour data={data} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <GroundCanePerHour data={data} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <EquipmentPendingDownload />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CaneEntryInTheNextFewHours />
              </Grid>
            </Grid>
          )}
      </Container>
      <Notification data={notification} />
    </Wrapper>
  );
};
