import styled, { css } from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  background-color: white;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  padding: 10px;
  ${(props: any) =>
    css`
      height: calc(${props.height}px - 124px);
    `}
  overflow: auto;
  @media (max-width: 600px) {
    ${(props: any) =>
      css`
        height: calc(${props.height}px - 118px);
      `}
  }
  ${(props: any) =>
    !props.showDetail &&
    css`
      ${Option} {
        height: 18px;
        text-align: center;
        svg {
          float: none;
        }
      }
    `}
`;

export const Header = styled.div`
  padding-top: ${(props: any) => props.paddingtop}px;
  padding-bottom: 20px;
  padding-left: 15px;
  color: ${design.colors.darkGray};
  font-weight: bold;
`;

export const Option = styled.div`
  height: 18px;
  color: ${design.colors.darkGray};
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  svg {
    float: left;
    margin-top: -3px;
  }
  :hover {
    background-color: ${design.colors.lightGray};
  }
  ${(props: any) =>
    props.status === 'selected' &&
    css`
      background-color: ${design.colors.lightPrimary} !important;
      color: ${design.colors.primary};
    `}
`;

export const Title = styled.div`
  padding-left: 35px;
`;
