import Grid from '@mui/material/Grid';
import { AvatarWithInfo } from 'src/components/reusables/avatarWithInfo';
import { Wrapper, Title } from './styled';
import { settings } from '@constants';

export const Header = () => {
  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={6}>
          <Title>Facturación</Title>
        </Grid>
        <Grid item xs={6}>
          <AvatarWithInfo
            title="Tasa de cambio"
            description={`1 USD = ${settings.exchangeRate} GTQ`}
            highlightDescription={true}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};
