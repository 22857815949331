import { useEffect, useState, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Tooltip from '@mui/material/Tooltip';
import {
  Wrapper,
  Title,
  Subtitle,
  LogoWrapper,
  Content,
  ContentTitle,
  ContentDescription,
  Icon,
} from './styled';
import { ModalButtons, Notification, CropImage, ModalConfirm } from '@components';
import { Activate } from './activate';
import { hooks } from '@hooks';
import { services } from '@services';
import { useNavigate } from 'react-router-dom';
import { helpers } from '@helpers';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { slices } from '@slices';

interface IProps {
  setCreatingEnterprise: (value: boolean) => void;
}

export const CreateEnterprise = ({ setCreatingEnterprise }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notification = hooks.useNotification();
  const controller = new AbortController();
  const [name, setName] = useState('');
  const [logo, setLogo] = useState<File>();
  const [urlLogo, setUrlLogo] = useState('');
  const [urlFile, setUrlFile] = useState('');
  const [isCropping, setIsCropping] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isActivatingModules, setIsActivatingModules] = useState(false);
  const [selectedModules, setSelectedModules] = useState<number[]>([]);
  const modules = useAppSelector(slices.modules.items);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const save = async () => {
    setIsProcessing(true);
    await services.enterprise
      .createEnterprise(controller.signal, name.trim(), logo, selectedModules)
      .then(() => {
        const updatedModules = modules.map((module) => {
          if (selectedModules.includes(module.id)) {
            return module;
          } else {
            return {
              ...module,
              is_active: false,
            };
          }
        });
        dispatch(slices.modules.setItems(updatedModules));
        helpers.deleteJwt();
        navigate('/login?event=enterpriseCreated');
      })
      .catch(() => {
        notification.warning('No fue posible crear la empresa');
      });
    setIsProcessing(false);
  };

  const validate = () => {
    if (name.trim() === '') {
      notification.warning('Completa el nombre de la empresa para continuar');
    } else {
      setIsActivatingModules(true);
    }
  };

  const cancel = () => {
    setCreatingEnterprise(false);
  };

  const cancelCropping = () => {
    setIsCropping(false);
  };

  const onConfirm = () => {
    setIsActivatingModules(false);
    setIsConfirming(true);
  };

  const saveCropping = (newImage: any) => {
    setLogo(newImage);
    setUrlLogo(URL.createObjectURL(newImage));
    setIsCropping(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setUrlFile(URL.createObjectURL(e.target.files[0]));
      setIsCropping(true);
    }
  };

  return (
    <Wrapper>
      <Title>Creación de empresa</Title>
      <Subtitle>Agrega el nombre y logotipo para empezar</Subtitle>
      <TextField
        label="Nombre de la empresa"
        value={name}
        onChange={(event) => setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <Tooltip
        title={logo === undefined ? '' : 'Haz clic para cambiar el logotipo de la empresa'}
        arrow
      >
        <LogoWrapper img={urlLogo} component="label">
          {logo === undefined ? (
            <Content>
              <ContentTitle>Haz clic para agregar el logotipo de la empresa</ContentTitle>
              <ContentDescription>
                Se aceptan imágenes en formato JPG, JPEG y PNG
              </ContentDescription>
            </Content>
          ) : (
            <Icon>
              <PhotoCameraIcon />
            </Icon>
          )}
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleFileChange}
            onClick={(e: any) => {
              e.target.value = null;
            }}
            hidden
          />
        </LogoWrapper>
      </Tooltip>
      <ModalButtons
        isProcessing={false}
        secondaryAction={cancel}
        primaryAction={validate}
        secondaryText="Cancelar"
        primaryText="Continuar"
      />
      {isCropping && (
        <CropImage
          title="Logotipo de empresa"
          variant="logo"
          initialImage={urlFile}
          close={cancelCropping}
          save={saveCropping}
          isProcessing={false}
        />
      )}
      {isActivatingModules && (
        <Activate
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
          next={onConfirm}
          close={() => setIsActivatingModules(false)}
          notification={notification}
        />
      )}
      {isConfirming && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se creará la nueva empresa con los módulos seleccionados y se iniciará con el cobro mensual"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={save}
          secondaryAction={() => setIsConfirming(false)}
          isProcessing={isProcessing}
        />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
