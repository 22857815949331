import { useEffect } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IUseEquipment, INotification, IEquipmentType } from '@interfaces';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

interface IProps {
  data: IUseEquipment;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const equipmentTypes = useAppSelector(slices.equipmentTypes.items);
  const loading = useAppSelector(slices.equipmentTypes.loading);
  const loaded = useAppSelector(slices.equipmentTypes.loaded);

  useEffect(() => {
    if (!loaded && !loading) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.equipmentTypes.setLoading(true));
    await services.equipmentType
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.equipmentTypes.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los tipos');
      });
    dispatch(slices.equipmentTypes.setLoading(false));
  };

  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe un equipo con este código');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} equipo`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Código"
        value={data.code}
        onChange={(event) => data.setCode(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <Autocomplete
        value={data.type}
        onChange={(_event: any, newValue: IEquipmentType | null) => data.setType(newValue)}
        options={equipmentTypes}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="Tipo" size="small" sx={{ marginBottom: '20px' }} />
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};
