import { useState } from 'react';
import { IUseTruck, ITruck, IBrand } from '@interfaces';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

export const useTruck = () => {
  const items = useAppSelector(slices.trucks.items);
  const [id, setId] = useState(0);
  const [code, setCode] = useState('');
  const [brand, setBrand] = useState<IBrand | null>(null);
  const [model, setModel] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: ITruck) => {
    setId(item ? item.id : 0);
    setCode(item ? item.code : '');
    setBrand(item ? item.brand : null);
    setModel(item ? item.model.toString() : '');
  };

  const isValid = () => {
    if (code.trim() === '' || brand === null || model.trim() === '') {
      setCode(code.trim());
      setModel(model.trim());
      return false;
    }
    return true;
  };

  const isDuplicated = () =>
    items.filter((item) => item.code.toLowerCase() === code.trim().toLowerCase() && item.id !== id)
      .length > 0;

  const object = () => {
    return {
      id: id,
      code: code.trim(),
      brand: brand?.id,
      model: Number(model.trim()),
    };
  };

  const data: IUseTruck = {
    id,
    code,
    brand,
    model,
    isCreating,
    isUpdating,
    isDeleting,
    isProcessing,
    setCode,
    setBrand,
    setModel,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsProcessing,
    initialize,
    isValid,
    isDuplicated,
    object,
  };

  return data;
};
