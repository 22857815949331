const colors = {
  imageBackground: 'rgba(0, 0, 0, 0.54)',
  mediaControlsBackground: 'rgba(0, 0, 0, 0.5)',
  darkPrimary: '#062d70',
  primary: '#1d57aa',
  lightPrimary: '#1d57aa1A',
  ultralightPrimary: '#1d57aa0D',
  mediumPrimary: '#1d57aa26',
  secondary: '#28cbfd',
  title: '#28cbfd',
  content: '#202020',
  darkGray: '#808080',
  ultralightGray: 'rgba(32,33,36,0.04)',
  lightGray: 'rgba(32,33,36,0.07)',
  mediumGray: 'rgba(32,33,36,0.2)',
  red: 'rgb(211, 47, 47)',
  orange: 'rgb(237, 108, 2)',
  blue: 'rgb(2, 136, 209)',
  green: 'rgb(46, 125, 50)',
  lightRed: 'rgba(211, 47, 47, 0.2)',
  lightOrange: 'rgba(237, 108, 2, 0.2)',
  lightBlue: 'rgba(2, 136, 209, 0.2)',
  lightGreen: 'rgba(46, 125, 50, 0.2)',
  opacity: {
    '00': '00',
    '0D': '0D',
    '1A': '1A',
    '26': '26',
    '33': '33',
    '40': '40',
    '4D': '4D',
    '59': '59',
    '66': '66',
    '73': '73',
    '80': '80',
    '8C': '8C',
    '99': '99',
    A6: 'A6',
    B3: 'B3',
    BF: 'BF',
    CC: 'CC',
    D9: 'D9',
    E6: 'E6',
    F2: 'F2',
    FF: 'FF',
  },
  pallette: [
    '#1d57aa',
    '#28cbfd',
    '#70a906',
    '#fd8228',
    '#fdcb28',
    '#fd28cb',
    '#28e1fd',
    '#88c0d0',
    '#d08770',
    '#ebcb8b',
    '#a3be8c',
    '#b48ead',
    '#5e81ac',
    '#4c566a',
    '#d8dee9',
    '#bf616a',
    '#eceff4',
  ],
};

const shadows = {
  default: 'rgb(99 99 99 / 20%) 0px 2px 8px 0px',
};

export const design = {
  colors,
  shadows,
};
