import { useEffect, useState } from 'react';
import { helpers } from '@helpers';
import { Wrapper, Container, CodeWrapper, Logo, UpdateConfiguration } from './styled';
import { Fieldset, AvatarWithInfo, Notification } from '@components';
import BusinessIcon from '@mui/icons-material/Business';
import TokenIcon from '@mui/icons-material/Token';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { ChangeLogo } from './changeLogo';
import { UpdateSettings } from './updateSettings';
import { hooks } from '@hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import moment from 'moment';
import logo from 'src/assets/logo.jpg';
import { settings } from '@constants';

export const Enterprise = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const [isUpdatingLogo, setIsUpdatingLogo] = useState(false);
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);
  const enterpriseSettings = useAppSelector(slices.enterpriseSettings.settings);
  const loading = useAppSelector(slices.enterpriseSettings.loading);
  const loaded = useAppSelector(slices.enterpriseSettings.loaded);
  const modules = useAppSelector(slices.modules.items);

  useEffect(() => {
    if (!loaded && !loading) {
      getSettings();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getSettings = async () => {
    dispatch(slices.enterpriseSettings.setLoading(true));
    await services.enterprise
      .enterpriseSettings(controller.signal)
      .then((response) => {
        dispatch(slices.enterpriseSettings.setSettings(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible obtener la configuración de la empresa');
      });
    dispatch(slices.enterpriseSettings.setLoading(false));
  };

  const copyEnterpriseToken = () => {
    helpers.copyToClipboard(helpers.getPropertyInCurrentUser('enterprise')['token']);
    notification.success('El código de empresa ha sido copiado');
  };

  const cancelAction = () => {
    setIsUpdatingLogo(false);
    setIsUpdatingSettings(false);
  };

  return (
    <Wrapper>
      <Container>
        <Logo>
          <Tooltip title="Haz clic para cambiar el logotipo de la empresa" arrow>
            <IconButton onClick={() => setIsUpdatingLogo(true)}>
              <PhotoCameraIcon />
            </IconButton>
          </Tooltip>
          <img
            src={
              helpers.getPropertyInCurrentUser('enterprise')['logo']
                ? helpers.getLogo(helpers.getPropertyInCurrentUser('enterprise')['logo'])
                : logo
            }
          />
        </Logo>
        <Fieldset title="General" active={true} size="medium">
          <Grid container spacing={3} style={{ marginBottom: -22 }}>
            <Grid item xs={12} md={6}>
              <AvatarWithInfo
                title="Nombre"
                description={helpers.getPropertyInCurrentUser('enterprise')['name']}
                highlightDescription={true}
                avatar={<BusinessIcon />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CodeWrapper>
                <AvatarWithInfo
                  title="Código"
                  description={helpers.getPropertyInCurrentUser('enterprise')['token']}
                  highlightDescription={true}
                  avatar={<TokenIcon />}
                />
                <Tooltip title="Copiar código de empresa" arrow>
                  <IconButton onClick={copyEnterpriseToken} size="small">
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </CodeWrapper>
            </Grid>
          </Grid>
        </Fieldset>
        {helpers.moduleIsActive(settings.modules.transportManagement, modules) && (
          <Fieldset title="Configuración" active={true} size="medium">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <AvatarWithInfo
                  title="Velocidad promedio de los camiones"
                  description={`${enterpriseSettings.average_speed} km/h`}
                  highlightDescription={true}
                  avatar={<SpeedIcon />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AvatarWithInfo
                  title="Hora de inicio de un día de zafra"
                  description={moment(enterpriseSettings.harvest_day_start_time).format('HH:mm')}
                  highlightDescription={true}
                  avatar={<AccessTimeIcon />}
                />
              </Grid>
            </Grid>
            <UpdateConfiguration>
              <Button onClick={() => setIsUpdatingSettings(true)} variant="outlined" size="large">
                Actualizar configuración
              </Button>
            </UpdateConfiguration>
          </Fieldset>
        )}
      </Container>
      {isUpdatingLogo && <ChangeLogo close={cancelAction} notification={notification} />}
      {isUpdatingSettings && <UpdateSettings close={cancelAction} notification={notification} />}
      <Notification data={notification} />
    </Wrapper>
  );
};
