import { useEffect } from 'react';
import { Wrapper, Title, Content, SkeletonWrapper } from './styled';
import { slices } from '@slices';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Notification, Skeleton, WithoutData } from '@components';
import { hooks } from '@hooks';
import { RequestTrip } from './requestTrip';
import { Detail } from './detail';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const CurrentTrip = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const currentTrip = useAppSelector(slices.trip.item);
  const loaded = useAppSelector(slices.trip.loaded);
  const loading = useAppSelector(slices.trip.loading);

  useEffect(() => {
    if (!loaded && !loading) {
      getCurrentTrip();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getCurrentTrip = async () => {
    dispatch(slices.trip.setLoading(true));
    await services.trip
      .current(controller.signal)
      .then((response) => {
        dispatch(slices.trip.setItem(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible cargar el detalle de la asignación actual');
      });
    dispatch(slices.trip.setLoading(false));
  };

  return (
    <Wrapper>
      <Title>Asignación actual</Title>
      {loading ? (
        <SkeletonWrapper>
          <Skeleton variant="currentTrip" />
        </SkeletonWrapper>
      ) : currentTrip ? (
        currentTrip.front ? (
          <Detail trip={currentTrip} notification={notification} />
        ) : (
          <Content>
            <WithoutData
              icon={<LocalShippingIcon />}
              title="Esperando asignación"
              description="En esta sección se mostrarán los detalles de la asignación"
            />
          </Content>
        )
      ) : (
        <Content>
          <RequestTrip notification={notification} />
        </Content>
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
