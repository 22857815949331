import { useEffect, useState } from 'react';
import { AvatarWithInfo, Fieldset, Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IFront, INotification, IUseAssignTrip } from '@interfaces';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { OptionContainer, Information, Indicator, FieldsetWrapper } from './styled';

interface IProps {
  data: IUseAssignTrip;
  close: () => void;
  notification: INotification;
}

export const AssignTrip = ({ data, close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [options, setOptions] = useState<IFront[]>([]);
  const fronts = useAppSelector(slices.fronts.items);
  const truckDemand = useAppSelector(slices.truckDemand.items);

  useEffect(() => {
    let frontList = truckDemand.map(
      (item) => fronts.filter((front) => front.identifier === item.identifier)[0],
    );
    frontList = frontList.filter((front) => {
      if (front.equipment_type.id !== data.trip?.equipment_type.id) return false;
      if (front.settings.minimum_cages > data.trip?.settings.cage_number) return false;
      if (front.settings.maximum_cages < data.trip?.settings.cage_number) return false;
      return true;
    });
    setOptions(frontList);
    if (frontList.length) {
      data.setFront(frontList[0]);
      data.setAutomaticAssignment(true);
    }
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    if (data.isValid()) {
      data.setIsProcessing(true);
      await services.trip
        .assign(controller.signal, data.object())
        .then((response) => {
          dispatch(slices.trips.updateItem(response.data));
          notification.success('Un nuevo viaje ha sido asignado');
          close();
        })
        .catch(() => {
          notification.warning('No fue posible asignar el nuevo viaje');
        });
      data.setIsProcessing(false);
    } else {
      notification.warning('Debes seleccionar un frente');
    }
  };

  const setFront = (front: IFront | null) => {
    data.setFront(front);
    data.setAutomaticAssignment(false);
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Asignar viaje" close={close} actions={buttons} maxWidth="xs">
      <FieldsetWrapper>
        <Fieldset title="Conductor">
          <AvatarWithInfo
            avatar={data.trip?.driver.avatar}
            title={`${data.trip?.driver.first_name} ${data.trip?.driver.last_name}`}
            description={data.trip?.driver.phone}
          />
        </Fieldset>
      </FieldsetWrapper>
      <FieldsetWrapper>
        <Fieldset title="Camión">
          <AvatarWithInfo
            title={data.trip?.truck.code || ''}
            description={`${data.trip?.equipment_type.name} de ${data.trip?.settings.cage_number} jaulas`}
          />
        </Fieldset>
      </FieldsetWrapper>
      <Information>Solo se listan los frentes que cumplen con la solicitud de viaje</Information>
      <Autocomplete
        value={data.front}
        onChange={(_event: any, newValue: IFront | null) => setFront(newValue)}
        options={options}
        getOptionLabel={(option) =>
          `Frente ${option.identifier} ubicado en finca ${option.farm.name}`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Frente"
            size="small"
            autoFocus
            sx={{ marginTop: '10px', marginBottom: '20px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <Indicator
              color={truckDemand.filter((item) => item.identifier === option.identifier)[0].color}
            />
            <AvatarWithInfo
              title={`Frente ${option.identifier}`}
              description={`Ubicado en finca ${option.farm.name}`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};
