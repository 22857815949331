import styled, { css } from 'styled-components';
import { design } from '@constants';
import Button from '@mui/material/Button';

export const Wrapper = styled.div`
  box-shadow: ${design.shadows.default};
  background-color: white;
  width: 100%;
  max-width: 450px;
  padding: 50px;
  border-radius: 10px;
  margin: auto;
  @media (max-width: 599px) {
    padding: 20px;
    margin-left: 16px;
    margin-right: 16px;
    max-width: unset;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 5px;
  opacity: 0.7;
`;

export const Subtitle = styled.div`
  opacity: 0.5;
  font-size: 16px;
  margin-bottom: 30px;
`;

export const LogoWrapper = styled(Button)`
  width: 100%;
  text-align: center;
  border-radius: 10px !important;
  padding: 20px !important;
  margin-bottom: 30px !important;
  height: 130px;
  ${(props: any) =>
    props.img === ''
      ? css`
          background-color: ${design.colors.ultralightGray} !important;
          :hover {
            background-color: ${design.colors.lightGray} !important;
          }
        `
      : css`
          background-image: url(${props.img});
          background-size: cover;
          background-position: center center;
          background-color: ${design.colors.imageBackground};
          :hover {
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
              url(${props.img});
            span {
              opacity: 1;
            }
          }
        `}
`;

export const Content = styled.div`
  color: ${design.colors.content} !important;
  font-weight: normal;
`;

export const ContentTitle = styled.div``;

export const ContentDescription = styled.div`
  opacity: 0.7;
`;

export const Icon = styled.span`
  transition: 300ms !important;
  opacity: 0;
  color: white !important;
  background-color: ${design.colors.mediaControlsBackground} !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  svg {
    margin-top: 9px;
  }
`;
