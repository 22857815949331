import { useEffect, useState } from 'react';
import { ChipWrapper, Container } from './styled';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Chip from '@mui/material/Chip';
import { WithoutData, Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import BallotIcon from '@mui/icons-material/Ballot';
import { Header } from './header';
import { Body } from './body';
import { IBinnacleRecord } from '@interfaces';

export const Table = () => {
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IBinnacleRecord[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  useEffect(() => {
    getItems(offset);
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async (newOffset: number) => {
    setOffset(newOffset);
    setLoading(true);
    await services.product
      .getBinnacle(controller.signal, limit, newOffset)
      .then((response) => {
        setItems([...items, ...response.data.records]);
        setTotalItems(response.data.total);
      })
      .catch(() => {
        notification.warning('No fue posible listar las actualizaciones registradas');
      });
    setLoading(false);
  };

  const removeItem = (id: number) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const seeMore = () => {
    getItems(offset + limit);
  };

  return (
    <>
      <ChipWrapper>
        <Chip
          label={`${items.length} ${
            items.length === 1 ? 'actualización encontrada' : 'actualizaciones encontradas'
          }`}
          size="small"
        />
      </ChipWrapper>
      <Container>
        {loading || items.length ? (
          <TableContainer>
            <MUITable size="small" stickyHeader sx={{ minWidth: 900 }}>
              <Header />
              <Body
                items={items}
                loading={loading}
                seeMore={items.length < totalItems ? seeMore : undefined}
                removeItem={removeItem}
                notification={notification}
              />
            </MUITable>
          </TableContainer>
        ) : (
          <WithoutData
            icon={<BallotIcon />}
            title="No hay actualizaciones"
            description="En esta sección se listarán las actualizaciones registradas"
          />
        )}
        <Notification data={notification} />
      </Container>
    </>
  );
};
