import { useState } from 'react';
import { Wrapper, Divider } from './styled';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import { UserInformation } from './userInformation';
import { Options } from './options';
import { ChangeAvatar } from './userInformation/changeAvatar';
import { Footer } from './footer';
import { UpdateState } from './options/updateState';
import { Menu, Notification } from '@components';
import { helpers } from '@helpers';
import { hooks } from '@hooks';

export const Profile = () => {
  const notification = hooks.useNotification();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isChangingAvatar, setIsChangingAvatar] = useState(false);
  const [isUpdatingState, setIsUpdatingState] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAvatar = () => {
    handleClose();
    setIsChangingAvatar(true);
  };

  const close = () => {
    setIsChangingAvatar(false);
    setIsUpdatingState(false);
  };

  const onUpdateState = () => {
    handleClose();
    setIsUpdatingState(true);
  };

  const getAvatar = () => {
    if (helpers.getPropertyInCurrentUser('avatar')) {
      return helpers.getAvatar(helpers.getPropertyInCurrentUser('avatar'));
    } else {
      return undefined;
    }
  };

  return (
    <Wrapper
      statecolor={
        helpers.getPropertyInCurrentUser('state')
          ? helpers.getPropertyInCurrentUser('state')['color']
          : ''
      }
    >
      <Tooltip title="Mi perfil" arrow>
        <IconButton onClick={handleClick} size="small">
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            color="primary"
            overlap="circular"
            variant={helpers.getPropertyInCurrentUser('state') ? 'dot' : undefined}
          >
            <Avatar alt="Foto de perfil" src={getAvatar()}>
              <PersonIcon />
            </Avatar>
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} handleClose={handleClose}>
        <UserInformation changeAvatar={changeAvatar} />
        <Divider />
        <Options handleClose={handleClose} onUpdateState={onUpdateState} />
        {helpers.getPropertyInCurrentUser('enterprise') && (
          <Footer handleClose={handleClose} notification={notification} />
        )}
      </Menu>
      {isChangingAvatar && <ChangeAvatar close={close} notification={notification} />}
      {isUpdatingState && <UpdateState close={close} notification={notification} />}
      <Notification data={notification} />
    </Wrapper>
  );
};
