import { useEffect, useState } from 'react';
import { Wrapper } from './styled';
import { Header } from '@components';
import { Content } from './content';
import { CreateEnterprise } from './createEnterprise';
import { JoinEnterprise } from './joinEnterprise';
import { useAppDispatch } from 'src/store/hooks';
import { slices } from '@slices';
import { helpers } from '@helpers';
import { hooks } from '@hooks';
import { useNavigate } from 'react-router-dom';

export const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const screenHeight = hooks.useScreenHeight();
  const [creatingEnterprise, setCreatingEnterprise] = useState(false);
  const [joiningEnterprise, setJoiningEnterprise] = useState(false);

  useEffect(() => {
    if (!helpers.isAuthenticated()) {
      navigate('/login');
    }
    dispatch(slices.settings.setInHome());
  }, []);

  return (
    <>
      <Header />
      <Wrapper height={screenHeight}>
        {!creatingEnterprise && !joiningEnterprise && (
          <Content
            setCreatingEnterprise={setCreatingEnterprise}
            setJoiningEnterprise={setJoiningEnterprise}
          />
        )}
        {creatingEnterprise && <CreateEnterprise setCreatingEnterprise={setCreatingEnterprise} />}
        {joiningEnterprise && <JoinEnterprise setJoiningEnterprise={setJoiningEnterprise} />}
      </Wrapper>
    </>
  );
};
