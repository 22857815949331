import { useEffect, useState } from 'react';
import { AvatarWithInfo, Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { INotification, ITripForDownload, ITruck } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { OptionContainer } from './styled';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const AssignEquipment = ({ close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [trip, setTrip] = useState<ITripForDownload | null>();
  const [truck, setTruck] = useState<ITruck | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const trucks = useAppSelector(slices.trucks.items);
  const loadingTrucks = useAppSelector(slices.trucks.loading);
  const loadedTrucks = useAppSelector(slices.trucks.loaded);
  const equipmentsDownload = useAppSelector(slices.equipmentsDownload.items);
  const loadingEquipmentsDownload = useAppSelector(slices.equipmentsDownload.loading);
  const loadedEquipmentsDownload = useAppSelector(slices.equipmentsDownload.loaded);

  useEffect(() => {
    if (!loadedTrucks && !loadingTrucks) {
      getTrucks();
    }
    if (!loadedEquipmentsDownload && !loadingEquipmentsDownload) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getTrucks = async () => {
    dispatch(slices.trucks.setLoading(true));
    await services.truck
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trucks.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los camiones');
      });
    dispatch(slices.trucks.setLoading(false));
  };

  const getItems = async () => {
    dispatch(slices.equipmentsDownload.setLoading(true));
    await services.trip
      .allForDownload(controller.signal)
      .then((response) => {
        dispatch(slices.equipmentsDownload.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los equipos pendientes de descarga');
      });
    dispatch(slices.equipmentsDownload.setLoading(false));
  };

  const next = async () => {
    if (trip && truck) {
      setIsProcessing(true);
      await services.trip
        .assignEquipment(controller.signal, trip.id, truck.id)
        .then((response) => {
          dispatch(slices.trip.setItem(response.data));
          notification.success('Este equipo ha sido asignado');
          close();
        })
        .catch((error) => {
          switch (error.response.data.detail) {
            case 'This truck has already been assigned':
              notification.warning('Este camión ya fue asignado');
              break;
            case 'This equipment has already been assigned':
              notification.warning('Este equipo ya fue asignado');
              break;
            default:
              notification.warning('No fue posible asignar este equipo');
              break;
          }
        });
      setIsProcessing(false);
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Asignar equipo" close={close} actions={buttons} maxWidth="xs">
      <Autocomplete
        value={truck}
        onChange={(_event: any, newValue: ITruck | null) => setTruck(newValue)}
        options={trucks}
        getOptionLabel={(option) => `${option.code} ${option.brand.name} ${option.model}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camión"
            size="small"
            autoFocus
            sx={{ marginBottom: '20px', marginTop: '10px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              title={option.code}
              description={`${option.brand.name} ${option.model}`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
      <Autocomplete
        value={trip}
        onChange={(_event: any, newValue: ITripForDownload | null) => setTrip(newValue)}
        options={equipmentsDownload}
        getOptionLabel={(option) =>
          `${option.equipment.code} ${option.equipment_type.name} de ${option.settings.cage_number} jaulas`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="Equipo" size="small" sx={{ marginBottom: '20px' }} />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              title={option.equipment.code}
              description={`${option.equipment_type.name} de ${option.settings.cage_number} jaulas`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};
