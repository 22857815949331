import {
  Wrapper,
  Title,
  Description,
  Option,
  Icon,
  OptionTitle,
  OptionDescription,
} from './styled';
import Grid from '@mui/material/Grid';
import BusinessIcon from '@mui/icons-material/Business';
import MergeTypeIcon from '@mui/icons-material/MergeType';

interface IProps {
  setCreatingEnterprise: (value: boolean) => void;
  setJoiningEnterprise: (value: boolean) => void;
}

export const Content = ({ setCreatingEnterprise, setJoiningEnterprise }: IProps) => {
  return (
    <Wrapper>
      <Title>Te damos la bienvenida</Title>
      <Description>
        Gracias por utilizar nuestros servicios. Ahora puedes crear una nueva empresa o unirte a una
        ya existente.
      </Description>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Option onClick={() => setCreatingEnterprise(true)}>
            <Icon>
              <BusinessIcon />
            </Icon>
            <OptionTitle>Crear una empresa</OptionTitle>
            <OptionDescription>
              Crea una instancia para tu empresa y configúrala según tus necesidades.
            </OptionDescription>
          </Option>
        </Grid>
        <Grid item xs={12} md={6}>
          <Option onClick={() => setJoiningEnterprise(true)}>
            <Icon>
              <MergeTypeIcon />
            </Icon>
            <OptionTitle>Unirme a una empresa</OptionTitle>
            <OptionDescription>
              Únete de forma fácil y rápida a una empresa existente.
            </OptionDescription>
          </Option>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
