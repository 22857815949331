import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { settings } from '@constants';

export const options = [
  {
    name: 'Regresar al inicio',
    url: '/',
    roles: [settings.roles.administrator],
    icon: <HomeIcon />,
  },
  {
    name: 'Empresa',
    url: '/admin/enterprise',
    roles: [settings.roles.administrator],
    icon: <BusinessIcon />,
  },
  {
    name: 'Usuarios activos',
    url: '/admin/active-users',
    roles: [settings.roles.administrator],
    icon: <AdminPanelSettingsIcon />,
  },
  {
    name: 'Nuevos usuarios',
    url: '/admin/new-users',
    roles: [settings.roles.administrator],
    icon: <GroupAddIcon />,
  },
  {
    name: 'Módulos',
    url: '/admin/modules',
    roles: [settings.roles.administrator],
    icon: <ViewModuleIcon />,
  },
  {
    name: 'Facturación',
    url: '/admin/invoices',
    roles: [settings.roles.administrator],
    icon: <ReceiptLongIcon />,
  },
];
