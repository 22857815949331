import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableOptions, AvatarWithInfo, ModalConfirm } from '@components';
import { INotification, ITrip, IUseAssignTrip } from '@interfaces';
import { helpers } from '@helpers';
import { services } from '@services';
import { slices } from '@slices';
import { AssignTrip } from './assignTrip';
import { useAppDispatch } from 'src/store/hooks';

interface IProps {
  data: IUseAssignTrip;
  items: ITrip[];
  pendingConfiguration: boolean;
  notification: INotification;
}

export const Body = ({ data, items, pendingConfiguration, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const assignTrip = (item: ITrip) => {
    data.setIsAssigning(true);
    data.setTrip(item);
    data.setFront(null);
  };

  const deleteRequest = (item: ITrip) => {
    data.setIsDeleting(true);
    data.setTrip(item);
  };

  const close = () => {
    data.setIsAssigning(false);
    data.setIsDeleting(false);
  };

  const confirmDeleteRequest = async () => {
    if (data.trip) {
      data.setIsProcessing(true);
      await services.trip
        .del(controller.signal, data.trip.id)
        .then(() => {
          if (data.trip) {
            dispatch(slices.trips.endTrip(data.trip.id));
            notification.success('Se ha eliminado la solicitud de viaje');
            data.setIsDeleting(false);
          }
        })
        .catch(() => {
          notification.warning('No fue posible eliminar la solicitud de viaje');
        });
      data.setIsProcessing(false);
    }
  };

  const tableOptions = (item: ITrip) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Asignar viaje',
          onClick: pendingConfiguration ? undefined : () => assignTrip(item),
        },
        {
          title: 'Eliminar solicitud',
          onClick: () => deleteRequest(item),
        },
      ]}
    />
  );

  return (
    <>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id} hover>
            <TableCell>
              <AvatarWithInfo
                avatar={helpers.getAvatar(item.driver.avatar)}
                title={`${item.driver.first_name} ${item.driver.last_name}`}
                description={item.driver.phone}
              />
            </TableCell>
            <TableCell>
              <AvatarWithInfo
                title={item.truck.code}
                description={`${item.equipment_type.name} de ${item.settings.cage_number} jaulas`}
                tooltipDatetime={helpers.getTimeFromNow(item.truck.state_date)}
                tooltipTitle={item.truck.state.name}
                tooltipDescription={item.truck.state_description}
                tooltipIconColor={item.truck.state.color}
              />
            </TableCell>
            {tableOptions(item)}
          </TableRow>
        ))}
      </TableBody>
      {data.isAssigning && <AssignTrip data={data} close={close} notification={notification} />}
      {data.isDeleting && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, la solicitud de viaje se eliminará"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmDeleteRequest}
          secondaryAction={close}
          isProcessing={data.isProcessing}
        />
      )}
    </>
  );
};
