import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" width={100}>
          Frente
        </TableCell>
        <TableCell>Ubicación</TableCell>
        <TableCell align="center" width={100}>
          Vacíos
        </TableCell>
        <TableCell align="center" width={100}>
          Cargando
        </TableCell>
        <TableCell align="center" width={100}>
          Cargados
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
