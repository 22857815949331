import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Usuario</TableCell>
        <TableCell>Producto</TableCell>
        <TableCell align="center" width={55}>
          Cantidad
        </TableCell>
        <TableCell align="right" width={50}>
          Precio
        </TableCell>
        <TableCell align="center" width={65}>
          Acción
        </TableCell>
        <TableCell width={120}>Fecha</TableCell>
        <TableCell>Observaciones</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
