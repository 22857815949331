import { useState } from 'react';
import { IUseFront, IFront, IFarm, IUser } from '@interfaces';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

export const useFront = () => {
  const items = useAppSelector(slices.fronts.items);
  const [id, setId] = useState(0);
  const [identifier, setIdentifier] = useState('');
  const [boss, setBoss] = useState<IUser | null>(null);
  const [quota, setQuota] = useState('');
  const [farm, setFarm] = useState<IFarm | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: IFront) => {
    setId(item ? item.id : 0);
    setIdentifier(item ? item.identifier : '');
    setBoss(item ? item.boss : null);
    setQuota(item?.settings.quota ? item.settings.quota.toString() : '');
    setFarm(item ? item.farm : null);
  };

  const isValid = () => {
    if (identifier.trim() === '' || boss === null || farm === null || quota.trim() === '') {
      setIdentifier(identifier.trim());
      setQuota(quota.trim());
      return false;
    }
    return true;
  };

  const isDuplicated = () =>
    items.filter(
      (item) => item.identifier.toLowerCase() === identifier.trim().toLowerCase() && item.id !== id,
    ).length > 0;

  const object = () => {
    return {
      id: id,
      identifier: identifier.trim(),
      boss_id: boss?.id,
      quota: Number(quota.trim()),
      farm_id: farm?.id,
    };
  };

  const data: IUseFront = {
    id,
    identifier,
    boss,
    quota,
    farm,
    isCreating,
    isUpdating,
    isDeleting,
    isProcessing,
    setIdentifier,
    setBoss,
    setQuota,
    setFarm,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsProcessing,
    initialize,
    isValid,
    isDuplicated,
    object,
  };

  return data;
};
