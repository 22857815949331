/* eslint-disable camelcase */
import { settings } from '@constants';
import { IModule, IRole } from '@interfaces';
import jwt_decode from 'jwt-decode';

export const setJwt = (jwt: string) => {
  localStorage.setItem('KAFMY_JWT', jwt);
  localStorage.setItem('KAFMY_CURRENT_USER', JSON.stringify(jwt_decode(jwt)));
};

export const getJwt = () => {
  return localStorage.getItem('KAFMY_JWT');
};

const getCurrentUser = () => {
  return localStorage.getItem('KAFMY_CURRENT_USER');
};

export const isAuthenticated = () => {
  return getJwt() ? true : false;
};

export const deleteJwt = () => {
  localStorage.removeItem('KAFMY_JWT');
  localStorage.removeItem('KAFMY_CURRENT_USER');
};

export const getPropertyInJwt = (property: string) => {
  const jwt = getJwt();
  if (jwt !== null) {
    const decodedJwt: string = jwt_decode(jwt);
    return JSON.parse(JSON.stringify(decodedJwt))[property];
  }
  return '';
};

export const getPropertyInCurrentUser = (property: string) => {
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    return JSON.parse(currentUser)[property];
  }
  return '';
};

export const updateCurrentUser = (
  first_name: string,
  last_name: string,
  birthday: string,
  phone: string,
) => {
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    const data = JSON.parse(currentUser);
    data['first_name'] = first_name;
    data['last_name'] = last_name;
    data['birthday'] = birthday;
    data['phone'] = phone;
    localStorage.setItem('KAFMY_CURRENT_USER', JSON.stringify(data));
  }
};

export const updateCurrentUserState = (
  state_id: number,
  state_name: string,
  state_color: string,
  state_description: string,
  state_date: string,
) => {
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    const data = JSON.parse(currentUser);
    data['state_description'] = state_description;
    data['state_date'] = state_date;
    const state = data['state'];
    state['id'] = state_id;
    state['name'] = state_name;
    state['color'] = state_color;
    data['state'] = state;
    localStorage.setItem('KAFMY_CURRENT_USER', JSON.stringify(data));
  }
};

export const updateCurrentUserAvatar = (avatar: string) => {
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    const data = JSON.parse(currentUser);
    data['avatar'] = avatar;
    localStorage.setItem('KAFMY_CURRENT_USER', JSON.stringify(data));
  }
};

export const updateCurrentUserEnterpriseLogo = (logo: string) => {
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    const data = JSON.parse(currentUser);
    const enterprise = data['enterprise'];
    enterprise['logo'] = logo;
    data['enterprise'] = enterprise;
    localStorage.setItem('KAFMY_CURRENT_USER', JSON.stringify(data));
  }
};

export const hasRequiredRoles = (requiredRoles: string[]) => {
  let result = false;
  for (const role of requiredRoles) {
    if (getPropertyInCurrentUser('roles') && getPropertyInCurrentUser('roles').includes(role)) {
      result = true;
      break;
    }
  }
  return result;
};

export const moduleIsActive = (moduleId: number, modules: IModule[]) => {
  const activeModules = modules.filter((module) => module.id === moduleId && module.is_active);
  return activeModules.length > 0;
};

export const getActiveRoles = (roles: IRole[], modules: IModule[]) => {
  return roles.filter((role) => {
    if (role.internal_name === settings.roles.administrator) {
      return true;
    }
    if (role.internal_name === settings.roles.controller) {
      return moduleIsActive(settings.modules.transportManagement, modules);
    }
    if (role.internal_name === settings.roles.downloadCoordinator) {
      return moduleIsActive(settings.modules.transportManagement, modules);
    }
    if (role.internal_name === settings.roles.driver) {
      return moduleIsActive(settings.modules.transportManagement, modules);
    }
    if (role.internal_name === settings.roles.frontBoss) {
      return moduleIsActive(settings.modules.transportManagement, modules);
    }
    if (role.internal_name === settings.roles.resourceManager) {
      return moduleIsActive(settings.modules.transportManagement, modules);
    }
    if (role.internal_name === settings.roles.inventoryClerk) {
      return moduleIsActive(settings.modules.inventoryManagement, modules);
    }
  });
};

export const getHomePage = (modules: IModule[]) => {
  if (
    moduleIsActive(settings.modules.transportManagement, modules) &&
    hasRequiredRoles([settings.roles.administrator, settings.roles.controller])
  ) {
    return '/transport-management/indicators';
  }
  if (
    moduleIsActive(settings.modules.inventoryManagement, modules) &&
    hasRequiredRoles([settings.roles.administrator])
  ) {
    return '/inventory-management/indicators';
  }
  if (
    moduleIsActive(settings.modules.transportManagement, modules) &&
    hasRequiredRoles([settings.roles.driver])
  ) {
    return '/transport-management/drivers/current-trip';
  }
  if (
    moduleIsActive(settings.modules.transportManagement, modules) &&
    hasRequiredRoles([settings.roles.frontBoss])
  ) {
    return '/transport-management/fronts/current-state';
  }
  if (
    moduleIsActive(settings.modules.transportManagement, modules) &&
    hasRequiredRoles([settings.roles.resourceManager])
  ) {
    return '/transport-management/drivers';
  }
  if (
    moduleIsActive(settings.modules.inventoryManagement, modules) &&
    hasRequiredRoles([settings.roles.inventoryClerk])
  ) {
    return '/inventory-management/products';
  }
  if (
    moduleIsActive(settings.modules.transportManagement, modules) &&
    hasRequiredRoles([settings.roles.downloadCoordinator])
  ) {
    return '/transport-management/equipments/download';
  }
  return '';
};
