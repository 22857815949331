import { axiosInstance } from './axios';
import { api } from '@constants';
import { IEquipmentType } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.equipments}/types`, { signal: signal });
};

const create = (signal: AbortSignal, data: IEquipmentType) => {
  return axiosInstance.post(`${api.equipments}/types`, data, { signal: signal });
};

const update = (signal: AbortSignal, data: IEquipmentType) => {
  return axiosInstance.put(`${api.equipments}/types`, data, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.equipments}/types/${id}`, { signal: signal });
};

export const equipmentType = {
  all,
  create,
  update,
  del,
};
