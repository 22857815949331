import { axiosInstance } from './axios';
import { api } from '@constants';
import { INewTruck, IUpdateTruckState } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.trucks, { signal: signal });
};

const create = (signal: AbortSignal, truck: INewTruck) => {
  return axiosInstance.post(api.trucks, truck, { signal: signal });
};

const update = (signal: AbortSignal, truck: INewTruck) => {
  return axiosInstance.put(api.trucks, truck, { signal: signal });
};

const updateState = (signal: AbortSignal, data: IUpdateTruckState) => {
  return axiosInstance.put(`${api.trucks}/state`, data, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.trucks}/${id}`, { signal: signal });
};

export const truck = {
  all,
  create,
  update,
  updateState,
  del,
};
