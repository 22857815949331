import { useEffect } from 'react';
import { Wrapper, Title } from './styled';
import { Notification } from '@components';
import { hooks } from '@hooks';

export const Header = () => {
  const controller = new AbortController();
  const notification = hooks.useNotification();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Wrapper>
      <Title>Bitácora</Title>
      <Notification data={notification} />
    </Wrapper>
  );
};
