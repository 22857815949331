import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  padding-left: 5px;
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  padding-top: 14px;
  padding-left: 5px;
  color: ${design.colors.primary};
  margin-bottom: 15px;
  letter-spacing: 0.02857em;
`;

export const Content = styled.div`
  height: calc(100% - 98px);
  overflow: auto;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  margin-left: 5px;
  margin-right: 24px;
  background-color: white;
  box-shadow: ${design.shadows.default};
  @media (max-width: 600px) {
    margin-left: 3px;
    margin-right: 16px;
  }
`;

export const SkeletonWrapper = styled.div`
  height: calc(100% - 68px);
  overflow: auto;
  padding: 5px 24px 5px 5px;
  @media (max-width: 600px) {
    padding-left: 3px;
    padding-right: 16px;
  }
`;
