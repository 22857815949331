import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Wrapper, Title, Value } from './styled';

export const EquipmentPendingDownload = () => {
  const [approximateAmountOfCane, setApproximateAmountOfCane] = useState(0);
  const equipments = useAppSelector(slices.equipmentsDownload.items);

  useEffect(() => {
    let total = 0;
    equipments.forEach((equipment) => {
      total += equipment.settings.load_weight * equipment.settings.cage_number;
    });
    setApproximateAmountOfCane(total);
  }, [equipments]);

  return (
    <Wrapper>
      {equipments.length > 0 && (
        <Tooltip title={`${approximateAmountOfCane.toLocaleString()} toneladas`} arrow>
          <InfoIcon />
        </Tooltip>
      )}
      <Value>{equipments.length}</Value>
      <Title>Equipos pendientes de descarga</Title>
    </Wrapper>
  );
};
