import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IInvoice, IInvoiceState } from '@interfaces';

const initialState: IInvoiceState = {
  items: [],
  loaded: false,
  loading: false,
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IInvoice[]>) => {
      state.items = action.payload;
      state.loaded = true;
    },
  },
});

const { setLoading, setItems } = invoicesSlice.actions;
const loading = (state: RootState) => state.invoices.loading;
const loaded = (state: RootState) => state.invoices.loaded;
const items = (state: RootState) => state.invoices.items;

export const invoices = {
  setLoading,
  setItems,
  loading,
  loaded,
  items,
};

export default invoicesSlice.reducer;
