import { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Breakpoint } from '@mui/material';
import { design } from '@constants';

interface IProps {
  title: string | ReactNode;
  children: ReactNode;
  actions: ReactNode;
  close: () => void;
  dividers?: boolean;
  maxWidth: false | Breakpoint | undefined;
  dynamicBody?: boolean;
}

export const Modal = ({
  title,
  children,
  actions,
  close,
  dividers = false,
  maxWidth,
  dynamicBody = true,
}: IProps) => {
  return (
    <Dialog open={true} onClose={close} scroll="paper" fullWidth={true} maxWidth={maxWidth}>
      <DialogTitle style={{ color: design.colors.primary, fontWeight: 'bold' }}>
        {title}
      </DialogTitle>
      <DialogContent
        dividers={dividers}
        style={
          dynamicBody ? { maxHeight: 400, paddingBottom: 0 } : { height: 300, paddingBottom: 0 }
        }
      >
        <DialogContentText component="span" style={{ lineHeight: 'initial' }}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
