import { security } from './security';
import { enterprise } from './enterprise';
import { user } from './user';
import { role } from './role';
import { farm } from './farm';
import { front } from './front';
import { state } from './state';
import { truck } from './truck';
import { link } from './link';
import { trip } from './trip';
import { equipment } from './equipment';
import { brand } from './brand';
import { equipmentType } from './equipmentType';
import { module } from './module';
import { product } from './product';
import { inventoryManagement } from './inventoryManagement';

export const services = {
  security,
  enterprise,
  user,
  role,
  farm,
  front,
  state,
  truck,
  link,
  trip,
  equipment,
  brand,
  equipmentType,
  module,
  product,
  inventoryManagement,
};
