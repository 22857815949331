import { useEffect, useState } from 'react';
import { ModalConfirm } from '@components';
import { helpers } from '@helpers';
import { services } from '@services';
import { useNavigate } from 'react-router-dom';
import { INotification } from '@interfaces';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const Leave = ({ close, notification }: IProps) => {
  const navigate = useNavigate();
  const controller = new AbortController();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    setIsProcessing(true);
    await services.enterprise
      .leaveEnterprise(controller.signal)
      .then(() => {
        helpers.deleteJwt();
        navigate('/login?event=leaveEnterprise');
      })
      .catch(() => {
        notification.warning('No fue posible abandonar la empresa');
      });
    setIsProcessing(false);
  };

  return (
    <ModalConfirm
      title="Confirmación"
      content="Al confirmar esta acción, perderás el acceso a la información de la empresa"
      primaryText="Confirmar"
      secondaryText="Cancelar"
      primaryAction={next}
      secondaryAction={close}
      isProcessing={isProcessing}
    />
  );
};
