import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ITruckDemand, ITruckDemandState } from '@interfaces';

const initialState: ITruckDemandState = {
  items: [],
};

const truckDemandSlice = createSlice({
  name: 'truckDemand',
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<ITruckDemand[]>) => {
      state.items = action.payload;
    },
  },
});

const { setItems } = truckDemandSlice.actions;
const items = (state: RootState) => state.truckDemand.items;

export const truckDemand = {
  setItems,
  items,
};

export default truckDemandSlice.reducer;
