import { useEffect } from 'react';
import { Modal, ModalButtons, Skeleton, AvatarWithInfo } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { INotification, IUseTruckState, IState } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { OptionContainer } from './styled';

interface IProps {
  data: IUseTruckState;
  notification: INotification;
}

export const UpdateState = ({ data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const states = useAppSelector(slices.states.items);
  const loading = useAppSelector(slices.states.loading);
  const loaded = useAppSelector(slices.states.loaded);

  useEffect(() => {
    if (!loaded && !loading) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.states.setLoading(true));
    await services.state
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.states.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los estados');
      });
    dispatch(slices.states.setLoading(false));
  };

  const next = async () => {
    if (data.isValid()) {
      data.setIsProcessing(true);
      await services.truck
        .updateState(controller.signal, data.object())
        .then((response) => {
          dispatch(slices.trucks.updateItem(response.data));
          notification.success('Se actualizó el estado del camión');
        })
        .catch(() => {
          notification.warning('No fue posible actualizar el estado del camión');
        });
      data.setIsUpdating(false);
      data.setIsProcessing(false);
    } else {
      notification.warning('Selecciona el nuevo estado');
    }
  };

  const cancel = () => {
    data.setIsUpdating(false);
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`Estado del camión ${data.truck?.code}`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      {loading ? (
        <Skeleton variant="input" />
      ) : (
        <Autocomplete
          value={data.state}
          onChange={(_event: any, newValue: IState | null) => data.setState(newValue)}
          options={states.filter((item) => item.scope.name === 'Camiones')}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Estado"
              size="small"
              autoFocus
              sx={{ marginTop: '10px', marginBottom: '20px' }}
            />
          )}
          renderOption={(props, option) => (
            <OptionContainer {...props}>
              <AvatarWithInfo title={option.name} description={option.description} />
            </OptionContainer>
          )}
          noOptionsText="No hay opciones"
        />
      )}
      <TextField
        label="Descripción"
        value={data.stateDescription}
        onChange={(event) => data.setStateDescription(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        multiline
        minRows={4}
        maxRows={4}
        sx={{ marginBottom: '10px' }}
      />
    </Modal>
  );
};
