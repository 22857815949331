import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
  isProcessing?: boolean;
  primaryAction?: () => void;
  secondaryAction: () => void;
  primaryText?: string;
  secondaryText: string;
}

export const ModalButtons = ({
  isProcessing = false,
  primaryAction,
  secondaryAction,
  primaryText,
  secondaryText,
}: IProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {primaryText && (
          <Button
            onClick={secondaryAction}
            variant="outlined"
            size="large"
            fullWidth
            style={{ marginTop: 15 }}
          >
            {secondaryText}
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        {primaryText ? (
          <LoadingButton
            onClick={primaryAction}
            loading={isProcessing}
            variant="contained"
            size="large"
            fullWidth
            style={{ marginTop: 15 }}
          >
            {primaryText}
          </LoadingButton>
        ) : (
          <Button
            onClick={secondaryAction}
            variant="outlined"
            size="large"
            style={{ marginTop: 15, float: 'right' }}
          >
            {secondaryText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
