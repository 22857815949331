import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  text-align: center;
  padding: 50px 24px;
  margin: auto;
  .MuiGrid-item {
    display: flex;
  }
  @media (max-width: 599px) {
    padding: 50px 16px;
  }
`;

export const Title = styled.div`
  color: ${design.colors.darkGray};
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
  @media (max-width: 899px) {
    font-size: 25px;
  }
`;

export const Description = styled.div`
  color: ${design.colors.darkGray};
  font-size: 18px;
  margin-bottom: 60px;
  @media (max-width: 899px) {
    margin-bottom: 40px;
  }
`;

export const Option = styled.div`
  border: solid 2px white;
  box-shadow: ${design.shadows.default};
  background-color: white;
  text-align: center;
  padding: 100px 20px;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
  @media (max-width: 899px) {
    padding: 30px 10px;
  }
  :hover {
    border-color: ${design.colors.primary};
    svg {
      color: ${design.colors.primary};
      font-size: 100px;
      @media (max-width: 899px) {
        font-size: 60px;
      }
    }
  }
`;

export const Icon = styled.div`
  height: 100px;
  display: flex;
  @media (max-width: 899px) {
    height: 70px;
  }
  svg {
    margin: auto;
    transition: 0.2s;
    color: ${design.colors.darkGray};
    font-size: 90px;
    @media (max-width: 899px) {
      font-size: 50px;
    }
  }
`;

export const OptionTitle = styled.div`
  color: ${design.colors.primary};
  font-weight: bold;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const OptionDescription = styled.div`
  color: ${design.colors.darkGray};
  font-size: 18px;
`;
