import { useState as useReactState } from 'react';
import { IUseState, IState, IScope } from '@interfaces';
import { design } from '@constants';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

export const useState = () => {
  const items = useAppSelector(slices.states.items);
  const [id, setId] = useReactState(0);
  const [name, setName] = useReactState('');
  const [description, setDescription] = useReactState('');
  const [color, setColor] = useReactState(design.colors.darkGray);
  const [scope, setScope] = useReactState<IScope | null>(null);
  const [isCreating, setIsCreating] = useReactState(false);
  const [isUpdating, setIsUpdating] = useReactState(false);
  const [isDeleting, setIsDeleting] = useReactState(false);
  const [isProcessing, setIsProcessing] = useReactState(false);

  const initialize = (item?: IState) => {
    setId(item ? item.id : 0);
    setName(item ? item.name : '');
    setDescription(item ? item.description : '');
    setColor(item ? item.color : design.colors.darkGray);
    setScope(item ? item.scope : null);
  };

  const isValid = () => {
    if (name.trim() === '' || description.trim() === '' || scope === null) {
      setName(name.trim());
      setDescription(description.trim());
      return false;
    }
    return true;
  };

  const isDuplicated = () =>
    items.filter(
      (item) =>
        item.name.toLowerCase() === name.trim().toLowerCase() &&
        item.scope.id === scope?.id &&
        item.id !== id,
    ).length > 0;

  const object = () => {
    return {
      id: id,
      name: name.trim(),
      description: description.trim(),
      color: color,
      scope: scope?.id,
    };
  };

  const data: IUseState = {
    id,
    name,
    description,
    color,
    scope,
    isCreating,
    isUpdating,
    isDeleting,
    isProcessing,
    setName,
    setDescription,
    setColor,
    setScope,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsProcessing,
    initialize,
    isValid,
    isDuplicated,
    object,
  };

  return data;
};
