import styled from 'styled-components';
import MUIDivider from '@mui/material/Divider';

export const Wrapper = styled.div`
  float: right;
  .MuiBadge-dot {
    background-color: ${(props: any) => props.statecolor};
  }
`;

export const Divider = styled(MUIDivider)`
  margin-bottom: 20px !important;
`;
