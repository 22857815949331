import { useEffect } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import { INotification, IUseFrontRoute } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch } from 'src/store/hooks';

interface IProps {
  data: IUseFrontRoute;
  notification: INotification;
}

export const UpdateRoute = ({ data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    if (data.isValid()) {
      data.setIsProcessing(true);
      await services.front
        .updateRoute(controller.signal, data.object())
        .then((response) => {
          dispatch(slices.fronts.updateItem(response.data));
          notification.success('Se actualizó la ruta del frente');
        })
        .catch(() => {
          notification.warning('No fue posible actualizar la ruta del frente');
        });
      data.setIsUpdating(false);
      data.setIsProcessing(false);
    } else {
      notification.warning('Ingresa la nueva ruta');
    }
  };

  const cancel = () => {
    data.setIsUpdating(false);
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`Ruta del Frente ${data.front?.identifier}`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Ruta"
        value={data.route}
        onChange={(event) => data.setRoute(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        multiline
        minRows={4}
        maxRows={4}
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
    </Modal>
  );
};
