import { useEffect, useState } from 'react';
import { Wrapper, Title, OptionsWrapper } from './styled';
import { Searcher, Notification, Menu } from '@components';
import { services } from '@services';
import { slices } from '@slices';
import { hooks } from '@hooks';
import { INewProduct } from '@interfaces';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Form } from '../form';
import { UpdateExistence } from './updateExistence';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { helpers } from '@helpers';
import { settings } from '@constants';

export const Header = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.products.items);
  const searchCriteria = useAppSelector(slices.products.searchCriteria);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdatingExistence, setIsUpdatingExistence] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addProduct = () => {
    setIsCreating(true);
    handleClose();
  };

  const updateExistence = (input: boolean) => {
    setIsInput(input);
    setIsUpdatingExistence(true);
    handleClose();
  };

  const save = async (data: INewProduct) => {
    setIsProcessing(true);
    await services.product
      .create(controller.signal, data)
      .then((response) => {
        dispatch(slices.products.setItem(response.data));
        notification.success('Se agregó el nuevo producto');
      })
      .catch(() => {
        notification.warning('No fue posible agregar el nuevo producto');
      });
    setIsCreating(false);
    setIsProcessing(false);
  };

  const onSearch = (value: string) => {
    dispatch(slices.products.search(value));
  };

  return (
    <Wrapper>
      <Tooltip title="Opciones" placement="left" arrow>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} handleClose={handleClose}>
        <OptionsWrapper>
          {helpers.hasRequiredRoles([settings.roles.administrator]) && (
            <MenuItem onClick={addProduct}>Agregar nuevo producto</MenuItem>
          )}
          <MenuItem onClick={() => updateExistence(true)}>Entrada de producto</MenuItem>
          <MenuItem onClick={() => updateExistence(false)}>Salida de producto</MenuItem>
        </OptionsWrapper>
      </Menu>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={items.length === 0} />
      <Title>Productos</Title>
      {isCreating && (
        <Form
          save={save}
          cancel={() => setIsCreating(false)}
          isProcessing={isProcessing}
          notification={notification}
        />
      )}
      {isUpdatingExistence && (
        <UpdateExistence
          isInput={isInput}
          close={() => setIsUpdatingExistence(false)}
          notification={notification}
        />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
