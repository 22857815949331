import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IOrder, IBrand, IBrandList } from '@interfaces';
import { helpers } from '@helpers';

const searchFields = ['name'];

const initialState: IBrandList = {
  items: [],
  searchResults: [],
  searchCriteria: '',
  order: 'asc',
  orderBy: '',
  loaded: false,
  loading: false,
};

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItem: (state, action: PayloadAction<IBrand>) => {
      const newList = [...state.items, action.payload];
      const field = state.orderBy === '' ? 'name' : state.orderBy;
      const orderedList = helpers.orderList(newList, field, state.order);
      state.items = orderedList;
      if (state.searchCriteria !== '') {
        state.searchResults = helpers.search(state.searchCriteria, orderedList, searchFields);
      }
    },
    setItems: (state, action: PayloadAction<IBrand[]>) => {
      state.items = action.payload;
      state.loaded = true;
    },
    updateItem: (state, action: PayloadAction<IBrand>) => {
      const newList = state.items.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );
      const field = state.orderBy === '' ? 'name' : state.orderBy;
      const orderedList = helpers.orderList(newList, field, state.order);
      state.items = orderedList;
      if (state.searchCriteria !== '') {
        state.searchResults = helpers.search(state.searchCriteria, orderedList, searchFields);
      }
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item: IBrand) => item.id !== action.payload);
      if (state.searchCriteria !== '') {
        state.searchResults = state.searchResults.filter(
          (item: IBrand) => item.id !== action.payload,
        );
      }
    },
    search: (state, action: PayloadAction<string>) => {
      state.searchCriteria = action.payload;
      const field = state.orderBy === '' ? 'name' : state.orderBy;
      if (action.payload === '') {
        state.items = helpers.orderList(state.items, field, state.order);
      } else {
        const newList = helpers.search(action.payload, state.items, searchFields);
        state.searchResults = helpers.orderList(newList, field, state.order);
      }
    },
    setOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    sort: (state, action: PayloadAction<IOrder>) => {
      if (state.searchCriteria === '') {
        state.items = helpers.orderList(state.items, action.payload.field, action.payload.order);
      } else {
        state.searchResults = helpers.orderList(
          state.searchResults,
          action.payload.field,
          action.payload.order,
        );
      }
    },
  },
});

const {
  setLoading,
  setItem,
  setItems,
  updateItem,
  deleteItem,
  search,
  setOrder,
  setOrderBy,
  sort,
} = brandsSlice.actions;
const items = (state: RootState) => state.brands.items;
const searchResults = (state: RootState) => state.brands.searchResults;
const searchCriteria = (state: RootState) => state.brands.searchCriteria;
const order = (state: RootState) => state.brands.order;
const orderBy = (state: RootState) => state.brands.orderBy;
const loaded = (state: RootState) => state.brands.loaded;
const loading = (state: RootState) => state.brands.loading;

export const brands = {
  setLoading,
  setItem,
  setItems,
  updateItem,
  deleteItem,
  search,
  setOrder,
  setOrderBy,
  sort,
  items,
  searchResults,
  searchCriteria,
  order,
  orderBy,
  loaded,
  loading,
};

export default brandsSlice.reducer;
