import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  ${(props: any) =>
    css`
      height: calc(${props.height}px - 64px);
    `}
  @media (max-width: 599px) {
    ${(props: any) =>
      css`
        height: calc(${props.height}px - 56px);
      `}
  }
`;
