import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 73px 20px 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
  svg {
    color: ${design.colors.darkGray};
    position: absolute;
    right: 20px;
    top: 34px;
    transition: 0.2s;
    :hover {
      color: ${design.colors.primary};
    }
  }
`;

export const Title = styled.div`
  padding-top: 68px;
  color: ${design.colors.darkGray};
`;

export const Value = styled.div`
  font-size: 80px;
  font-weight: bold;
  color: ${design.colors.primary};
`;
