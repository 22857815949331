import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { PieChart } from '@mui/x-charts/PieChart';
import { Wrapper, Title } from './styled';
import { design } from '@constants';

interface ICountByState {
  label: string;
  value: number;
}

export const FrontsByState = () => {
  const [data, setData] = useState<ICountByState[]>([]);
  const fronts = useAppSelector(slices.fronts.items);

  useEffect(() => {
    const count: { [key: string]: number } = {};
    fronts.forEach((front) => {
      if (front.state.name in count) {
        count[front.state.name]++;
      } else {
        count[front.state.name] = 1;
      }
    });
    const countingList = Object.keys(count).map((item) => ({ label: item, value: count[item] }));
    countingList.sort((a, b) => a.label.localeCompare(b.label));
    setData(countingList);
  }, [fronts]);

  return (
    <Wrapper>
      <PieChart
        series={[
          {
            data: data,
            valueFormatter: (item) => `${item.value} ${item.value === 1 ? 'frente' : 'frentes'}`,
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -90,
          },
        ]}
        slotProps={{ legend: { hidden: true } }}
        colors={design.colors.pallette}
        margin={{ right: 0 }}
        height={200}
      />
      <Title>Frentes por estado</Title>
    </Wrapper>
  );
};
