import { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableOptions, AvatarWithInfo, ModalConfirm } from '@components';
import { INotification, ITripForDownload } from '@interfaces';
import { TimeIndicator } from '../../../currentTrip/detail/timeIndicator';
import { slices } from '@slices';
import { services } from '@services';
import { useAppDispatch } from 'src/store/hooks';

interface IProps {
  items: ITripForDownload[];
  notification: INotification;
}

export const Body = ({ items, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [trip, setTrip] = useState<ITripForDownload>();
  const [isNotifying, setIsNotifying] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const confirmNotification = (currentTrip: ITripForDownload) => {
    setTrip(currentTrip);
    setIsNotifying(true);
  };

  const notify = async () => {
    setIsProcessing(true);
    await services.trip
      .notify(controller.signal, trip?.id || 0, 'notify-download')
      .then(() => {
        dispatch(slices.equipmentsDownload.del(trip?.id || 0));
        notification.success('Hemos notificado la descarga de este equipo');
      })
      .catch(() => {
        notification.warning('No fue posible notificar la descarga de este equipo');
      });
    setIsNotifying(false);
    setIsProcessing(false);
  };

  const tableOptions = (item: ITripForDownload) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Notificar descarga',
          onClick: confirmNotification,
        },
      ]}
    />
  );

  return (
    <>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id} hover>
            <TableCell>
              <AvatarWithInfo
                title={item.equipment.code}
                description={`${item.equipment_type.name} de ${item.settings.cage_number} jaulas`}
              />
            </TableCell>
            <TableCell>
              <AvatarWithInfo
                title={`Frente ${item.front?.identifier}`}
                description={`Finca ${item.farm?.name}`}
              />
            </TableCell>
            <TableCell>
              <TimeIndicator
                settings={item.settings}
                isWaiting={item.download_driver ? false : true}
                truck={item.download_truck}
                driver={
                  item.download_driver
                    ? `${item.download_driver.first_name} ${item.download_driver.last_name}`
                    : undefined
                }
                titleSize={10}
                indicatorSize={40}
                variant="Horizontal"
              />
            </TableCell>
            {tableOptions(item)}
          </TableRow>
        ))}
      </TableBody>
      {isNotifying && (
        <ModalConfirm
          title="Confirmación"
          content={`¿ Deseas notificar la descarga de este equipo ?`}
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={notify}
          secondaryAction={() => setIsNotifying(false)}
          isProcessing={isProcessing}
        />
      )}
    </>
  );
};
