import styled from 'styled-components';
import { design } from '@constants';

export const ModalHeader = styled.div`
  > div + div {
    padding-top: 10px;
    padding-right: 5px;
  }
`;

export const ModalTitle = styled.div`
  font-size: 1.25rem;
`;

export const SearcherWrapper = styled.div`
  padding-right: 15px;
  > .MuiTextField-root {
    width: 100% !important;
    margin-bottom: 10px;
  }
  > .MuiChip-root {
    width: 100%;
    background: ${design.colors.lightPrimary};
    color: ${design.colors.primary};
    height: auto;
    span {
      font-size: 12px !important;
      padding: 3px;
    }
  }
`;

export const Information = styled.div`
  background-color: ${design.colors.lightPrimary};
  color: ${design.colors.primary};
  padding: 5px 10px !important;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
`;
