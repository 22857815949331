import styled, { css } from 'styled-components';
import { design } from '@constants';

export const Cost = styled.div`
  > div {
    display: block;
  }
`;

export const Price = styled.div`
  > div {
    display: block;
  }
`;

interface IStockProps {
  low: string;
}

export const Stock = styled.div`
  border-radius: 50px;
  padding: 5px;
  margin-left: 25px;
  text-align: center;
  font-weight: bold;
  ${(props: IStockProps) =>
    props.low === 'true'
      ? css`
          color: ${design.colors.orange};
          background-color: ${design.colors.lightOrange};
        `
      : css`
          color: ${design.colors.green};
          background-color: ${design.colors.lightGreen};
        `}
`;

export const MinimumExistence = styled.div`
  margin-left: 25px;
  text-align: center;
`;
