import { useEffect } from 'react';
import { AvatarWithInfo, Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IEquipmentType, INotification, ITruck, IUseTrip } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { helpers } from '@helpers';
import { OptionContainer } from './styled';

interface IProps {
  data: IUseTrip;
  notification: INotification;
}

export const RequestNewTrip = ({ data, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const trucks = useAppSelector(slices.trucks.items);
  const loadingTrucks = useAppSelector(slices.trucks.loading);
  const loadedTrucks = useAppSelector(slices.trucks.loaded);
  const equipmentTypes = useAppSelector(slices.equipmentTypes.items);
  const loadingEquipmentTypes = useAppSelector(slices.equipmentTypes.loading);
  const loadedEquipmentTypes = useAppSelector(slices.equipmentTypes.loaded);

  useEffect(() => {
    if (!loadedTrucks && !loadingTrucks) {
      getTrucks();
    }
    if (!loadedEquipmentTypes && !loadingEquipmentTypes) {
      getEquipmentTypes();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getTrucks = async () => {
    dispatch(slices.trucks.setLoading(true));
    await services.truck
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trucks.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los camiones');
      });
    dispatch(slices.trucks.setLoading(false));
  };

  const getEquipmentTypes = async () => {
    dispatch(slices.equipmentTypes.setLoading(true));
    await services.equipmentType
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.equipmentTypes.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los tipos');
      });
    dispatch(slices.equipmentTypes.setLoading(false));
  };

  const next = async () => {
    if (data.isValid()) {
      data.setIsProcessing(true);
      await services.trip
        .request(controller.signal, data.object())
        .then((response) => {
          dispatch(slices.trip.setItem(response.data));
          notification.success('Se ha solicitado un nuevo viaje');
          data.setIsRequesting(false);
        })
        .catch((error) => {
          switch (error.response.data.detail) {
            case 'The truck code entered does not exist':
              notification.warning('El código de camión no existe');
              break;
            case 'This truck has been assigned to another driver':
              notification.warning('Este camión ya tiene un viaje asignado');
              break;
            default:
              notification.warning('No fue posible solicitar un nuevo viaje');
              break;
          }
        });
      data.setIsProcessing(false);
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={() => data.setIsRequesting(false)}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title="Solicitar viaje"
      close={() => data.setIsRequesting(false)}
      actions={buttons}
      maxWidth="xs"
    >
      <Autocomplete
        value={data.truck}
        onChange={(_event: any, newValue: ITruck | null) => data.setTruck(newValue)}
        options={trucks}
        getOptionLabel={(option) => `${option.code} ${option.brand.name} ${option.model}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camión"
            size="small"
            autoFocus
            sx={{ marginBottom: '20px', marginTop: '10px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              title={option.code}
              description={`${option.brand.name} ${option.model}`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
      <Autocomplete
        value={data.equipmentType}
        onChange={(_event: any, newValue: IEquipmentType | null) => data.setEquipmentType(newValue)}
        options={equipmentTypes}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tipo de equipo"
            size="small"
            sx={{ marginBottom: '20px' }}
          />
        )}
        noOptionsText="No hay opciones"
      />
      <TextField
        label="Cantidad de jaulas"
        value={data.cageNumber}
        onChange={(event) =>
          data.setCageNumber(helpers.validateNumber(data.cageNumber, event.target.value, false))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
    </Modal>
  );
};
