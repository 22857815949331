import styled from 'styled-components';
import { design } from '@constants';
import IconButton from '@mui/material/IconButton';

export const Wrapper = styled.div`
  header {
    box-shadow: ${design.shadows.default};
    position: relative;
  }
  @media (max-width: 600px) {
    header > div > div > div > img {
      height: 35px !important;
    }
    button {
      padding: 5px;
    }
    svg,
    .MuiAvatar-circular {
      width: 25px;
      height: 25px;
    }
  }
`;

export const HideShowMenu = styled(IconButton)`
  margin-left: 10px !important;
  float: left;
`;
