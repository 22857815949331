import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IRole, IRoleList } from '@interfaces';

const initialState: IRoleList = {
  items: [],
  loaded: false,
  loading: false,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IRole[]>) => {
      state.items = action.payload;
      state.loaded = true;
    },
    clear: (state) => {
      state.items = [];
    },
  },
});

const { setLoading, setItems, clear } = rolesSlice.actions;
const items = (state: RootState) => state.roles.items;
const loaded = (state: RootState) => state.roles.loaded;
const loading = (state: RootState) => state.roles.loading;

export const roles = {
  setLoading,
  setItems,
  clear,
  items,
  loaded,
  loading,
};

export default rolesSlice.reducer;
