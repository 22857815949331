import { useEffect } from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import Chip from '@mui/material/Chip';
import { Wrapper, Title, List, Item, SearcherWrapper } from './styled';
import { Skeleton, WithoutData, Notification, AvatarWithInfo, Searcher } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { IUser } from '@interfaces';
import { helpers } from '@helpers';

export const ContactList = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.authorizedUsers.items);
  const searchResults = useAppSelector(slices.authorizedUsers.searchResults);
  const searchCriteria = useAppSelector(slices.authorizedUsers.searchCriteria);
  const loading = useAppSelector(slices.authorizedUsers.loading);
  const loaded = useAppSelector(slices.authorizedUsers.loaded);

  useEffect(() => {
    if (!loaded && !loading) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.authorizedUsers.setLoading(true));
    await services.user
      .authorized(controller.signal)
      .then((response) => {
        dispatch(slices.authorizedUsers.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los usuarios');
      });
    dispatch(slices.authorizedUsers.setLoading(false));
  };

  const onSearch = (value: string) => {
    dispatch(slices.authorizedUsers.search(value));
  };

  const getContent = () => (
    <>
      {loading && <Skeleton variant="list" />}
      {!loading &&
        (searchCriteria === '' ? (
          items.length ? (
            buildList(items)
          ) : (
            <WithoutData
              icon={<GroupsIcon />}
              title="No hay usuarios"
              description="En esta sección se mostrarán los usuarios que han sido agregados"
              size="small"
            />
          )
        ) : searchResults.length ? (
          buildList(searchResults)
        ) : (
          <WithoutData
            icon={<GroupsIcon />}
            title="No hay coincidencias"
            description="En esta sección se mostrarán los usuarios que coincidan con los criterios de búsqueda"
            size="small"
          />
        ))}
    </>
  );

  const buildList = (dataList: IUser[]) =>
    dataList.map((item, index) => (
      <Item key={index}>
        <AvatarWithInfo
          avatar={helpers.getAvatar(item.avatar)}
          title={`${item.first_name} ${item.last_name}`}
          description={item.phone}
          tooltipDatetime={helpers.getTimeFromNow(item.state_date)}
          tooltipTitle={item.state.name}
          tooltipDescription={item.state_description}
          tooltipIconColor={item.state.color}
        />
      </Item>
    ));

  const getTotalItems = () => {
    let totalItems = 0;
    if (searchCriteria === '') {
      totalItems = items.length;
    } else {
      totalItems = searchResults.length;
    }
    return (
      totalItems > 0 && (
        <Chip
          label={`${totalItems} ${
            totalItems === 1 ? 'usuario encontrado' : 'usuarios encontrados'
          }`}
          size="small"
        />
      )
    );
  };

  return (
    <Wrapper>
      <Title>Contactos</Title>
      {items.length > 0 && (
        <SearcherWrapper>
          <Searcher value={searchCriteria} onSearch={onSearch} />
          {getTotalItems()}
        </SearcherWrapper>
      )}
      <List>{getContent()}</List>
      <Notification data={notification} />
    </Wrapper>
  );
};
