import { ReactNode } from 'react';
import { Wrapper, Container, MenuWrapper, Page } from './styled';
import { Menu } from './menu';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { hooks } from '@hooks';

interface IProps {
  children: ReactNode;
}

export const Content = ({ children }: IProps) => {
  const screenHeight = hooks.useScreenHeight();
  const openMenu = useAppSelector(slices.settings.openMenu);

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <MenuWrapper item openmenu={String(openMenu)}>
          <Menu />
        </MenuWrapper>
        <Page item openmenu={String(openMenu)}>
          <Container height={screenHeight}>{children}</Container>
        </Page>
      </Grid>
    </Wrapper>
  );
};
