import { useState } from 'react';
import { IUseFrontRoute, IFront } from '@interfaces';

export const useFrontRoute = () => {
  const [front, setFront] = useState<IFront | null>(null);
  const [route, setRoute] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: IFront) => {
    setFront(item || null);
    setRoute(item?.settings.route || '');
  };

  const isValid = () => {
    if (route.trim() === '') {
      setRoute(route.trim());
      return false;
    }
    return true;
  };

  const object = () => {
    return {
      front: front?.id,
      route: route.trim(),
    };
  };

  const data: IUseFrontRoute = {
    front,
    route,
    isUpdating,
    isProcessing,
    setRoute,
    setIsUpdating,
    setIsProcessing,
    initialize,
    isValid,
    object,
  };

  return data;
};
