import styled, { css } from 'styled-components';
import { design } from '@constants';

interface IDataProps {
  align: 'right' | 'center';
  background: string;
  color: string;
  hasDetail: boolean;
  forceHeight: boolean;
}

export const Wrapper = styled.div`
  height: calc(100% - 93px);
  background-color: white;
  padding: 20px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  th,
  td {
    border: none;
    padding: 6px;
  }
  tbody > tr > td {
    position: relative;
  }
  tbody > tr:hover > td > div {
    background-color: ${design.colors.lightGray};
  }
`;

export const Data = styled.div`
  padding: 10px 15px;
  border-radius: 10px;
  transition: 300ms;
  color: ${(props: IDataProps) => props.color || design.colors.darkGray};
  text-align: ${(props: IDataProps) => props.align || 'left'};
  ${(props: IDataProps) =>
    props.background
      ? css`
          background-color: ${props.background} !important;
        `
      : css`
          background-color: ${design.colors.ultralightGray};
        `};
  ${(props: IDataProps) =>
    props.forceHeight &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;
    `}
  :hover {
    ${(props: IDataProps) =>
      props.hasDetail &&
      css`
        cursor: pointer;
        color: ${design.colors.primary};
        background-color: ${design.colors.mediumPrimary} !important;
      `}
  }
`;
