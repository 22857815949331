import { axiosInstance } from './axios';
import { api } from '@constants';

const getAllRoles = (signal: AbortSignal) => {
  return axiosInstance.get(api.roles, { signal: signal });
};

const assignRoles = (signal: AbortSignal, user: number, roles: string[]) => {
  const formData = new FormData();
  formData.append('user', String(user));
  formData.append('roles', String(roles));
  return axiosInstance.post(`${api.roles}/assign-roles`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const role = {
  getAllRoles,
  assignRoles,
};
