import { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { ModalConfirm, TableOptions } from '@components';
import { INotification, IModule } from '@interfaces';
import { useAppDispatch } from 'src/store/hooks';
import { services } from '@services';
import { slices } from '@slices';
import { design } from '@constants';

interface IProps {
  items: IModule[];
  notification: INotification;
}

export const Body = ({ items, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [isInactivating, setIsInactivating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedModule, setSelectedModule] = useState<null | IModule>(null);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onInactivating = (value: IModule) => {
    if (items.length === 1) {
      notification.warning('No es posible inactivar todos los módulos');
    } else {
      setSelectedModule(value);
      setIsInactivating(true);
    }
  };

  const confirmInactivating = async () => {
    setIsProcessing(true);
    await services.module
      .inactivate(controller.signal, selectedModule?.id || 0)
      .then((response) => {
        dispatch(slices.modules.update([response.data]));
        notification.success('Se inactivó el módulo seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible inactivar el módulo seleccionado');
      });
    setIsInactivating(false);
    setIsProcessing(false);
  };

  const cancel = () => {
    setIsInactivating(false);
    setIsProcessing(false);
  };

  const tableOptions = (item: IModule) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Eliminar',
          onClick: onInactivating,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.description}</TableCell>
          <TableCell align="right">
            {item.price} USD
            <Tooltip title="Precio por usuario al mes" placement="right" arrow>
              <InfoIcon
                sx={{ fontSize: 14 }}
                htmlColor={design.colors.darkGray}
                style={{ marginLeft: 5, marginBottom: -2 }}
              />
            </Tooltip>
          </TableCell>
          <TableCell align="right">{item.discount} %</TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {isInactivating && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se inactivará el módulo seleccionado"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={confirmInactivating}
          secondaryAction={cancel}
          isProcessing={isProcessing}
        />
      )}
    </TableBody>
  );
};
