import styled from 'styled-components';
import { design } from '@constants';

export const Container = styled.div`
  background-color: white;
  height: calc(100% - 88px);
  padding: 20px 20px 15px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  overflow: auto;
`;
