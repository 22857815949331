import { Wrapper, Content, Title, Subtitle } from './styled';

interface IProps {
  icon: any;
  title: string;
  description: string;
  size?: 'large' | 'medium' | 'small';
}

export const WithoutData = ({ icon, title, description, size = 'large' }: IProps) => {
  return (
    <Wrapper>
      <Content size={size}>
        {icon}
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
      </Content>
    </Wrapper>
  );
};
