import { useEffect, useState } from 'react';
import { ChipWrapper, Container } from './styled';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Chip from '@mui/material/Chip';
import { Skeleton, WithoutData, Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Header } from './header';
import { Body } from './body';
import { IModule } from '@interfaces';

export const Table = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const [activeModules, setActiveModules] = useState<IModule[]>([]);
  const modules = useAppSelector(slices.modules.items);
  const loading = useAppSelector(slices.modules.loading);
  const loaded = useAppSelector(slices.modules.loaded);

  useEffect(() => {
    if (!loaded && !loading) getItems();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setActiveModules(modules.filter((item) => item.is_active));
  }, [modules]);

  const getItems = async () => {
    dispatch(slices.modules.setLoading(true));
    await services.module
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.modules.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los modulos activos');
      });
    dispatch(slices.modules.setLoading(false));
  };

  return (
    <>
      <ChipWrapper>
        <Chip
          label={`${activeModules.length} ${
            activeModules.length === 1 ? 'módulo encontrado' : 'módulos encontrados'
          }`}
          size="small"
        />
      </ChipWrapper>
      <Container>
        {loading ? (
          <Skeleton variant="table" />
        ) : activeModules.length ? (
          <TableContainer>
            <MUITable size="small" stickyHeader sx={{ minWidth: 800 }}>
              <Header />
              <Body items={activeModules} notification={notification} />
            </MUITable>
          </TableContainer>
        ) : (
          <WithoutData
            icon={<ViewModuleIcon />}
            title="No hay módulos activos"
            description="En esta sección se mostrarán los módulos que han sido activados"
          />
        )}
        <Notification data={notification} />
      </Container>
    </>
  );
};
