import { useEffect, useState } from 'react';
import { Modal, ModalButtons } from '@components';
import { helpers } from '@helpers';
import { services } from '@services';
import TextField from '@mui/material/TextField';
import { MuiTelInput } from 'mui-tel-input';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { INotification } from '@interfaces';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const Update = ({ close, notification }: IProps) => {
  const controller = new AbortController();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState<any>(null);
  const [phone, setPhone] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setFirstName(helpers.getPropertyInCurrentUser('first_name'));
    setLastName(helpers.getPropertyInCurrentUser('last_name'));
    setPhone(helpers.getPropertyInCurrentUser('phone'));
    setBirthday(moment(helpers.getPropertyInCurrentUser('birthday')));
    return () => {
      controller.abort();
    };
  }, []);

  const save = async () => {
    if (
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      birthday === null ||
      phone.trim() === ''
    ) {
      notification.warning('Completa todos los campos para continuar');
    } else {
      setIsProcessing(true);
      await services.user
        .update(controller.signal, firstName.trim(), lastName.trim(), birthday, phone.trim())
        .then(() => {
          helpers.updateCurrentUser(firstName.trim(), lastName.trim(), birthday, phone.trim());
          notification.success('Tu información ha sido actualizada');
          close();
        })
        .catch(() => {
          notification.warning('No fue posible actualizar tu información');
        });
      setIsProcessing(false);
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={save}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Información personal" close={close} actions={buttons} maxWidth="xs">
      <TextField
        label="Nombre"
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <TextField
        label="Apellido"
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Fecha de nacimiento"
          value={birthday}
          onChange={(value) => setBirthday(value)}
          slotProps={{ textField: { size: 'small' } }}
          maxDate={moment()}
          format="DD/MM/YYYY"
          sx={{ marginBottom: '20px', width: '100%' }}
        />
      </LocalizationProvider>
      <MuiTelInput
        label="Teléfono"
        value={phone}
        onChange={(value) => setPhone(value)}
        size="small"
        defaultCountry="GT"
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
    </Modal>
  );
};
