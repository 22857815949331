import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  button {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  height: calc(100% - 68px);
  overflow: auto;
  padding: 5px 24px 5px 5px;
  @media (max-width: 600px) {
    padding-left: 3px;
    padding-right: 11px;
  }
`;

export const Container = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${design.colors.primary};
`;

export const Subtitle = styled.div`
  padding-top: 10px;
  color: ${design.colors.darkGray};
`;
