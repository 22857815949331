import { useState } from 'react';
import { IUseTruckState, ITruck, IState } from '@interfaces';

export const useTruckState = () => {
  const [truck, setTruck] = useState<ITruck | null>(null);
  const [state, setNewState] = useState<IState | null>(null);
  const [stateDescription, setStateDescription] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: ITruck) => {
    setTruck(item ? item : null);
    setNewState(item ? item.state : null);
    setStateDescription(item ? item.state_description : '');
  };

  const isValid = () => {
    if (state === null) {
      setStateDescription(stateDescription.trim());
      return false;
    }
    return true;
  };

  const object = () => {
    return {
      truck: truck?.id,
      state: state?.id,
      state_description: stateDescription.trim(),
    };
  };

  const setState = (value: IState | null) => {
    setNewState(value);
    setStateDescription('');
  };

  const data: IUseTruckState = {
    truck,
    state,
    stateDescription,
    isUpdating,
    isProcessing,
    setState,
    setStateDescription,
    setIsUpdating,
    setIsProcessing,
    initialize,
    isValid,
    object,
  };

  return data;
};
