import { pendingUsers } from './pendingUsers';
import { authorizedUsers } from './authorizedUsers';
import { roles } from './roles';
import { farms } from './farms';
import { fronts } from './fronts';
import { states } from './states';
import { trucks } from './trucks';
import { settings } from './settings';
import { links } from './links';
import { trip } from './trip';
import { trips } from './trips';
import { enterpriseSettings } from './enterpriseSettings';
import { equipments } from './equipments';
import { equipmentsDownload } from './equipmentsDownload';
import { brands } from './brands';
import { equipmentTypes } from './equipmentTypes';
import { frontCompliance } from './frontCompliance';
import { front } from './front';
import { truckDemand } from './truckDemand';
import { indicators } from './indicators';
import { modules } from './modules';
import { invoices } from './invoices';
import { products } from './products';
import { inventoryManagement } from './inventoryManagement';

export const slices = {
  pendingUsers,
  authorizedUsers,
  roles,
  farms,
  fronts,
  states,
  trucks,
  settings,
  links,
  trip,
  trips,
  enterpriseSettings,
  equipments,
  equipmentsDownload,
  brands,
  equipmentTypes,
  frontCompliance,
  front,
  truckDemand,
  indicators,
  modules,
  invoices,
  products,
  inventoryManagement,
};
