import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IEnterpriseSettings, IEnterpriseSettingsState } from '@interfaces';

const initialState: IEnterpriseSettingsState = {
  settings: {
    average_speed: 0,
    harvest_day_start_time: null,
  },
  loaded: false,
  loading: false,
};

const enterpriseSettingsSlice = createSlice({
  name: 'enterpriseSettings',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSettings: (state, action: PayloadAction<IEnterpriseSettings>) => {
      state.settings = action.payload;
      state.loaded = true;
    },
  },
});

const { setLoading, setSettings } = enterpriseSettingsSlice.actions;
const loading = (state: RootState) => state.enterpriseSettings.loading;
const loaded = (state: RootState) => state.enterpriseSettings.loaded;
const settings = (state: RootState) => state.enterpriseSettings.settings;

export const enterpriseSettings = {
  setLoading,
  setSettings,
  loading,
  loaded,
  settings,
};

export default enterpriseSettingsSlice.reducer;
