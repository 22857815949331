import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Fecha</TableCell>
        <TableCell align="center">Módulos</TableCell>
        <TableCell align="right">Total</TableCell>
        <TableCell align="center">Estado</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
