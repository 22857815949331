import { useEffect, useState } from 'react';
import { IFront, ITruckDemand } from '@interfaces';
import { slices } from '@slices';
import { useAppSelector } from 'src/store/hooks';
import { Data } from './../styled';

interface IProps {
  item: IFront;
}

export const Identifier = ({ item }: IProps) => {
  const truckDemand = useAppSelector(slices.truckDemand.items);
  const [data, setData] = useState<ITruckDemand>();

  useEffect(() => {
    truckDemand.forEach((element) => {
      if (element.identifier === item.identifier) {
        setData(element);
      }
    });
  }, [truckDemand]);

  return (
    <Data align="center" background={data?.background} color={data?.color} forceHeight={true}>
      {data?.identifier}
    </Data>
  );
};
