import { design } from '@constants';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 500px;
  text-align: center;
  svg {
    background-color: ${design.colors.lightPrimary};
    color: ${design.colors.primary};
    border-radius: 50%;
  }
  ${(props: any) =>
    props.size === 'small' &&
    css`
      padding: 25px 0;
      svg {
        font-size: 40px;
        padding: 10px;
      }
      ${Title} {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    `}
  ${(props: any) =>
    props.size === 'medium' &&
    css`
      padding: 40px 0;
      svg {
        font-size: 60px;
        padding: 20px;
      }
      ${Title} {
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      ${Subtitle} {
        font-size: 16px;
      }
    `}
  ${(props: any) =>
    props.size === 'large' &&
    css`
      padding: 80px 0;
      svg {
        font-size: 80px;
        padding: 20px;
      }
      ${Title} {
        font-size: 25px;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      ${Subtitle} {
        font-size: 18px;
      }
    `}
`;

export const Title = styled.div`
  color: ${design.colors.darkGray};
  font-weight: bold;
`;

export const Subtitle = styled.div`
  color: ${design.colors.darkGray};
  opacity: 0.8;
  line-height: 1.5;
`;
