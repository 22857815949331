import { axiosInstance } from './axios';
import { api } from '@constants';
import { INewFront } from '@interfaces';
import { IUpdateFrontRoute, IUpdateFrontState, IUpdateFrontSettings } from 'src/interfaces/front';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.fronts, { signal: signal });
};

const create = (signal: AbortSignal, front: INewFront) => {
  return axiosInstance.post(api.fronts, front, { signal: signal });
};

const update = (signal: AbortSignal, front: INewFront) => {
  return axiosInstance.put(api.fronts, front, { signal: signal });
};

const updateRoute = (signal: AbortSignal, data: IUpdateFrontRoute) => {
  return axiosInstance.put(`${api.fronts}/route`, data, { signal: signal });
};

const updateState = (signal: AbortSignal, data: IUpdateFrontState) => {
  return axiosInstance.put(`${api.fronts}/state`, data, { signal: signal });
};

const updateSettings = (signal: AbortSignal, data: IUpdateFrontSettings) => {
  return axiosInstance.put(`${api.fronts}/settings`, data, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.fronts}/${id}`, { signal: signal });
};

const compliance = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.fronts}/compliance`, { signal: signal });
};

const detail = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.fronts}/detail`, { signal: signal });
};

export const front = {
  all,
  create,
  update,
  updateRoute,
  updateState,
  updateSettings,
  del,
  compliance,
  detail,
};
