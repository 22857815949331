import { axiosInstance } from './axios';
import { api } from '@constants';
import { IUpdateUserState } from '@interfaces';

const authorized = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.users}/authorized`, { signal: signal });
};

const pending = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.users}/pending`, { signal: signal });
};

const deleteAccess = (signal: AbortSignal, user: number) => {
  const formData = new FormData();
  formData.append('user', String(user));
  return axiosInstance.post(`${api.users}/delete-access`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const changeAvatar = (signal: AbortSignal, avatar: any) => {
  const formData = new FormData();
  formData.append('avatar', avatar);
  return axiosInstance.post(`${api.users}/change-avatar`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const update = (
  signal: AbortSignal,
  firstName: string,
  lastName: string,
  birthday: string,
  phone: string,
) => {
  const data = {
    first_name: firstName,
    last_name: lastName,
    birthday: birthday,
    phone: phone,
  };
  return axiosInstance.put(api.users, data, { signal: signal });
};

const updateState = (signal: AbortSignal, data: IUpdateUserState) => {
  return axiosInstance.put(`${api.users}/state`, data, { signal: signal });
};

export const user = {
  authorized,
  pending,
  deleteAccess,
  changeAvatar,
  update,
  updateState,
};
