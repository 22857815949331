import { useEffect, useState } from 'react';
import { Wrapper } from './styled';
import Button from '@mui/material/Button';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { Trips } from './trips';

export const Searcher = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const fronts = useAppSelector(slices.fronts.items);
  const trips = useAppSelector(slices.trips.items);
  const searchCriteria = useAppSelector(slices.trips.searchCriteria);
  const searchResults = useAppSelector(slices.trips.searchResults);
  const loadingTrips = useAppSelector(slices.trips.loading);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const openModal = () => {
    dispatch(slices.trips.search(''));
    setIsSearching(true);
  };

  return (
    <Wrapper>
      <Button onClick={openModal} variant="outlined" size="large" disabled={fronts.length === 0}>
        Buscar
      </Button>
      {isSearching && (
        <Trips
          initialData={trips.filter(
            (trip) => trip.front !== null && trip.settings.mill_arrival_datetime === '',
          )}
          data={
            searchCriteria === ''
              ? trips.filter(
                  (trip) => trip.front !== null && trip.settings.mill_arrival_datetime === '',
                )
              : searchResults.filter(
                  (trip) => trip.front !== null && trip.settings.mill_arrival_datetime === '',
                )
          }
          loading={loadingTrips}
          close={() => setIsSearching(false)}
          notification={notification}
        />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
