import { useEffect, useState } from 'react';
import { Modal, ModalButtons } from '@components';
import { services } from '@services';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { INotification } from '@interfaces';
import { helpers } from '@helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const UpdateSettings = ({ close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [averageSpeed, setAverageSpeed] = useState('');
  const [harvestDayStartTime, setHarvestDayStartTime] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const settings = useAppSelector(slices.enterpriseSettings.settings);

  useEffect(() => {
    setAverageSpeed(settings.average_speed.toString());
    setHarvestDayStartTime(moment(settings.harvest_day_start_time));
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    if (Number(averageSpeed) > 0 && harvestDayStartTime) {
      setIsProcessing(true);
      await services.enterprise
        .updateEnterpriseSettings(controller.signal, {
          average_speed: Number(averageSpeed),
          harvest_day_start_time: harvestDayStartTime,
        })
        .then((response) => {
          dispatch(slices.enterpriseSettings.setSettings(response.data));
          notification.success('La configuración ha sido actualizada');
          close();
        })
        .catch(() => {
          notification.warning('No fue posible actualizar la configuración');
        });
      setIsProcessing(false);
    } else {
      notification.warning('Completa todos los campos para continuar');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Actualizar configuración" close={close} actions={buttons} maxWidth="xs">
      <TextField
        label="Velocidad promedio de los camiones"
        value={averageSpeed}
        onChange={(event) =>
          setAverageSpeed(helpers.validateNumber(averageSpeed, event.target.value, false))
        }
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">km/h</InputAdornment>,
        }}
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          label="Hora de inicio de un día de zafra"
          value={harvestDayStartTime}
          onChange={(value) => setHarvestDayStartTime(value)}
          slotProps={{ textField: { size: 'small' } }}
          sx={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}
        />
      </LocalizationProvider>
    </Modal>
  );
};
