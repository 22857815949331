import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Tooltip from '@mui/material/Tooltip';
import { hooks } from '@hooks';
import { ModalButtons } from '@components';

interface IProps {
  close: () => void;
  setImage: (image: any) => void;
  saveImage: () => void;
  isProcessing: boolean;
}

export const Buttons = ({ close, setImage, saveImage, isProcessing }: IProps) => {
  const screenWidth = hooks.useScreenWidth();

  const beforeUpload = (event: any) => {
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <Grid container>
      <Grid item xs={4} sm={6}>
        <Tooltip title={screenWidth > 600 ? '' : 'Cambiar imagen'} placement="right" arrow>
          <Button
            variant="outlined"
            size="large"
            component="label"
            startIcon={
              screenWidth > 600 ? undefined : <ChangeCircleIcon style={{ marginRight: -13 }} />
            }
            style={{ marginTop: 15 }}
          >
            {screenWidth > 600 && 'Cambiar imagen'}
            <input hidden accept=".jpg, .jpeg, .png" type="file" onChange={beforeUpload} />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={8} sm={6}>
        <ModalButtons
          isProcessing={isProcessing}
          primaryAction={saveImage}
          secondaryAction={close}
          primaryText="Continuar"
          secondaryText="Cancelar"
        />
      </Grid>
    </Grid>
  );
};
