import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  padding: 0 24px 0 8px;
  @media (max-width: 600px) {
    padding-right: 16px;
  }
`;

export const Container = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  height: calc(100% - 40px);
  overflow: auto;
`;
