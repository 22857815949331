import { useState } from 'react';
import { INotification } from '@interfaces';

export const useNotification = () => {
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [show, setShow] = useState(false);

  const success = (msg: string) => {
    setMessage(msg);
    setSeverity('success');
    setShow(true);
  };

  const info = (msg: string) => {
    setMessage(msg);
    setSeverity('info');
    setShow(true);
  };

  const warning = (msg: string) => {
    setMessage(msg);
    setSeverity('warning');
    setShow(true);
  };

  const error = (msg: string) => {
    setMessage(msg);
    setSeverity('error');
    setShow(true);
  };

  const hide = () => {
    setShow(false);
  };

  const notification: INotification = {
    message,
    severity,
    show,
    success,
    info,
    warning,
    error,
    hide,
  };

  return notification;
};
