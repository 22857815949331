import styled, { css } from 'styled-components';
import { design } from '@constants';

export const Content = styled.div`
  padding-bottom: 30px;
  > div {
    margin-top: 0;
  }
`;

export const Container = styled.div`
  background-color: ${design.colors.ultralightPrimary};
  padding: 20px;
  text-align: center;
  border-radius: 10px;
`;

export const State = styled.div`
  font-size: 20px;
  font-weight: bold;
  ${(props: any) =>
    props.paid
      ? css`
          color: ${design.colors.green};
        `
      : css`
          color: ${design.colors.orange};
        `}
`;

export const Total = styled.div`
  font-size: 20px;
  color: ${design.colors.primary};
  font-weight: bold;
`;

export const Title = styled.div`
  margin-top: 10px;
  color: ${design.colors.darkGray};
`;
