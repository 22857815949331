import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  padding: 0 24px 0 8px;
  @media (max-width: 600px) {
    padding-right: 16px;
  }
`;

export const Container = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: ${design.shadows.default};
  border-radius: 10px;
  height: calc(100% - 40px);
  overflow: auto;
  > div + div {
    margin-bottom: 40px;
  }
  > div > div > div > div > button {
    width: 100%;
    margin-top: 15px;
  }
  > div > div > div > div > div + div {
    margin-top: 15px;
  }
`;

export const CodeWrapper = styled.div`
  width: max-content;
  button {
    right: -35px;
    top: -22px;
    float: right;
    svg {
      font-size: 15px;
    }
  }
  @media (max-width: 500px) {
    > div > div > div + div {
      width: 190px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  img {
    width: 300px;
  }
  button {
    position: absolute;
    color: white;
    background-color: ${design.colors.mediaControlsBackground};
    transition: 0.2s;
    :hover {
      background-color: ${design.colors.imageBackground};
    }
  }
`;

export const UpdateConfiguration = styled.div`
  text-align: right;
  padding-top: 40px;
`;
