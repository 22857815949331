import { useState, useEffect } from 'react';
import { Modal, Skeleton, ModalButtons } from '@components';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { INotification, IRole } from '@interfaces';
import { Information, Content } from './styled';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { helpers } from '@helpers';

interface IProps {
  cancelAction: () => void;
  assignedRoles: string[];
  setAssignedRoles: (value: string[]) => void;
  assign: () => void;
  notification: INotification;
}

export const RoleAssignment = ({
  cancelAction,
  assignedRoles,
  setAssignedRoles,
  assign,
  notification,
}: IProps) => {
  const [activeRoles, setActiveRoles] = useState<IRole[]>([]);
  const modules = useAppSelector(slices.modules.items);
  const roles = useAppSelector(slices.roles.items);
  const rolesLoading = useAppSelector(slices.roles.loading);

  useEffect(() => {
    setActiveRoles(helpers.getActiveRoles(roles, modules));
  }, [roles, modules]);

  const next = () => {
    if (assignedRoles.length === 0) {
      notification.warning('Debes seleccionar al menos un rol');
    } else {
      assign();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setAssignedRoles(assignedRoles.concat([event.target.name]));
    } else {
      setAssignedRoles(assignedRoles.filter((role: string) => role !== event.target.name));
    }
  };

  return (
    <Modal
      title="Roles de usuario"
      close={cancelAction}
      actions={
        rolesLoading ? null : (
          <ModalButtons
            isProcessing={false}
            primaryAction={next}
            secondaryAction={cancelAction}
            primaryText="Continuar"
            secondaryText="Cancelar"
          />
        )
      }
      maxWidth="xs"
    >
      <Information>Selecciona los roles que deseas asignar</Information>
      <Content>
        {rolesLoading ? (
          <Skeleton variant="list" />
        ) : (
          <FormGroup>
            {activeRoles.map((item) => (
              <Tooltip key={item.id} title={item.description} placement="right" arrow>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={item.internal_name}
                      checked={assignedRoles.includes(item.internal_name)}
                      onChange={handleChange}
                    />
                  }
                  label={item.name}
                />
              </Tooltip>
            ))}
          </FormGroup>
        )}
      </Content>
    </Modal>
  );
};
