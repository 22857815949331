import { IMIndicators } from '@interfaces';
import { Wrapper, Title, Value } from './styled';

interface IProps {
  data: IMIndicators;
}

const RegisteredProducts = ({ data }: IProps) => {
  return (
    <Wrapper>
      <Value>{data.registered_products.toLocaleString()}</Value>
      <Title>Productos registrados</Title>
    </Wrapper>
  );
};

export default RegisteredProducts;
