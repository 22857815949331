import { axiosInstance } from './axios';
import { api } from '@constants';
import { INewProduct, IUpdateExistence } from '@interfaces';

const get = (signal: AbortSignal) => {
  return axiosInstance.get(api.products, { signal: signal });
};

const create = (signal: AbortSignal, data: INewProduct) => {
  return axiosInstance.post(api.products, data, { signal: signal });
};

const update = (signal: AbortSignal, data: INewProduct) => {
  return axiosInstance.put(api.products, data, { signal: signal });
};

const remove = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.products}/${id}`, { signal: signal });
};

const updateExistence = (signal: AbortSignal, data: IUpdateExistence) => {
  return axiosInstance.put(`${api.products}/update-existence`, data, { signal: signal });
};

const getBinnacle = (signal: AbortSignal, limit: number, offset: number) => {
  return axiosInstance.get(`${api.products}/binnacle`, {
    params: {
      limit: limit,
      offset: offset,
    },
    signal: signal,
  });
};

const cancelUpdate = (signal: AbortSignal, id: number) => {
  return axiosInstance.put(`${api.products}/cancel-update/${id}`, { signal: signal });
};

export const product = {
  get,
  create,
  update,
  remove,
  updateExistence,
  getBinnacle,
  cancelUpdate,
};
