import { Indicators } from '@interfaces';
import { Wrapper, Title } from './styled';
import { ProgressIndicator } from '@components';

interface IProps {
  data: Indicators;
}

export const TimeToLoad = ({ data }: IProps) => {
  return (
    <Wrapper>
      <ProgressIndicator
        value={data.time_compliance.time_to_load}
        indicatorSize={80}
        titleSize={20}
        indicatorColor="primary"
      />
      <Title>Cumplimiento del tiempo de carga en frentes</Title>
    </Wrapper>
  );
};
