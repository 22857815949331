import styled, { css } from 'styled-components';
import { design } from '@constants';

export const Title = styled.div`
  padding-top: 2px;
  position: relative;
  ${(props: any) =>
    props.highlightDescription
      ? css`
          color: ${design.colors.darkGray};
          font-size: 12px;
        `
      : css`
          color: ${(props: any) => props.color};
        `}
  svg {
    color: ${(props: any) => props.tooltipColor};
    font-size: 14px;
    margin-left: 5px;
    position: absolute;
    transition: 0.2s;
    :hover {
      color: ${design.colors.primary};
    }
  }
`;

export const Description = styled.div`
  line-height: 1.5;
  ${(props: any) =>
    props.highlightDescription
      ? css`
          color: initial;
        `
      : css`
          font-size: 12px;
          color: ${(props: any) => props.color};
        `}
`;

export const TooltipWrapper = styled.div`
  padding: 5px;
`;

export const TooltipDatetime = styled.div`
  font-size: 12px;
  ${(props: any) =>
    props.addPadding &&
    css`
      padding-bottom: 10px;
    `}
`;

export const TooltipTitle = styled.div`
  font-weight: bold;
`;

export const TooltipDescription = styled.div`
  ${(props: any) =>
    props.addPadding &&
    css`
      padding-top: 5px;
    `}
`;
