import { axiosInstance } from './axios';
import { api } from '@constants';

const login = (signal: AbortSignal, email: string, password: string) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);
  return axiosInstance.post(`${api.auth}/token`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const signup = (
  signal: AbortSignal,
  firstName: string,
  lastName: string,
  birthday: string,
  phone: string,
  email: string,
  password: string,
) => {
  const data = {
    first_name: firstName,
    last_name: lastName,
    birthday: birthday,
    phone: phone,
    email: email,
    password: password,
  };
  return axiosInstance.post(`${api.users}/create`, data, { signal: signal });
};

const generateOtp = (signal: AbortSignal, email: string) => {
  return axiosInstance.post(`${api.auth}/generate-otp`, { email: email }, { signal: signal });
};

const validateOtp = (signal: AbortSignal, email: string, otp: string) => {
  const data = {
    email: email,
    otp: otp,
  };
  return axiosInstance.post(`${api.auth}/validate-otp`, data, { signal: signal });
};

const registerPassword = (signal: AbortSignal, email: string, password: string, otp: string) => {
  const data = {
    email: email,
    password: password,
    otp: otp,
  };
  return axiosInstance.post(`${api.auth}/register-password`, data, { signal: signal });
};

export const security = {
  login,
  signup,
  generateOtp,
  validateOtp,
  registerPassword,
};
