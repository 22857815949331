import { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { Wrapper, Container, Title } from './styled';
import { PendingUsers } from './pendingUsers';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Notification, Searcher } from '@components';
import { services } from '@services';
import { hooks } from '@hooks';

export const NewUsers = () => {
  const dispatch = useAppDispatch();
  const notification = hooks.useNotification();
  const controller = new AbortController();
  const rolesLoading = useAppSelector(slices.roles.loading);
  const rolesLoaded = useAppSelector(slices.roles.loaded);
  const users = useAppSelector(slices.pendingUsers.items);
  const searchResults = useAppSelector(slices.pendingUsers.searchResults);
  const searchCriteria = useAppSelector(slices.pendingUsers.searchCriteria);

  useEffect(() => {
    if (!rolesLoaded && !rolesLoading) {
      getRoles();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getRoles = async () => {
    dispatch(slices.roles.setLoading(true));
    await services.role
      .getAllRoles(controller.signal)
      .then((response) => {
        dispatch(slices.roles.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los roles existentes');
      });
    dispatch(slices.roles.setLoading(false));
  };

  const onSearch = (value: string) => {
    dispatch(slices.pendingUsers.search(value));
  };

  const getTotalItems = () => {
    let totalItems = 0;
    if (searchCriteria === '') {
      totalItems = users.length;
    } else {
      totalItems = searchResults.length;
    }
    return (
      <Chip
        label={`${totalItems} ${totalItems === 1 ? 'usuario encontrado' : 'usuarios encontrados'}`}
        size="small"
      />
    );
  };

  return (
    <Wrapper>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={users.length === 0} />
      <Title>Nuevos usuarios</Title>
      {getTotalItems()}
      <Container>
        <PendingUsers />
      </Container>
      <Notification data={notification} />
    </Wrapper>
  );
};
