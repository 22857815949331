import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  button {
    float: right;
    border-color: rgba(0, 0, 0, 0.23);
    color: ${design.colors.darkGray};
  }
  button:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
`;
