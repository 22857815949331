import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { SortableColumn } from '@components';

export const Header = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(slices.links.order);
  const orderBy = useAppSelector(slices.links.orderBy);

  const handleSort = (field: string) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc';
    dispatch(slices.links.setOrder(newOrder));
    dispatch(slices.links.setOrderBy(field));
    dispatch(slices.links.sort({ field: field, order: newOrder }));
  };

  return (
    <TableHead>
      <TableRow>
        <SortableColumn
          name="icon"
          value="Icono"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="name"
          value="Nombre"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="url"
          value="URL"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
