import { useEffect, useState } from 'react';
import { INotification, ITrip } from '@interfaces';
import { Wrapper, Container, Title, Subtitle } from './styled';
import { slices } from '@slices';
import { services } from '@services';
import { useAppDispatch } from 'src/store/hooks';
import { ModalConfirm } from '@components';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TimeIndicator } from './timeIndicator';
import { NotifyDepartureFront } from './../../distribution/header/searcher/trips/body/notifyDepartureFront';

interface IProps {
  trip: ITrip | null;
  notification: INotification;
}

export const Detail = ({ trip, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [actionTitle, setActionTitle] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isNotifyingDepartureFront, setIsNotifyingDepartureFront] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (trip?.settings.front_arrival_datetime === '') {
      setActionTitle('Notificar la llegada al frente');
    } else {
      if (trip?.settings.front_departure_datetime === '') {
        setActionTitle('Notificar la salida del frente');
      } else {
        if (trip?.settings.mill_arrival_datetime === '') {
          setActionTitle('Notificar la llegada al ingenio');
        } else {
          setActionTitle('Notificar la descarga del equipo');
        }
      }
    }
  }, [trip]);

  const notify = async () => {
    setIsProcessing(true);
    let generatedEvent = '';
    if (trip?.settings.front_arrival_datetime === '') {
      generatedEvent = 'notify-arrival-front';
    } else {
      if (trip?.settings.front_departure_datetime === '') {
        generatedEvent = '';
      } else {
        if (trip?.settings.mill_arrival_datetime === '') {
          generatedEvent = 'notify-arrival-mill';
        } else {
          generatedEvent = 'notify-download';
        }
      }
    }
    await services.trip
      .notify(controller.signal, trip?.id || 0, generatedEvent)
      .then((response) => {
        dispatch(slices.trip.setItem(response.data));
        notification.success(`Hemos ${actionTitle.replace('Notificar', 'notificado')}`);
      })
      .catch(() => {
        notification.warning(`No fue posible ${actionTitle.toLowerCase()}`);
      });
    setIsConfirming(false);
    setIsProcessing(false);
  };

  const onClick = () => {
    if (
      trip?.settings.front_arrival_datetime !== '' &&
      trip?.settings.front_departure_datetime === ''
    ) {
      setIsNotifyingDepartureFront(true);
    } else {
      setIsConfirming(true);
    }
  };

  return (
    <Wrapper>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Container>
            <Title>{trip?.download_truck ? trip.equipment.code : trip?.truck.code}</Title>
            <Subtitle>{trip?.download_truck ? 'Equipo' : 'Camión'}</Subtitle>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <Container>
            <Title>{trip?.equipment_type.name}</Title>
            <Subtitle>Tipo de equipo</Subtitle>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <Container>
            <Title>{trip?.settings.cage_number}</Title>
            <Subtitle>Cantidad de jaulas</Subtitle>
          </Container>
        </Grid>
        {!trip?.download_truck && (
          <Grid item xs={12}>
            <Container>
              <Title>Frente {trip?.front?.identifier}</Title>
              <Subtitle>
                Ubicado en finca {trip?.farm?.name}. {trip?.front?.settings.route}.
              </Subtitle>
            </Container>
          </Grid>
        )}
        <Grid item xs={12}>
          <Container>
            {!isProcessing && trip && (
              <TimeIndicator
                settings={trip.settings}
                truck={trip.truck}
                titleSize={20}
                indicatorSize={100}
                variant="Vertical"
              />
            )}
          </Container>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button onClick={onClick} variant="contained" size="large">
            {actionTitle}
          </Button>
        </Grid>
      </Grid>
      {isConfirming && (
        <ModalConfirm
          title="Confirmación"
          content={`¿ Deseas ${actionTitle.toLowerCase()} ?`}
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={notify}
          secondaryAction={() => setIsConfirming(false)}
          isProcessing={isProcessing}
        />
      )}
      {isNotifyingDepartureFront && trip && (
        <NotifyDepartureFront
          data={trip}
          close={() => setIsNotifyingDepartureFront(false)}
          notification={notification}
        />
      )}
    </Wrapper>
  );
};
