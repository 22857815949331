import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  width: 300px;
  padding: 10px 0 15px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${design.colors.primary};
  margin-bottom: 15px;
  padding: 0 15px;
`;

export const List = styled.div`
  margin: 15px 0 0;
  padding: 0 15px;
  max-height: 200px;
  overflow: auto;
`;

export const Item = styled.div`
  background-color: ${design.colors.ultralightGray};
  padding: 7px 15px;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: 0.2s;
  :hover {
    background-color: ${design.colors.lightGray};
  }
`;

export const SearcherWrapper = styled.div`
  padding: 0 20px 0 15px;
  > .MuiTextField-root {
    width: 100% !important;
    margin-bottom: 10px;
  }
  > .MuiChip-root {
    width: 100%;
    background: ${design.colors.lightPrimary};
    color: ${design.colors.primary};
    height: auto;
    span {
      font-size: 12px !important;
      padding: 3px;
    }
  }
`;
