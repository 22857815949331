import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IFrontCompliance, IFrontComplianceList } from '@interfaces';

const initialState: IFrontComplianceList = {
  items: [],
  loaded: false,
  loading: false,
};

const frontComplianceSlice = createSlice({
  name: 'frontCompliance',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IFrontCompliance[]>) => {
      state.items = action.payload;
      state.loaded = true;
    },
  },
});

const { setLoading, setItems } = frontComplianceSlice.actions;
const items = (state: RootState) => state.frontCompliance.items;
const loaded = (state: RootState) => state.frontCompliance.loaded;
const loading = (state: RootState) => state.frontCompliance.loading;

export const frontCompliance = {
  setLoading,
  setItems,
  items,
  loaded,
  loading,
};

export default frontComplianceSlice.reducer;
