import { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableOptions, AvatarWithInfo, ModalConfirm } from '@components';
import { INotification, ITrip } from '@interfaces';
import { helpers } from '@helpers';
import { slices } from '@slices';
import { services } from '@services';
import { useAppDispatch } from 'src/store/hooks';
import { TimeIndicator } from 'src/components/home/transportManagement/currentTrip/detail/timeIndicator';
import { ChangeFront } from './changeFront';
import { Transfer } from './transfer';
import { NotifyDepartureFront } from './notifyDepartureFront';

interface IProps {
  items: ITrip[];
  notification: INotification;
}

export const Body = ({ items, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [trip, setTrip] = useState<ITrip>();
  const [action, setAction] = useState('');
  const [actionTitle, setActionTitle] = useState('');
  const [isNotifying, setIsNotifying] = useState(false);
  const [isNotifyingDepartureFront, setIsNotifyingDepartureFront] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChangingFront, setIsChangingFront] = useState(false);
  const [isTransfering, setIsTransfering] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const confirmAction = (currentTrip: ITrip) => {
    if (currentTrip?.settings.front_arrival_datetime === '') {
      setAction('notify-arrival-front');
      setActionTitle(options[0].title);
    } else {
      if (currentTrip?.settings.front_departure_datetime !== '') {
        setAction('notify-arrival-mill');
        setActionTitle(options[2].title);
      }
    }
    setTrip(currentTrip);
    setIsNotifying(true);
  };

  const notifyDepartureFront = (currentTrip: ITrip) => {
    setTrip(currentTrip);
    setIsNotifyingDepartureFront(true);
  };

  const changeFront = (currentTrip: ITrip) => {
    setTrip(currentTrip);
    setIsChangingFront(true);
  };

  const transfer = (currentTrip: ITrip) => {
    setTrip(currentTrip);
    setIsTransfering(true);
  };

  const confirmDeletion = (currentTrip: ITrip) => {
    setTrip(currentTrip);
    setIsDeleting(true);
  };

  const showOption = (currentTrip: ITrip, index: number) => {
    let show = false;
    switch (index) {
      case 0:
        show = currentTrip.settings.front_arrival_datetime === '';
        break;
      case 1:
        show =
          currentTrip.settings.front_arrival_datetime !== '' &&
          currentTrip.settings.front_departure_datetime === '';
        break;
      case 2:
        show =
          currentTrip.settings.front_departure_datetime !== '' &&
          currentTrip.settings.mill_arrival_datetime === '';
        break;
      case 3:
        show = currentTrip.settings.front_arrival_datetime === '';
        break;
      case 4:
        show = true;
        break;
      case 5:
        show = true;
        break;
    }
    return show;
  };

  const options = [
    {
      title: 'Notificar la llegada al frente',
      onClick: confirmAction,
    },
    {
      title: 'Notificar la salida del frente',
      onClick: notifyDepartureFront,
    },
    {
      title: 'Notificar la llegada al ingenio',
      onClick: confirmAction,
    },
    {
      title: 'Cambiar frente',
      onClick: changeFront,
    },
    {
      title: 'Transferir',
      onClick: transfer,
    },
    {
      title: 'Eliminar',
      onClick: confirmDeletion,
    },
  ];

  const tableOptions = (item: ITrip) => (
    <TableOptions row={item} showOption={showOption} options={options} />
  );

  const notify = async () => {
    setIsProcessing(true);
    await services.trip
      .notify(controller.signal, trip?.id || 0, action)
      .then((response) => {
        if (action === 'notify-arrival-mill') {
          dispatch(slices.trips.endTrip(trip?.id || 0));
        } else {
          dispatch(slices.trips.updateItem(response.data));
        }
        notification.success(`Hemos ${actionTitle.replace('Notificar', 'notificado')}`);
      })
      .catch(() => {
        notification.warning(`No fue posible ${actionTitle.toLowerCase()}`);
      });
    setIsNotifying(false);
    setIsProcessing(false);
  };

  const del = async () => {
    setIsProcessing(true);
    await services.trip
      .del(controller.signal, trip?.id || 0)
      .then(() => {
        dispatch(slices.trips.endTrip(trip?.id || 0));
        notification.success('Hemos eliminado el viaje seleccionado');
      })
      .catch(() => {
        notification.warning('No fue posible eliminar el viaje seleccionado');
      });
    setIsDeleting(false);
    setIsProcessing(false);
  };

  return (
    <>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id} hover>
            <TableCell>
              <AvatarWithInfo
                avatar={helpers.getAvatar(item.driver.avatar)}
                title={`${item.driver.first_name} ${item.driver.last_name}`}
                description={item.driver.phone}
              />
            </TableCell>
            <TableCell>
              <AvatarWithInfo
                title={item.truck.code}
                description={`${item.equipment_type.name} de ${item.settings.cage_number} jaulas`}
                tooltipDatetime={helpers.getTimeFromNow(item.truck.state_date)}
                tooltipTitle={item.truck.state.name}
                tooltipDescription={item.truck.state_description}
                tooltipIconColor={item.truck.state.color}
              />
            </TableCell>
            <TableCell>
              <AvatarWithInfo
                title={`Frente ${item.front?.identifier}`}
                description={`Finca ${item.farm?.name}`}
              />
            </TableCell>
            <TableCell style={{ width: 200 }}>
              <TimeIndicator
                settings={item.settings}
                titleSize={10}
                indicatorSize={40}
                variant="Horizontal"
              />
            </TableCell>
            {tableOptions(item)}
          </TableRow>
        ))}
      </TableBody>
      {isNotifying && (
        <ModalConfirm
          title="Confirmación"
          content={`¿ Deseas ${actionTitle.toLowerCase()} ?`}
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={notify}
          secondaryAction={() => setIsNotifying(false)}
          isProcessing={isProcessing}
        />
      )}
      {isDeleting && (
        <ModalConfirm
          title="Confirmación"
          content={`¿ Deseas eliminar el viaje seleccionado ?`}
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={del}
          secondaryAction={() => setIsDeleting(false)}
          isProcessing={isProcessing}
        />
      )}
      {isChangingFront && trip && (
        <ChangeFront
          data={trip}
          close={() => setIsChangingFront(false)}
          notification={notification}
        />
      )}
      {isTransfering && trip && (
        <Transfer data={trip} close={() => setIsTransfering(false)} notification={notification} />
      )}
      {isNotifyingDepartureFront && trip && (
        <NotifyDepartureFront
          data={trip}
          close={() => setIsNotifyingDepartureFront(false)}
          notification={notification}
        />
      )}
    </>
  );
};
