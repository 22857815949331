import { WithoutData } from '@components';
import SecurityIcon from '@mui/icons-material/Security';
import { Wrapper, Container } from './styled';

export const Forbidden = () => {
  return (
    <Wrapper>
      <Container>
        <WithoutData
          icon={<SecurityIcon />}
          title="Permisos insuficientes"
          description="No tienes los permisos suficientes para ingresar a esta página"
        />
      </Container>
    </Wrapper>
  );
};
