import { useEffect } from 'react';
import { Wrapper, Title } from './styled';
import { Searcher, Notification } from '@components';
import { services } from '@services';
import { slices } from '@slices';
import { hooks } from '@hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Form } from '../form';
import Button from '@mui/material/Button';
import { IUseEquipment } from '@interfaces';

interface IProps {
  data: IUseEquipment;
}

export const Header = ({ data }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.equipments.items);
  const searchCriteria = useAppSelector(slices.equipments.searchCriteria);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onCreate = () => {
    data.initialize();
    data.setIsCreating(true);
  };

  const save = async () => {
    data.setIsProcessing(true);
    await services.equipment
      .create(controller.signal, data.object())
      .then((response) => {
        dispatch(slices.equipments.setItem(response.data));
        notification.success('Se registró el nuevo equipo');
      })
      .catch(() => {
        notification.warning('No fue posible agregar el nuevo equipo');
      });
    data.setIsCreating(false);
    data.setIsProcessing(false);
  };

  const cancel = () => {
    data.setIsCreating(false);
    data.setIsProcessing(false);
  };

  const onSearch = (value: string) => {
    dispatch(slices.equipments.search(value));
  };

  return (
    <Wrapper>
      <Button onClick={onCreate} variant="outlined" size="large">
        Agregar
      </Button>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={items.length === 0} />
      <Title>Equipos</Title>
      {data.isCreating && (
        <Form data={data} save={save} cancel={cancel} notification={notification} />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
