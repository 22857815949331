import { useState } from 'react';
import { IUseEquipment, IEquipment, IEquipmentType } from '@interfaces';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

export const useEquipment = () => {
  const items = useAppSelector(slices.equipments.items);
  const [id, setId] = useState(0);
  const [code, setCode] = useState('');
  const [type, setType] = useState<IEquipmentType | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initialize = (item?: IEquipment) => {
    setId(item ? item.id : 0);
    setCode(item ? item.code : '');
    setType(item ? item.type : null);
  };

  const isValid = () => {
    if (code.trim() === '' || type === null) {
      setCode(code.trim());
      return false;
    }
    return true;
  };

  const isDuplicated = () =>
    items.filter((item) => item.code.toLowerCase() === code.trim().toLowerCase() && item.id !== id)
      .length > 0;

  const object = () => {
    return {
      id: id,
      code: code.trim(),
      type: type?.id,
    };
  };

  const data: IUseEquipment = {
    id,
    code,
    type,
    isCreating,
    isUpdating,
    isDeleting,
    isProcessing,
    setCode,
    setType,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsProcessing,
    initialize,
    isValid,
    isDuplicated,
    object,
  };

  return data;
};
