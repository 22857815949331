import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TableSkeleton = styled.div`
  span {
    margin-top: -15px;
  }
`;

export const ListSkeleton = styled.div`
  span {
    margin-top: -10px;
  }
`;

export const InputSkeleton = styled.div`
  span {
    margin-top: -14px;
    margin-bottom: 8px;
  }
`;

export const IndicatorWrapper = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
`;
