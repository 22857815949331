import { useEffect } from 'react';
import { ChipWrapper, Container } from './styled';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Chip from '@mui/material/Chip';
import { Skeleton, WithoutData, Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Header } from './header';
import { Body } from './body';
import { ITruck, IUseTruck } from '@interfaces';

interface IProps {
  data: IUseTruck;
}

export const Table = ({ data }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.trucks.items);
  const searchResults = useAppSelector(slices.trucks.searchResults);
  const searchCriteria = useAppSelector(slices.trucks.searchCriteria);
  const loading = useAppSelector(slices.trucks.loading);
  const loaded = useAppSelector(slices.trucks.loaded);

  useEffect(() => {
    if (!loaded && !loading) getItems();
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.trucks.setLoading(true));
    await services.truck
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trucks.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los camiones');
      });
    dispatch(slices.trucks.setLoading(false));
  };

  const getTotalItems = () => {
    let totalItems = 0;
    if (searchCriteria === '') {
      totalItems = items.length;
    } else {
      totalItems = searchResults.length;
    }
    return (
      <Chip
        label={`${totalItems} ${totalItems === 1 ? 'camión encontrado' : 'camiones encontrados'}`}
        size="small"
      />
    );
  };

  const buildTable = (dataList: ITruck[]) => (
    <TableContainer>
      <MUITable size="small" stickyHeader sx={{ minWidth: 600 }}>
        <Header />
        <Body items={dataList} data={data} notification={notification} />
      </MUITable>
    </TableContainer>
  );

  return (
    <>
      <ChipWrapper>{getTotalItems()}</ChipWrapper>
      <Container>
        {loading ? (
          <Skeleton variant="table" />
        ) : searchCriteria === '' ? (
          items.length ? (
            buildTable(items)
          ) : (
            <WithoutData
              icon={<LocalShippingIcon />}
              title="No hay camiones"
              description="En esta sección se mostrarán los camiones que han sido agregados"
            />
          )
        ) : searchResults.length ? (
          buildTable(searchResults)
        ) : (
          <WithoutData
            icon={<LocalShippingIcon />}
            title="No hay coincidencias"
            description="En esta sección se mostrarán los camiones que coincidan con los criterios de búsqueda"
          />
        )}
        <Notification data={notification} />
      </Container>
    </>
  );
};
