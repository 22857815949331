import { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { AvatarWithInfo, ModalConfirm, TableOptions, Skeleton } from '@components';
import { INotification, IBinnacleRecord } from '@interfaces';
import { services } from '@services';
import { Action, SeeMore, Price } from './styled';
import moment from 'moment';

interface IProps {
  items: IBinnacleRecord[];
  loading: boolean;
  seeMore?: () => void;
  removeItem: (id: number) => void;
  notification: INotification;
}

export const Body = ({ items, loading, seeMore, removeItem, notification }: IProps) => {
  const controller = new AbortController();
  const [selectedItem, setSelectedItem] = useState<IBinnacleRecord | null>(null);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onCancel = (value: IBinnacleRecord) => {
    setSelectedItem(value);
    setIsCanceling(true);
  };

  const cancelAction = async () => {
    setIsProcessing(true);
    await services.product
      .cancelUpdate(controller.signal, selectedItem?.id || 0)
      .then(() => {
        removeItem(selectedItem?.id || 0);
        notification.success('Se canceló la actualización seleccionada');
      })
      .catch(() => {
        notification.warning('No fue posible cancelar la actualización seleccionada');
      });
    setIsCanceling(false);
    setIsProcessing(false);
  };

  const cancel = () => {
    setIsCanceling(false);
    setIsProcessing(false);
  };

  const tableOptions = (item: IBinnacleRecord) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Cancelar actualización',
          onClick: onCancel,
        },
      ]}
    />
  );

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>
            <AvatarWithInfo
              avatar={item.user.avatar}
              title={`${item.user.first_name} ${item.user.last_name}`}
              description={item.user.phone}
            />
          </TableCell>
          <TableCell>
            <AvatarWithInfo title={item.product.name} description={item.product.description} />
          </TableCell>
          <TableCell align="center">{item.amount.toLocaleString()}</TableCell>
          <TableCell align="right">
            <Price>
              <AvatarWithInfo title={item.price.toFixed(2).toLocaleString()} description="GTQ" />
            </Price>
          </TableCell>
          <TableCell>
            <Action isinput={String(item.is_input)}>{item.is_input ? 'Entrada' : 'Salida'}</Action>
          </TableCell>
          <TableCell>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
          <TableCell>{item.observations}</TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {seeMore && (
        <TableRow>
          <TableCell colSpan={8} style={{ borderBottom: 'none' }}>
            {loading ? (
              <Skeleton variant="table" />
            ) : (
              <SeeMore>
                <Button onClick={seeMore}>Ver más</Button>
              </SeeMore>
            )}
          </TableCell>
        </TableRow>
      )}
      {isCanceling && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se cancelará la actualización seleccionada"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={cancelAction}
          secondaryAction={cancel}
          isProcessing={isProcessing}
        />
      )}
    </TableBody>
  );
};
