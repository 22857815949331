import { PieChart } from '@mui/x-charts/PieChart';
import { Indicators } from '@interfaces';
import { Wrapper, Title } from './styled';
import { design } from '@constants';

interface IProps {
  data: Indicators;
}

export const CaneEnteredByTypeOfEquipment = ({ data }: IProps) => {
  return (
    <Wrapper>
      <PieChart
        series={[
          {
            data: data.cane_entered_by_type_of_equipment.map((item) => ({
              label: item.equipment_type,
              value: item.percentage,
            })),
            valueFormatter: (item) => `${item.value} %`,
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -90,
          },
        ]}
        slotProps={{ legend: { hidden: true } }}
        colors={design.colors.pallette}
        margin={{ right: 0 }}
        height={200}
      />
      <Title>Caña ingresada por tipo de equipo</Title>
    </Wrapper>
  );
};
