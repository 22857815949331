import { Wrapper, Name, Email } from './styled';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { helpers } from '@helpers';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  changeAvatar: () => void;
}

export const UserInformation = ({ changeAvatar }: IProps) => {
  const getName = () =>
    `${helpers.getPropertyInCurrentUser('first_name')} ${helpers.getPropertyInCurrentUser(
      'last_name',
    )}`;

  const getAvatar = () => {
    if (helpers.getPropertyInCurrentUser('avatar')) {
      return helpers.getAvatar(helpers.getPropertyInCurrentUser('avatar'));
    } else {
      return undefined;
    }
  };

  return (
    <Wrapper>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Tooltip title="Cambiar foto de perfil" arrow>
            <PhotoCameraIcon onClick={changeAvatar} />
          </Tooltip>
        }
      >
        <Avatar sx={{ width: 80, height: 80 }} alt="Foto de perfil" src={getAvatar()}>
          <PersonIcon sx={{ fontSize: 75 }} />
        </Avatar>
      </Badge>
      <Name>{getName()}</Name>
      <Email>{helpers.getPropertyInCurrentUser('email')}</Email>
    </Wrapper>
  );
};
