import axios from 'axios';
import { settings, urlBase } from '@constants';
import { helpers } from '@helpers';

const axiosInstance = axios.create({
  baseURL: urlBase,
  timeout: settings.axiosTimeout,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const jwt = helpers.getJwt();
    if (jwt) {
      config.headers && (config.headers.Authorization = `Bearer ${jwt}`);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      helpers.deleteJwt();
      window.location.href = '/login?event=sessionExpired';
    }
    if (error.response.status === 403) {
      window.location.href = '/forbidden';
    }
    return Promise.reject(error);
  },
);

export { axiosInstance };
