import { axiosInstance } from './axios';
import { api } from '@constants';
import { IEnterpriseSettings } from '@interfaces';

const createEnterprise = (signal: AbortSignal, name: string, logo: any, modules: number[]) => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('logo', logo);
  formData.append('modules', modules.join(','));
  return axiosInstance.post(`${api.enterprises}/create`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const joinRequest = (signal: AbortSignal, token: string) => {
  const formData = new FormData();
  formData.append('enterprise_token', token);
  return axiosInstance.post(`${api.users}/join-request`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const approveJoinRequest = (signal: AbortSignal, user: number, roles: string[]) => {
  const formData = new FormData();
  formData.append('user', String(user));
  formData.append('roles', String(roles));
  return axiosInstance.post(`${api.users}/approve-join-request`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const declineJoinRequest = (signal: AbortSignal, user: number) => {
  const formData = new FormData();
  formData.append('user', String(user));
  return axiosInstance.post(`${api.users}/decline-join-request`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const leaveEnterprise = (signal: AbortSignal) => {
  return axiosInstance.post(`${api.users}/leave-enterprise`, { signal: signal });
};

const changeLogo = (signal: AbortSignal, logo: any) => {
  const formData = new FormData();
  formData.append('logo', logo);
  return axiosInstance.post(`${api.enterprises}/change-logo`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const enterpriseSettings = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.enterprises}/settings`, { signal: signal });
};

const updateEnterpriseSettings = (signal: AbortSignal, settings: IEnterpriseSettings) => {
  return axiosInstance.put(`${api.enterprises}/settings`, settings, { signal: signal });
};

const indicators = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.enterprises}/indicators`, { signal: signal });
};

const invoices = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.enterprises}/invoices`, { signal: signal });
};

export const enterprise = {
  createEnterprise,
  joinRequest,
  approveJoinRequest,
  declineJoinRequest,
  leaveEnterprise,
  changeLogo,
  enterpriseSettings,
  updateEnterpriseSettings,
  indicators,
  invoices,
};
