import { useEffect } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IUseTruck, INotification, IBrand } from '@interfaces';
import { helpers } from '@helpers';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';

interface IProps {
  data: IUseTruck;
  save: () => void;
  cancel: () => void;
  notification: INotification;
}

export const Form = ({ data, save, cancel, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const brands = useAppSelector(slices.brands.items);
  const loading = useAppSelector(slices.brands.loading);
  const loaded = useAppSelector(slices.brands.loaded);

  useEffect(() => {
    if (!loaded && !loading) {
      getItems();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.brands.setLoading(true));
    await services.brand
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.brands.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar las marcas');
      });
    dispatch(slices.brands.setLoading(false));
  };

  const next = () => {
    if (data.isValid()) {
      if (data.isDuplicated()) {
        notification.warning('Ya existe un camión con este código');
      } else {
        save();
      }
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const buttons = (
    <ModalButtons
      isProcessing={data.isProcessing}
      primaryAction={next}
      secondaryAction={cancel}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal
      title={`${data.id === 0 ? 'Agregar' : 'Actualizar'} camión`}
      close={cancel}
      actions={buttons}
      maxWidth="xs"
    >
      <TextField
        label="Código"
        value={data.code}
        onChange={(event) => data.setCode(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '20px', marginTop: '10px' }}
      />
      <Autocomplete
        value={data.brand}
        onChange={(_event: any, newValue: IBrand | null) => data.setBrand(newValue)}
        options={brands}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="Marca" size="small" sx={{ marginBottom: '20px' }} />
        )}
        noOptionsText="No hay opciones"
      />
      <TextField
        label="Modelo"
        value={data.model}
        onChange={(event) =>
          data.setModel(helpers.validateNumber(data.model, event.target.value, false))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
    </Modal>
  );
};
