import { useState } from 'react';
import { IUseTrip, IEquipmentType, ITruck } from '@interfaces';

export const useTrip = () => {
  const [truck, setTruck] = useState<ITruck | null>(null);
  const [equipmentType, setEquipmentType] = useState<IEquipmentType | null>(null);
  const [cageNumber, setCageNumber] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const isValid = () => {
    if (truck === null || equipmentType === null || cageNumber.trim() === '') {
      setCageNumber(cageNumber.trim());
      return false;
    }
    return true;
  };

  const object = () => {
    return {
      truck_id: truck?.id,
      equipment_type: equipmentType?.id,
      cage_number: Number(cageNumber),
    };
  };

  const data: IUseTrip = {
    truck,
    equipmentType,
    cageNumber,
    isRequesting,
    isProcessing,
    setTruck,
    setEquipmentType,
    setCageNumber,
    setIsRequesting,
    setIsProcessing,
    isValid,
    object,
  };

  return data;
};
