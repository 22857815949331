import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  > div + div {
    ${(props: any) =>
      props.variant === 'Horizontal'
        ? css`
            padding-left: 56px;
            position: absolute;
            top: 7px;
          `
        : css`
            > div {
              width: 100%;
              padding-top: 20px !important;
            }
          `}
  }
`;
