import { axiosInstance } from './axios';
import { api } from '@constants';
import { INewState } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.states, { signal: signal });
};

const create = (signal: AbortSignal, data: INewState) => {
  return axiosInstance.post(api.states, data, { signal: signal });
};

const update = (signal: AbortSignal, data: INewState) => {
  return axiosInstance.put(api.states, data, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.states}/${id}`, { signal: signal });
};

export const state = {
  all,
  create,
  update,
  del,
};
