import { useEffect, useState } from 'react';
import { CropImage } from '@components';
import { helpers } from '@helpers';
import { services } from '@services';
import { INotification } from '@interfaces';
import logo from 'src/assets/logo.jpg';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const ChangeLogo = ({ close, notification }: IProps) => {
  const controller = new AbortController();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const getLogo = () => {
    return helpers.getPropertyInCurrentUser('enterprise')['logo']
      ? helpers.getLogo(helpers.getPropertyInCurrentUser('enterprise')['logo'])
      : logo;
  };

  const save = (image: any) => {
    setIsProcessing(true);
    services.enterprise
      .changeLogo(controller.signal, image)
      .then((response) => {
        helpers.updateCurrentUserEnterpriseLogo(response.data);
        notification.success('Logotipo actualizado');
        close();
      })
      .catch(() => {
        setIsProcessing(false);
        notification.warning('No fue posible actualizar el logotipo');
      });
  };

  return (
    <CropImage
      title="Logotipo de empresa"
      variant="logo"
      initialImage={getLogo()}
      close={close}
      save={save}
      isProcessing={isProcessing}
    />
  );
};
