import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { SortableColumn } from '@components';

export const Header = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(slices.farms.order);
  const orderBy = useAppSelector(slices.farms.orderBy);

  const handleSort = (field: string) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc';
    dispatch(slices.farms.setOrder(newOrder));
    dispatch(slices.farms.setOrderBy(field));
    dispatch(slices.farms.sort({ field: field, order: newOrder }));
  };

  return (
    <TableHead>
      <TableRow>
        <SortableColumn
          name="name"
          value="Nombre"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="location"
          value="Ubicación"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="distance"
          value="Distancia"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
          align="right"
        />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
