import styled from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  box-shadow: ${design.shadows.default};
  background-color: white;
  width: 100%;
  max-width: 450px;
  padding: 50px;
  border-radius: 10px;
  margin: auto;
  @media (max-width: 599px) {
    padding: 20px;
    margin-left: 16px;
    margin-right: 16px;
    max-width: unset;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 5px;
  opacity: 0.7;
`;

export const Subtitle = styled.div`
  opacity: 0.5;
  margin-bottom: 30px;
  font-size: 16px;
`;
