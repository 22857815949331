import { useEffect } from 'react';
import { Modal, ModalButtons, Skeleton, WithoutData, Searcher } from '@components';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { INotification, ITrip } from '@interfaces';
import { ModalHeader, ModalTitle, SearcherWrapper } from './styled';
import Chip from '@mui/material/Chip';
import { slices } from '@slices';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { Header } from './header';
import { Body } from './body';

interface IProps {
  initialData: ITrip[];
  data: ITrip[];
  loading: boolean;
  close: () => void;
  notification: INotification;
}

export const Trips = ({ initialData, data, loading, close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const searchCriteria = useAppSelector(slices.trips.searchCriteria);

  const onSearch = (value: string) => {
    dispatch(slices.trips.search(value));
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Modal
      title={
        <ModalHeader>
          <ModalTitle>Viajes activos</ModalTitle>
          {initialData.length > 0 && (
            <SearcherWrapper>
              <Searcher value={searchCriteria} onSearch={onSearch} />
              {data.length > 0 && (
                <Chip
                  label={`${data.length} ${
                    data.length === 1 ? 'viaje encontrado' : 'viajes encontrados'
                  }`}
                  style={{ fontWeight: 'normal' }}
                  size="small"
                />
              )}
            </SearcherWrapper>
          )}
        </ModalHeader>
      }
      close={close}
      actions={<ModalButtons secondaryAction={close} secondaryText="Cerrar" />}
      maxWidth="md"
      dynamicBody={false}
    >
      {loading ? (
        <Skeleton variant="table" />
      ) : data.length > 0 ? (
        <TableContainer>
          <MUITable size="small" stickyHeader sx={{ minWidth: 800 }}>
            <Header />
            <Body items={data} notification={notification} />
          </MUITable>
        </TableContainer>
      ) : (
        <WithoutData
          icon={<LocalShippingIcon />}
          title={`${searchCriteria === '' ? 'No hay viajes activos' : 'No hay coincidencias'}`}
          description={`${
            searchCriteria === ''
              ? 'En esta sección se mostrarán los viajes activos'
              : 'En esta sección se mostrarán los viajes que coincidan con los criterios de búsqueda'
          }`}
          size="medium"
        />
      )}
    </Modal>
  );
};
