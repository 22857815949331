import { Wrapper } from './styled';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { slices } from '@slices';
import { Detail } from './detail';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

export const Requests = () => {
  const dispatch = useAppDispatch();
  const notification = hooks.useNotification();
  const data = hooks.useAssignTrip();
  const fronts = useAppSelector(slices.fronts.items);
  const trips = useAppSelector(slices.trips.items);

  const openModal = () => {
    dispatch(slices.trips.search(''));
    data.setShowDetail(true);
  };

  return (
    <Wrapper>
      <Badge badgeContent={trips.filter((item) => item.front === null).length} color="primary">
        <Button onClick={openModal} variant="outlined" size="large" disabled={fronts.length === 0}>
          Solicitudes
        </Button>
      </Badge>
      {data.showDetail && (
        <Detail data={data} close={() => data.setShowDetail(false)} notification={notification} />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
