import styled, { css } from 'styled-components';
import { design } from '@constants';

export const Wrapper = styled.div`
  position: relative;
  ${(props: any) =>
    css`
      padding: ${props.margin};
    `}
`;

export const Title = styled.div`
  position: absolute;
  background-color: white;
  ${(props: any) =>
    props.active
      ? css`
          color: ${design.colors.primary};
        `
      : css`
          color: ${design.colors.darkGray};
        `}
  ${(props: any) =>
    props.size === 'small' &&
    css`
      font-size: 12px;
      padding: 0 5px;
      margin-top: -7px;
      margin-left: 10px;
    `}
  ${(props: any) =>
    props.size === 'medium' &&
    css`
      font-size: 18px;
      padding: 0 10px;
      margin-top: -11px;
      margin-left: 10px;
    `}
`;

export const Content = styled.div`
  border: solid 1px rgba(0, 0, 0, 0.23);
  transition: 200ms;
  ${(props: any) =>
    css`
      padding: ${props.padding};
    `}
  ${(props: any) =>
    props.hover &&
    css`
      :hover {
        border: solid 1px rgba(0, 0, 0, 0.87);
      }
    `}
  border-radius: 10px;
`;
